import { getPreFarmingAddress, getPreMiningAddress } from '../../utils/addressHelpers'
import { tokensList } from './tokens'
import { ChainId } from './v3/misc'

export const farms = {
  [ChainId.LINEA_TESTNET]: [
    {
      pid: 0,
      lpSymbol: 'USDC',
      stakeTokenAddress: {
        59140: '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068',
      },
      token: tokensList.lynx,
      quoteToken: tokensList.weth,
    },
    {
      pid: 1,
      lpSymbol: 'USDT',
      stakeTokenAddress: {
        59140: '0x1990BC6dfe2ef605Bfc08f5A23564dB75642Ad73',
      },
      token: tokensList.lynx,
      quoteToken: tokensList.weth,
    },
    {
      pid: 2,
      lpSymbol: 'LDO/ETH',
      stakeTokenAddress: {
        59140: '0x567F42De8014399Cd4FD342b886E3377b763767D',
      },
      token: tokensList.lynx,
      quoteToken: tokensList.weth,
      token1: tokensList.ldo,
      token0: tokensList.weth,
      isLp: true,
    },
  ],
  [ChainId.LINEA_MAINNET]: [
    {
      pid: 0,
      lpSymbol: 'WETH/USDC Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x0b15a5e3ca0d4b492c3b476d0f807535f9b72079',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.weth,
      address: getPreFarmingAddress(),
    },
    {
      pid: 1,
      lpSymbol: 'USDT/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0xf3b1125c8505f038503e002e61a78253610d4f60',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdt,
      token1: tokensList.weth,
      address: getPreFarmingAddress(),
    },
    {
      pid: 2,
      lpSymbol: 'WBTC/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x8a9570ec97534277ade6e46d100939fbce4968f0',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.wbtc,
      token1: tokensList.weth,
      address: getPreFarmingAddress(),
    },
    {
      pid: 3,
      lpSymbol: 'MATIC/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x8421c6102ee8a147facc01977df3b159f7921d54',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.matic,
      token1: tokensList.weth,
      address: getPreFarmingAddress(),
    },
    {
      pid: 4,
      lpSymbol: 'USDC/BUSD Gamma Stable',
      stakeTokenAddress: {
        59144: '0xd6cc4a33da7557a629e819c68fb805ddb225f517',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.busd,
      address: getPreFarmingAddress(),
    },
    {
      pid: 5,
      lpSymbol: 'BUSD/USDT Gamma Stable',
      stakeTokenAddress: {
        59144: '0x32e27ff479454e32868ff67ee9f06bafdc1e908f',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.busd,
      token1: tokensList.usdt,
      address: getPreFarmingAddress(),
    },
    {
      pid: 0,
      lpSymbol: 'WETH/USDC Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x0b15a5e3ca0d4b492c3b476d0f807535f9b72079',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 6,
      lpSymbol: 'USDC/WETH Yield IQ',
      stakeTokenAddress: {
        59144: '0x4f74ED3b2278dAE2119658aB2c235BBcd2306d29',
      },
      principalToken: tokensList.usdc,
      isLp: true,
      type: 'Ichi',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 7,
      lpSymbol: 'WETH/USDC Yield IQ',
      stakeTokenAddress: {
        59144: '0x00b34e51ac2a78761982485a618b235a211f5d86',
      },
      principalToken: tokensList.weth,
      isLp: true,
      type: 'Ichi',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 8,
      lpSymbol: 'wBTC/WETH Yield IQ',
      stakeTokenAddress: {
        59144: '0xad53f20917b144cd0b100dbe75988c0e4b919dba',
      },
      principalToken: tokensList.wbtc,
      isLp: true,
      type: 'Ichi',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.wbtc,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 9,
      lpSymbol: 'wstETH/WETH Stable LP',
      stakeTokenAddress: {
        59144: '0x2c02ad99f4bdd021a686262a32579f19cb02def4',
      },
      isLp: true,
      type: 'Stable',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.stweth,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 10,
      lpSymbol: 'USDT/USDC Stable LP',
      stakeTokenAddress: {
        59144: '0x42eb9a5b3d5b193a5bb54f54f9de52ae571f6aaf',
      },
      isLp: true,
      type: 'Stable',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.usdt,
      address: getPreMiningAddress(),
    },
    {
      pid: 1,
      lpSymbol: 'USDT/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0xf3b1125c8505f038503e002e61a78253610d4f60',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdt,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 2,
      lpSymbol: 'WBTC/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x8a9570ec97534277ade6e46d100939fbce4968f0',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.wbtc,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 3,
      type: 'Gamma',
      lpSymbol: 'MATIC/WETH Gamma Narrow',
      stakeTokenAddress: {
        59144: '0x8421c6102ee8a147facc01977df3b159f7921d54',
      },
      isLp: true,
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.matic,
      token1: tokensList.weth,
      address: getPreMiningAddress(),
    },
    {
      pid: 4,
      lpSymbol: 'USDC/BUSD Gamma Stable',
      stakeTokenAddress: {
        59144: '0xd6cc4a33da7557a629e819c68fb805ddb225f517',
      },
      isLp: true,
      type: 'Gamma',
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.usdc,
      token1: tokensList.busd,
      address: getPreMiningAddress(),
    },
    {
      pid: 5,
      type: 'Gamma',
      lpSymbol: 'BUSD/USDT Gamma Stable',
      stakeTokenAddress: {
        59144: '0x32e27ff479454e32868ff67ee9f06bafdc1e908f',
      },
      isLp: true,
      token: tokensList.lynxClaim,
      quoteToken: tokensList.weth,
      token0: tokensList.busd,
      token1: tokensList.usdt,
      address: getPreMiningAddress(),
    },
  ],
}
