import { useEffect, useState } from 'react'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { isAddress } from '@ethersproject/address'
import BigNumber from 'bignumber.js'
import { useERC20 } from './useContract'
import useRefresh from './useRefresh'
import { ERC20Abi } from '../config/abi'
import { multicall } from '../utils/multicall'
import { fromWei } from '../utils/formatNumber'

export const useTokenBalances = (addresses) => {
  const [balances, setBalances] = useState()
  const { account } = useWeb3Wagmi()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const balanceCalls = addresses.map((address) => {
        return {
          address: address,
          name: 'balanceOf',
          params: [account],
        }
      })
      const decimalCalls = addresses.map((address) => {
        return {
          address: address,
          name: 'decimals',
        }
      })
      const [rawBalances, rawDecimals] = await Promise.all([multicall(ERC20Abi, balanceCalls), multicall(ERC20Abi, decimalCalls)])
      const resBal = rawBalances.map((tokenBalance, index) => {
        return fromWei(tokenBalance, Number(rawDecimals[index].decimals) || 18)
      })
      setBalances(resBal)
      // setBalances(addresses.reduce((sum, address, i) => {
      //   return {
      //     ...sum,
      //     [address]: parsedTokenBalances[i],
      //   }
      // }, {}))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, fastRefresh])

  return balances
}

const useTokenBalance = (tokenAddress, fastUpdate = null) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account } = useWeb3Wagmi()
  const web3 = useWeb3()
  const contract = useERC20(tokenAddress)
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    const fetchBalance = async () => {
      if (!isAddress(tokenAddress)) {
        const walletBalance = await web3.eth.getBalance(account)
        setBalance(new BigNumber(walletBalance).div(1e18))
      } else {
        const [walletBalance, decimals] = await Promise.all([contract.methods.balanceOf(account).call(), contract.methods.decimals().call()])
        setBalance(new BigNumber(walletBalance).div(10 ** Number(decimals)))
      }
    }

    if (account) {
      fetchBalance()
    } else {
      setBalance(new BigNumber(0))
    }
  }, [account, tokenAddress, web3, contract, fastRefresh, fastUpdate])

  return balance
}

export default useTokenBalance
