import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { getTHEAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import Modal from '../Modal'
import NoFound from '../NoFound'
import { useERC20 } from '../../../hooks/useContract'
import { useImportTokens } from '../../../state/application/hooks'
import { fetchImportedToken } from '../../../utils/fetchImportToken'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { defaultChainId } from '../../../config/constants'

const CommonAssets = {
  59144: ['0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f', '0xa219439258ca9da29e9cc4ce5596924745e12b93', '0x176211869ca2b568f2a7d4ee941e073a821ee1ff'],
  59140: ['0xf56dc6695cf1f5c364edebc7dc7077ac9b586068'],
}

const SearchTokenPopup = ({ popup, setPopup, selectedAsset, setSelectedAsset, otherAsset, setOtherAsset, baseAssets, onAssetSelect = () => {} }) => {
  const [manage, setManage] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { account } = useWeb3Wagmi()
  const inputRef = useRef()
  const { importedTokens, addImportedToken, clearImportedToken } = useImportTokens()
  const tokenContract = useERC20(searchText)
  const [importedToken, setImportedToken] = useState({ address: null, name: null, symbol: null, decimals: null, balance: null })

  useEffect(() => {
    async function importToken(address) {
      const asset = await fetchImportedToken(address, account)
      setImportedToken(asset)
    }
    if (searchText !== '' && tokenContract) {
      importToken(searchText)
    }
  }, [searchText, account])

  const filteredAssets = useMemo(() => {
    return searchText
      ? baseAssets.filter(
          (asset) =>
            asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
            asset.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
            asset.address.toLowerCase().includes(searchText.toLowerCase()),
        )
      : baseAssets
  }, [baseAssets, searchText])

  const commonAssets = useMemo(() => {
    return baseAssets.filter((asset) => asset.address === 'ETH' || CommonAssets[defaultChainId].includes(asset.address.toLowerCase()))
  }, [baseAssets])

  useEffect(() => {
    if (!popup) {
      setSearchText('')
    } else {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 300)
    }
  }, [popup])

  return (
    <Modal
      popup={popup}
      setPopup={setPopup}
      title={manage ? 'Manage Tokens' : 'Select a Token'}
      isBack={manage}
      setIsBack={setManage}
      width={540}
      isToken={true}
    >
      <>
        <div className='w-full'>
          <div className='px-3 md:px-6'>
            <div className='w-full mt-3 rounded-md'>
              <input
                ref={inputRef}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search by name, symbol or address'
                className='bg-body placeholder-[#757384] h-8 md:h-10 w-full text-themeBlack text-base md:text-lg px-4 py-[18px] rounded-[3px]'
              />
            </div>
          </div>
          <div className='w-full mt-3.5 md:mt-[18px] px-3 md:px-6'>
            <p className='text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-white mb-1'>COMMON TOKENS</p>
            <div className='flex flex-wrap md:-mx-1'>
              {commonAssets.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={`px-2 md:px-3 text-white text-sm cursor-pointer py-1 md:py-1.5 m-[5px] flex items-center space-x-[5px] rounded-xl bg-neutral-500/90 hover:bg-neutral-400/90`}
                    onClick={() => {
                      if (otherAsset && otherAsset.address === item.address) {
                        const temp = selectedAsset
                        setSelectedAsset(otherAsset)
                        setOtherAsset(temp)
                      } else {
                        setSelectedAsset(item)
                      }
                      onAssetSelect()
                      setPopup(false)
                    }}
                  >
                    <img alt='' src={item.logoURI} width={20} height={20} loading='lazy' />
                    <p>{item.symbol}</p>
                  </div>
                )
              })}
            </div>
          </div>
          {importedTokens.length > 0 && (
            <div className='w-full mt-3.5 md:mt-[18px] px-3 md:px-6'>
              <div className='flex justify-between text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-white mb-1'>
                <p className=''>IMPORTED TOKENS</p>
                <button className='text-white hover:text-themeOrange' onClick={() => clearImportedToken()}>
                  Clear all
                </button>
              </div>
              <div className='flex flex-wrap md:-mx-1'>
                {importedTokens.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`px-2 md:px-3 text-white text-sm cursor-pointer py-1 md:py-1.5 m-[5px] flex items-center space-x-[5px] rounded-xl bg-neutral-500/90 hover:bg-neutral-400/90`}
                      onClick={() => {
                        if (otherAsset && otherAsset.address === item.address) {
                          const temp = selectedAsset
                          setSelectedAsset(otherAsset)
                          setOtherAsset(temp)
                        } else {
                          setSelectedAsset(item)
                        }
                        onAssetSelect()
                        setPopup(false)
                      }}
                    >
                      <img alt='' src='/images/tokens/UKNOWN.png' width={20} height={20} loading='lazy' />
                      <p>{item.symbol}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className='w-full mt-3'>
          <div className='flex justify-between text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-white mb-1 px-3 md:px-6'>
            <span>TOKEN NAME</span>
          </div>
          <div className='w-full mt-1 max-h-[160px] xs:max-h-[20vh] 2xl:max-h-[300px] overflow-auto text-white'>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((asset, idx) => {
                return (
                  <div
                    key={`asset-${idx}`}
                    className={`flex items-center justify-between py-[6px] mx-3 md:mx-6 px-1 md:px-2 rounded-md cursor-pointer hover:bg-white/10 hover:text-themeOrange`}
                    onClick={() => {
                      if (otherAsset && otherAsset.address === asset.address) {
                        const temp = selectedAsset
                        setSelectedAsset(otherAsset)
                        setOtherAsset(temp)
                      } else {
                        setSelectedAsset(asset)
                      }
                      onAssetSelect()
                      setPopup(false)
                    }}
                  >
                    <div className='flex items-center space-x-2.5 md:space-x-3'>
                      <img alt='' src={asset.logoURI || '/images/tokens/UKNOWN.png'} className='flex-shrink-0' width={28} height={28} loading='lazy' />
                      <div className=''>
                        <p className='text-sm md:text-base f-f-fg'>{asset.symbol}</p>
                        <p className='text-[12px] md:text-xs'>{asset.name}</p>
                      </div>
                    </div>
                    {account && <p className='text-sm md:text-base'>{formatAmount(asset.balance) || ''}</p>}
                  </div>
                )
              })
            ) : importedToken.address ? (
              <div
                key={`asset-${importedToken.address}`}
                className={`flex items-center justify-between py-[6px] px-3 md:px-6 cursor-pointer hover:bg-body hover:text-themeOrange`}
                onClick={() => {
                  if (otherAsset && otherAsset.address === importedToken.address) {
                    const temp = selectedAsset
                    setSelectedAsset(otherAsset)
                    setOtherAsset(temp)
                  } else {
                    setSelectedAsset(importedToken)
                  }
                  onAssetSelect()
                  addImportedToken(importedToken)
                  setPopup(false)
                }}
              >
                <div className='flex items-center space-x-2.5 md:space-x-3'>
                  <img alt='' src='/images/swap/question-mark.png' className='flex-shrink-0' width={28} height={28} loading='lazy' />
                  <div className=''>
                    <p className='text-sm md:text-base f-f-fg'>{importedToken.symbol}</p>
                    <p className='text-[13px] md:text-sm tracking-[0.52px]'>{importedToken.name}</p>
                  </div>
                </div>
                {account && <p className='text-sm md:text-base'>{formatAmount(importedToken.balance) || ''}</p>}
              </div>
            ) : (
              <NoFound title='No tokens found' />
            )}
          </div>
          {/* <div className='flex items-center justify-center w-full pt-[0.775rem]'>
              <button
                onClick={() => {
                  setManage(true)
                }}
                className='text-sm md:text-[17px] text-green text-center'
              >
                Manage Tokens
              </button>
            </div> */}
        </div>
      </>
    </Modal>
  )
}

export default SearchTokenPopup
