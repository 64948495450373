import { Orders as LynexOrders, TWAP as LynexTWAP } from '@orbs-network/twap-ui-lynex'
import { useCallback, useContext } from 'react'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import useWalletModal from '../../../hooks/useWalletModal'
import SearchTokenPopup from '../../common/SearchTokenPopup'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const Container = ({ isLimit }) => {
  const { account, library } = useWeb3Wagmi()
  const baseAssets = useContext(BaseAssetsConetext)
  const { openWalletModal } = useWalletModal()

  const priceUsd = useCallback(
    (address) => {
      return baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase())?.price
    },
    [baseAssets],
  )

  return (
    <div
      className='w-full max-w-[588px] mx-auto relative mt-[25px] pb-28 xl:pb-0 2xl:pb-[32px]'
      style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
    >
      <Wrapper title='Swap'>
        <LynexTWAP
          connect={openWalletModal}
          account={account}
          TokenSelectModal={SearchTokenPopup}
          provider={library}
          dappTokens={baseAssets}
          isDarkTheme={true}
          srcToken='ETH'
          dstToken='USDC'
          limit={isLimit}
          priceUsd={priceUsd}
        />
      </Wrapper>
      <Wrapper>
        <LynexOrders />
      </Wrapper>
    </div>
  )
}

const Wrapper = ({ children, title }) => {
  return (
    <div className='bg-white bg-opacity-10 p-px relative rounded-[10px]'>
      <div className='rounded-[5px] px-3 md:px-6 py-3 md:py-4'>
        {title && <p className='f-f-fg text-[23px] md:text-[27px] leading-10 text-white font-semibold mb-6'>{title}</p>}
        {children}
      </div>
    </div>
  )
}

const TWAP = () => {
  return <Container isLimit={false} />
}

const Limit = () => {
  return <Container isLimit={true} />
}

export { TWAP, Limit }
