import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import BigNumber from 'bignumber.js'
import { useSearchParams } from 'react-router-dom'
import Settings from '../../common/Settings'
import TokenInput from '../../common/Input/TokenInput'
import useWalletModal from '../../../hooks/useWalletModal'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { formatAmount, isInvalidAmount, wrappedAsset } from '../../../utils/formatNumber'
import { useProceedSwap, useQuoteSwap } from '../../../hooks/useSwap'
import Spinner from '../../common/Spinner'
import StyledButton from '../../common/Buttons/styledButton'
// import SwapPopup from './popups/swapPopup'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import useDebounce from '../../../hooks/useDebounce'
import WarningModal from './WarningModal'
import { useImportTokens } from '../../../state/application/hooks'
import { fetchImportedToken } from '../../../utils/fetchImportToken'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const SwapV1 = () => {
  const [init, setInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [fromAmount, setFromAmount] = useState('')
  const [fromAsset, setFromAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const [setting, setSetting] = useState(false)
  const [reverseTransiction, setReverseTransiction] = useState(false)
  const [slippage, setSlippage] = useState(0.5)
  const [multihops, setMultihops] = useState(true)
  const [deadline, setDeadline] = useState(20)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const debouncedFromAmount = useDebounce(fromAmount)
  const { bestTrade, priceImpact, quotePending } = useQuoteSwap(wrappedAsset(fromAsset), wrappedAsset(toAsset), debouncedFromAmount, multihops)
  const { onSwap, onWrap, onUnwrap, swapPending } = useProceedSwap()
  const [searchParams] = useSearchParams()
  const { importedTokens, addImportedToken } = useImportTokens()

  const isTokenImported = useMemo(() => {
    if (toAsset && fromAsset) {
      return importedTokens.some((asset) => asset.address === toAsset.address || asset.address === fromAsset.address)
    } else {
      return false
    }
  }, [fromAsset, toAsset, importedTokens])

  const toAmount = useMemo(() => {
    if (bestTrade) {
      return bestTrade.finalValue.toString(10)
    }
    if (fromAsset && toAsset && wrappedAsset(fromAsset).address.toLowerCase() === wrappedAsset(toAsset).address.toLowerCase()) {
      return fromAmount
    }
    return ''
  }, [bestTrade, fromAsset, toAsset, fromAmount])

  const isWrap = useMemo(() => {
    if (fromAsset && toAsset && fromAsset.address === 'ETH' && toAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const isUnwrap = useMemo(() => {
    if (fromAsset && toAsset && toAsset.address === 'ETH' && fromAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (!fromAsset || !toAsset) {
      return {
        isError: true,
        label: 'SELECT A TOKEN',
      }
    }

    if (isInvalidAmount(fromAmount)) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (fromAsset.balance && fromAsset.balance.lt(fromAmount)) {
      return {
        isError: true,
        label: 'INSUFFICIENT ' + fromAsset.symbol + ' BALANCE',
      }
    }

    if (isWrap) {
      return {
        isError: false,
        label: 'WRAP',
      }
    }

    if (isUnwrap) {
      return {
        isError: false,
        label: 'UNWRAP',
      }
    }

    if (!bestTrade) {
      return {
        isError: true,
        label: 'INSUFFICIENT LIQUIDITY FOR THIS TRADE',
      }
    }

    return {
      isError: false,
      label: 'SWAP',
    }
  }, [account, fromAsset, toAsset, fromAmount, bestTrade, priceImpact, isWrap, isUnwrap])

  const baseAssets = useContext(BaseAssetsConetext)
  useEffect(() => {
    if (init || baseAssets.length === 0) return
    setInputAndOutput()
    async function fetchToken(address) {
      let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
      if (!token) {
        token = await fetchImportedToken(address, account)
        if (token) addImportedToken(token)
      }
      return token
    }
    async function setInputAndOutput() {
      const inputCurrency = searchParams.get('inputCurrency')
      const outputCurrency = searchParams.get('outputCurrency')
      const from = await fetchToken(inputCurrency)
      const to = await fetchToken(outputCurrency)
      if (!from) {
        setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
      } else {
        setFromAsset(from)
      }
      if (!to) {
        setToAsset(baseAssets.find((asset) => asset.symbol === 'USDC'))
      } else {
        setToAsset(to)
      }
      setInit(true)
    }
  }, [baseAssets, searchParams])

  useEffect(() => {
    // NOTE: Refreshes balances
    if (baseAssets.length === 0 || !init) return
    const inputCurrency = fromAsset
    const outputCurrency = toAsset
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.address.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.address.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'USDC'))
    } else {
      setToAsset(to)
    }
  }, [baseAssets])

  const handleSwap = useCallback(() => {
    onSwap(fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline)
  }, [fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline])

  return (
    <>
      <div className='w-full max-w-[588px] mx-auto relative mt-[25px] pb-28 xl:pb-0 2xl:pb-[32px]'>
        <OutsideClickHandler
          onOutsideClick={() => {
            setSetting(false)
          }}
        >
          <div className='bg-white bg-opacity-10 p-px relative z-[10] rounded-[10px]'>
            <div className='rounded-[5px] px-3 md:px-6 py-3 md:py-4'>
              <div className='flex items-center justify-between'>
                <p className='f-f-fg text-[23px] md:text-[27px] leading-10 text-white font-semibold'>Swap</p>
                <button
                  onClick={() => {
                    setSetting(!setting)
                  }}
                  className=''
                >
                  <img alt='' src='/images/swap/bar.svg' />
                </button>
              </div>
              <div className='mt-3'>
                <div className='flex flex-col w-full items-center justify-center'>
                  <TokenInput
                    title='From'
                    asset={fromAsset}
                    setAsset={setFromAsset}
                    otherAsset={toAsset}
                    setOtherAsset={setToAsset}
                    amount={fromAmount}
                    onInputChange={(val) => {
                      setFromAmount(val)
                    }}
                    isDollar
                  />
                  <button
                    onClick={() => {
                      const tempAsset = fromAsset
                      if (new BigNumber(toAmount).gt(0)) {
                        setFromAmount(toAmount)
                      }
                      setFromAsset(toAsset)
                      setToAsset(tempAsset)
                    }}
                    className='focus:outline-none mt-5 z-[8]'
                  >
                    <img src='/images/swap/reverse-icon.svg' />
                  </button>
                  <TokenInput
                    title='To'
                    asset={toAsset}
                    setAsset={setToAsset}
                    amount={toAmount}
                    otherAsset={fromAsset}
                    setOtherAsset={setFromAsset}
                    disabled
                    isDollar
                  />
                </div>
              </div>

              {isTokenImported && (
                <div className='w-full flex flex-row justify-end my-4'>
                  <img alt='' src='/images/svgs/warning.svg' />
                  <p className='text-red-500 text-sm mx-2'>WARNING! Token imported; trade at your own risk.</p>
                </div>
              )}

              <div className='mt-3'>
                <div className='flex items-center justify-between'>
                  <p className='text-white text-sm md:text-base leading-5'>Slippage Tolerance</p>
                  <p className='text-white text-sm md:text-base leading-5'>{slippage}%</p>
                </div>
              </div>

              {priceImpact.gt(5) && (
                <div className='mt-3'>
                  <div className='flex items-center justify-center p-5 bg-[#080808] border border-error rounded-[3px]'>
                    <p className='text-white text-sm md:text-base font-semibold'>
                      Price Impact Too High: <span className='text-error'>{formatAmount(priceImpact)}%</span>
                    </p>
                  </div>
                </div>
              )}

              {account ? (
                <StyledButton
                  disabled={btnMsg.isError || swapPending}
                  pending={swapPending}
                  onClickHandler={() => {
                    if (isWrap) {
                      onWrap(fromAmount)
                    } else if (isUnwrap) {
                      onUnwrap(fromAmount)
                    } else {
                      if (priceImpact.gt(5)) {
                        setIsWarning(true)
                      } else {
                        handleSwap()
                      }
                    }
                  }}
                  content={btnMsg.label}
                  className='py-[13px] md:py-[14.53px] text-white mt-2 md:mt-3 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
                />
              ) : (
                <StyledButton
                  onClickHandler={() => openWalletModal()}
                  content={'CONNECT WALLET'}
                  className='py-[13px] md:py-[14.53px] text-white mt-2 md:mt-3 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
                />
              )}

              {bestTrade && (
                <>
                  <div className='flex items-center justify-between mt-3'>
                    <p className='text-white text-sm md:text-base leading-5'>Price:</p>
                    {quotePending ? (
                      <Spinner />
                    ) : (
                      <>
                        <div className='flex items-center space-x-1.5'>
                          <p className='text-white text-sm md:text-base leading-5'>
                            {reverseTransiction
                              ? `${formatAmount(new BigNumber(toAmount).div(fromAmount))} ${toAsset.symbol} per ${fromAsset.symbol}`
                              : `${formatAmount(new BigNumber(fromAmount).div(toAmount))} ${fromAsset.symbol} per ${toAsset.symbol}`}
                          </p>
                          <button onClick={() => setReverseTransiction(!reverseTransiction)}>
                            <img alt='' src='/images/swap/reverse-small-icon.png' />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='mt-[0.3rem]'>
                    <div className='flex items-center justify-between'>
                      <p className='text-white text-sm md:text-base leading-5'>Minimum received</p>
                      <p className='text-white text-sm md:text-base leading-5'>
                        {formatAmount(bestTrade.finalValue.times(100 - slippage).div(100))} {toAsset.symbol}
                      </p>
                    </div>
                  </div>
                  {priceImpact.gt(0) && (
                    <div className='mt-[0.3rem]'>
                      <div className='flex items-center justify-between'>
                        <p className='text-white text-sm md:text-base leading-5'>Price Impact</p>
                        <p
                          className={`text-white text-sm md:text-base leading-5 ${
                            priceImpact.lt(1) ? 'text-success' : priceImpact.lt(2) ? 'text-white' : priceImpact.lt(5) ? 'text-warn' : 'text-error'
                          }`}
                        >
                          {formatAmount(priceImpact)}%
                        </p>
                      </div>
                    </div>
                  )}
                  <div className='flex items-center justify-between mt-[0.3rem]'>
                    <p className='text-white text-sm md:text-base leading-5'>Route:</p>
                  </div>
                  <div className='flex relative items-center mt-7 justify-between'>
                    <img className='z-10 w-7 sm:w-[38px] -ml-0.5 sm:-ml-1' alt='' src={fromAsset.logoURI || '/images/tokens/UKNOWN.png'} />
                    <div className='relative flex flex-col items-center'>
                      <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[0].stable ? 'Stable' : 'Volatile'}</p>
                      <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                    </div>
                    {bestTrade.base && bestTrade.base.length === 1 && (
                      <>
                        <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[0].logoURI || '/images/tokens/UKNOWN.png'} />
                        <div className='relative flex flex-col items-center'>
                          <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[1].stable ? 'Stable' : 'Volatile'}</p>
                          <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                        </div>
                      </>
                    )}
                    {bestTrade.base && bestTrade.base.length === 2 && (
                      <>
                        <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[0].logoURI || '/images/tokens/UKNOWN.png'} />
                        <div className='relative flex flex-col items-center'>
                          <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[1].stable ? 'Stable' : 'Volatile'}</p>
                          <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                        </div>
                        <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[1].logoURI} />
                        <div className='relative flex flex-col items-center'>
                          <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[2].stable ? 'Stable' : 'Volatile'}</p>
                          <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                        </div>
                      </>
                    )}
                    <img className='z-10 w-7 sm:w-[38px] -mr-0.5 sm:-mr-1' alt='' src={toAsset.logoURI || '/images/tokens/UKNOWN.png'} />
                    <div className='border-custom w-full h-0.5  absolute' />
                  </div>
                </>
              )}
            </div>
            {setting && (
              <Settings
                slippage={slippage}
                setSlippage={setSlippage}
                deadline={deadline}
                setDeadline={setDeadline}
                multihops={multihops}
                setMultihops={setMultihops}
              />
            )}
            {isWarning && <WarningModal isOpen={isWarning} setIsOpen={setIsWarning} onClickHandler={handleSwap} priceImpact={priceImpact} />}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default SwapV1
