import React from 'react'
import './loadingBar.scss'
import { getTimePeriods } from '../../../../utils/time'
import { formatAmount } from '../../../../utils/formatNumber'

const LoadingBar = ({ secondsUntilStart, secondsUntilEnd, status, tokenCommited, totalRaise, symbol }) => {
  const progress = tokenCommited.div(totalRaise).toNumber() * 100
  const timer = status === 'coming_soon' ? getTimePeriods(secondsUntilStart) : getTimePeriods(secondsUntilEnd)

  return (
    <div className='wrapper-loading'>
      <p className='to-raise'>
        {formatAmount(tokenCommited)} / {formatAmount(totalRaise)} {symbol}
      </p>
      <div className='w-[100%] flex justify-center' style={{ marginTop: '28px' }}>
        {status !== 'finished' && (
          <p className='data-loading text-md'>
            {status === 'coming_soon' ? 'Starts in: ' : 'Ends in: '} {`${timer.days}d ${timer.hours}h, ${timer.minutes}m`}
          </p>
        )}
      </div>
      <div className='bar' />
      <div className='completion-bar' style={{ width: `${progress}%` }} />
    </div>
  )
}

export default LoadingBar
