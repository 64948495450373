import { useState, useEffect } from 'react'
import { getMerkleTreeAddress } from '../../../../utils/addressHelpers'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { multicall } from '../../../../utils/multicall'
import { merkleTreeAbi } from '../../../../config/abi'

export const useHasClaimed = (type) => {
  const [hasClaimed, setHasClaimed] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const { account } = useWeb3Wagmi()
  const merkleTreeAddress = getMerkleTreeAddress(type)

  useEffect(() => {
    const fetchInfo = async () => {
      let res
      if (account) {
        try {
          const calls = [
            {
              address: merkleTreeAddress,
              name: 'hasClaimed',
              params: [account],
            },
          ]
          res = await multicall(merkleTreeAbi, calls)
          setHasClaimed(res[0][0])
        } catch {
          console.log('An error was encountered while fetching claim status')
        }
      }
    }
    fetchInfo()
  }, [account, refresh])
  return { hasClaimed, setRefresh, setHasClaimed, refresh }
}
