import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getLPSymbol } from '../../../utils/formatNumber'
import Modal from '../Modal'
import NoFound from '../NoFound'

const PoolPopup = ({ popup, setPopup, setSelectedPool, pools }) => {
  const [searchText, setSearchText] = useState('')
  const inputRef = useRef()

  const filteredPools = useMemo(() => {
    return searchText
      ? pools.filter((pool) => pool.symbol.toLowerCase().includes(searchText.toLowerCase()) || pool.address.toLowerCase().includes(searchText.toLowerCase()))
      : pools
  }, [pools, searchText])

  useEffect(() => {
    if (!popup) {
      setSearchText('')
    } else {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 300)
    }
  }, [popup])

  return (
    <Modal popup={popup} setPopup={setPopup} title={'Select a Pool'} width={540} isToken={true}>
      <div className='w-full'>
        <div className='px-3 md:px-6'>
          <div className='border border-blue w-full mt-3 md:mt-5 rounded-[3px]'>
            <input
              ref={inputRef}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder='Search by name, symbol or address'
              className='bg-body placeholder-[#757384] h-14 w-full text-themeBlack text-base md:text-lg px-4 py-[18px] rounded-[3px]'
            />
          </div>
        </div>
      </div>
      <div className='w-full mt-5'>
        <div className='flex justify-between text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-dimGray mb-1 px-3 md:px-6'>
          <span>POOL NAME</span>
        </div>
        <div className='w-full mt-3 md:mt-[13px] max-h-[400px] overflow-auto'>
          {filteredPools.length > 0 ? (
            filteredPools.map((pool, idx) => {
              return (
                <div
                  key={`pool-${idx}`}
                  className={`flex items-center text-white justify-between py-[15px] px-3 md:px-6 cursor-pointer hover:bg-body hover:text-themeOrange`}
                  onClick={() => {
                    setSelectedPool(pool)
                    setPopup(false)
                  }}
                >
                  <div className='flex items-center space-x-2.5 md:space-x-3'>
                    <div className='flex items-center -space-x-2'>
                      <img className='relative z-10' width={28} height={28} alt='' src={pool.token0.logoURI} />
                      <img className='relative z-[5]' width={28} height={28} alt='' src={pool.token1.logoURI} />
                    </div>
                    <div className=''>
                      <p className='text-sm md:text-base f-f-fg'>{getLPSymbol(pool)}</p>
                      <p className='text-[13px] md:text-sm tracking-[0.52px]'>{pool.title}</p>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <NoFound title='No pools found' />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default PoolPopup
