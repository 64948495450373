import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'

const KeyProgressBar = () => {
  const { account } = useWeb3Wagmi()
  const [highestKey, setHighestKey] = useState('')

  useEffect(() => {
    if (account) {
      axios
        .get(`https://lynex-nest-ca415fe19eed.herokuapp.com/stats/reward/${account}`)
        .then((response) => {
          // Adjust key order for display purposes, separating the name and the suffix for the image file
          const keyOrder = [
            { name: 'Legendary', suffix: 'legendary-key' },
            { name: 'Platinum', suffix: 'platinum-key' },
            { name: 'Gold', suffix: 'gold-key' },
            { name: 'Silver', suffix: 'silver-key' },
            { name: 'Bronze', suffix: 'bronze-key' },
          ]
          const rewardsNft = response.data.rewardsNft

          // Find the highest key based on the predefined order
          const highestKeyFound = keyOrder.find((key) => rewardsNft.some((nft) => nft.name.includes(key.name))) || null
          setHighestKey(highestKeyFound ? highestKeyFound.name : 'None')
        })
        .catch((error) => {
          console.error('Error fetching rewards data:', error)
          setHighestKey('Error') // Appropriately handle the error state
        })
    }
  }, [account])

  const formatKeyImageName = (keyName) => {
    // Find the suffix for the image based on the key name
    const keySuffix = {
      Legendary: 'legendary-key',
      Platinum: 'platinum-key',
      Gold: 'gold-key',
      Silver: 'silver-key',
      Bronze: 'bronze-key',
    }[keyName]

    return keyName && keyName !== 'None' ? `/images/keys/${keySuffix}-square.png` : null
  }

  const keyImageSrc = formatKeyImageName(highestKey)

  if (!account) {
    return null
  }

  return (
    <div className={`key-progress-bar ${account ? '' : 'hidden'} p-3 flex flex-col items-center justify-center`}>
      <p className='text-white text-center'>Your Lynex Key</p>
      {keyImageSrc && (
        <img
          src={keyImageSrc}
          alt={`${highestKey} Key`}
          style={{ height: '128px', width: 'auto', overflow: 'hidden', margin: '10px 0', transform: 'translateX(-3%)' }}
          onError={(e) => {
            e.target.style.display = 'none'
          }}
        />
      )}
      <p className='text-white text-center'>{highestKey}</p>
    </div>
  )
}

export default KeyProgressBar
