import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Mint from './pages/mint'
import Swap from './pages/swap'
import Liquidity from './pages/liquidity'
import LiquidityInfo from './pages/liquidity-info'
import Farm from './pages/farm'
import ManageLiquidity from './pages/liquidity/manageLiquidity'
import ManageLiquidityV3 from './pages/liquidity/manageLiquidityV3'
import Lock from './pages/lock'
import Vote from './pages/vote'
import Delegate from './pages/delegate'
import Otoken from './pages/otoken'
import Rewards from './pages/rewards'
import WhiteList from './pages/whiteList'
import AddBribe from './pages/whiteList/bribeModal'
import AddGauge from './pages/whiteList/gaugeModal'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import PageNotFound from './pages/404NotFound'
import Claim from './pages/claim'
import { RefreshContextProvider } from './context/RefreshContext'
import { ToastContainer, Zoom } from 'react-toastify'
import { useVideoAutoplay } from './helpers/useAutoPlay'
import { VeTHEsContextProvider } from './context/veTHEsConetext'
import { BaseAssetsConetextProvider } from './context/BaseAssetsConetext'
import { FusionsContextProvider } from './context/FusionsContext'
import { GammaContextProvider } from './context/GammaContext'
import { PoolsContextProvider } from './context/PoolsContext'
import Referral from './pages/referral'
import Ido from './pages/ido'
import ApplicationUpdater from './state/application/updater'
import MultiCallV3Updater from './state/multicall/v3/updater'
import AnalyticsOverview from './pages/analytics'
import AnalyticsTokens from './pages/analytics/tokens'
import AnalyticsPairs from './pages/analytics/pairs'
import TokenDetails from './pages/analytics/token/index'
import PairDetails from './pages/analytics/pair/index'
import TokenDataContextProvider from './context/TokenData'
import GlobalDataContextProvider from './context/GlobalData'
import PairDataContextProvider from './context/PairData'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { chains, wagmiConfig } from './wagmi.config'
import { WagmiConfig } from 'wagmi'
import { RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit'

const ContextProviders = ({ children }) => {
  return (
    <BaseAssetsConetextProvider>
      <VeTHEsContextProvider>
        <GammaContextProvider>
          <FusionsContextProvider>
            <PoolsContextProvider>
              <TokenDataContextProvider>
                <GlobalDataContextProvider>
                  <PairDataContextProvider>{children}</PairDataContextProvider>
                </GlobalDataContextProvider>
              </TokenDataContextProvider>
            </PoolsContextProvider>
          </FusionsContextProvider>
        </GammaContextProvider>
      </VeTHEsContextProvider>
    </BaseAssetsConetextProvider>
  )
}

const Updaters = () => {
  return (
    <>
      <ApplicationUpdater />
      <MultiCallV3Updater />
    </>
  )
}

const App = () => {
  useVideoAutoplay()

  return (
    <div className='main'>
      <RefreshContextProvider>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            chains={chains}
            theme={midnightTheme({
              accentColor: '#DF832F',
            })}
          >
            <ContextProviders>
              {' '}
              <Updaters />
              <Header bannerVisible bannerText='Click here to go back' bannerTitle={'You are in v1'} bannerLink={'https://app.lynex.fi'} />
              <>
                <div className={'orangeGlow'} />
                <div className={'whiteGlow'} />
              </>
              <Routes>
                <Route path='/' element={<Home />} exact />
                <Route path='/theNFT' element={<Mint />} exact />
                <Route path='/swap' element={<Swap />} exact />
                <Route path='/liquidity' element={<Liquidity />} exact />
                <Route path='/liquidity-info' element={<LiquidityInfo />} exact />
                <Route path='/mining' element={<Farm />} exact />
                <Route path='/liquidity/manage' element={<ManageLiquidity />} exact />
                <Route path='/liquidity/fusion' element={<ManageLiquidityV3 />} exact />
                <Route path='/liquidity/manage/:address' element={<ManageLiquidity />} exact />
                <Route path='/otoken' element={<Otoken />} exact />
                <Route path='/lock' element={<Lock />} exact />
                <Route path='/vote' element={<Vote />} exact />
                <Route path='/vote/:veId' element={<Vote />} exact />
                <Route path='/ido' element={<Ido />} exact />
                <Route path='/delegate' element={<Delegate />} exact />
                <Route path='/rewards' element={<Rewards />} exact />
                <Route path='/whitelist' element={<WhiteList />} exact />
                <Route path='/whitelist/bribe' element={<AddBribe />} exact />
                <Route path='/whitelist/gauge' element={<AddGauge />} exact />
                <Route path='/referral' element={<Referral />} exact />
                <Route path='/analytics/:version?' element={<AnalyticsOverview />} exact />
                <Route path='/analytics/:version/pairs' element={<AnalyticsPairs />} exact />
                <Route path='/analytics/:version/tokens' element={<AnalyticsTokens />} exact />
                <Route path='/analytics/:version/token/:id' element={<TokenDetails />} exact />
                <Route path='/analytics/:version/pair/:id' element={<PairDetails />} exact />
                <Route path='/404' element={<PageNotFound />} exact />
                <Route path='/claim' element={<Claim />} exact />
                <Route path='/claim/:type' element={<Claim />} exact />
                <Route path='*' element={<PageNotFound />} exact />
              </Routes>
              <Footer />
            </ContextProviders>
          </RainbowKitProvider>
        </WagmiConfig>
      </RefreshContextProvider>
      <ToastContainer
        className='notify-class'
        position='top-right'
        theme='dark'
        closeOnClick={false}
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
      />
    </div>
  )
}

export default App
