import React, { useEffect, useState } from 'react'
import OtokenInput from '../../common/Input/OtokenInput'
import StyledButton from '../../common/Buttons/styledButton'
import AddToMetamask from '../../common/Buttons/addToMetamask'
import './otoken.scss'
import { getOptionAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import { useExcerciseVe } from '../../../hooks/useExercise'
import { formatUnits } from 'ethers/lib/utils'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const veLynx = ({ baseAssets, optionsData }) => {
  const { account } = useWeb3Wagmi()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [redeemAsset, setRedeemAsset] = useState(null)
  const [payAmount, setPayAmount] = useState('')
  const [payAsset, setPayAsset] = useState(null)
  const assets = baseAssets || []
  const option = optionsData.fetchedOptions
  const { onExcerciseVe } = useExcerciseVe()
  const notAllowed = !account || !redeemAsset || redeemAsset?.balance === 0 || !payAsset || payAsset?.balance === 0

  useEffect(() => {
    if (assets.length === 0) return
    if (!redeemAsset) {
      const oLynxtoken = assets.find((asset) => asset.address.toLowerCase() === getOptionAddress()?.toLowerCase())
      setRedeemAsset(oLynxtoken)
    } else {
      setRedeemAsset(redeemAsset)
    }
    if (!payAsset) {
      const paymentToken = assets.find((asset) => asset.address.toLowerCase() === option.paymentToken.toLowerCase())
      setPayAsset(paymentToken)
    } else {
      setPayAsset(payAsset)
    }
  }, [assets])

  useEffect(() => {
    if (!payAsset) return
    const principal = option.veDiscountPrice.toNumber() > 0 ? redeemAmount * option.veDiscountPrice.toNumber() : 0
    setPayAmount(formatUnits(principal, payAsset.decimals))
  }, [redeemAmount])

  return (
    <div className='wrapper-otoken'>
      <div className='line'>
        <p className='title'>oLYNX balance</p>
        <p className='value'>{redeemAsset?.balance ? formatAmount(redeemAsset.balance.toString()) : '-'}</p>
      </div>
      <div className='line'>
        <p className='text-white title'>You redeem</p>
      </div>
      <OtokenInput
        asset={redeemAsset}
        setAsset={setRedeemAsset}
        amount={redeemAmount}
        onInputChange={(val) => {
          setRedeemAmount(val)
        }}
      />
      <div className='line'>
        <p className='title'>Your voting power will be</p>
        <p className='value'>{formatAmount(redeemAmount, false, 2)} veLYNX</p>
      </div>
      <div className='line'>
        <p className='title'>Unlocking at</p>
        <p className='value'>11/13/2025</p>
      </div>
      <StyledButton
        content='REDEEM INTO veLYNX'
        disabled={notAllowed}
        onClickHandler={() => {
          onExcerciseVe(payAsset, payAmount, redeemAmount)
        }}
        className='w-full py-[13px] text-white text-lg tracking-[1.36px] rounded-md'
      />
      <p className='text-[14px] text-white'>
        Redeeeming into veLYNX will create you a new locked veNFT. It is possible to merge it into single veNFT on the Lock page after redemption.
      </p>
      {account && (
        <div className='flex'>
          <AddToMetamask
            text='Add oLYNX to Metamask'
            tokenAddress={redeemAsset?.address}
            tokenSymbol={redeemAsset?.symbol}
            tokenDecimals={redeemAsset?.decimals}
            imgUrl={redeemAsset?.logoURI}
          />
        </div>
      )}
    </div>
  )
}

export default veLynx
