import React, { useState, useEffect, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import BalanceInput from '../../common/Input/BalanceInput'
import StyledButton from '../../common/Buttons/styledButton'
import { formatAmount, isInvalidAmount } from '../../../utils/formatNumber'
import { useIncreaseAmount, useIncreaseDuration, useUnlockPermanent } from '../../../hooks/useLock'
import { customNotify } from '../../../utils/notify'
import { periodLevels } from '../../../config/constants'
import { epochDuration } from '../../../hooks/useGeneral'
import Toggle from '../../common/Toggle'

const duration = epochDuration * 1000
const minTimeStamp = 86400 * 14 * 1000
const maxTimeStamp = 86400 * 730 * 1000
const maxDate = new Date(Math.floor((new Date().getTime() + maxTimeStamp) / duration) * duration)

const ManageTab = ({ selected, theAsset }) => {
  const [amount, setAmount] = useState('')
  const [isPermanent, setIsPermanent] = useState(false)
  const [periodLevel, setPeriodLevel] = useState(0)

  const minDate = useMemo(() => {
    return new Date(selected.lockEnd * 1000 + minTimeStamp)
  }, [selected])
  const [selectedDate, setSelectedDate] = useState(minDate)

  const votingPower = useMemo(() => {
    return isPermanent ? selected.amount : selected.amount.times(selectedDate.getTime() - new Date().getTime()).div(maxTimeStamp)
  }, [selected, selectedDate, isPermanent])

  const unlockTime = useMemo(() => {
    return moment(selectedDate).diff(moment(), 'seconds')
  }, [selectedDate])

  const { onIncreaseAmount, pending: amountPending } = useIncreaseAmount()
  const { onIncreaseDuration, pending: durationPending } = useIncreaseDuration()
  const { onUnlock, pending: unlockPending } = useUnlockPermanent()

  const errorMsg = useMemo(() => {
    if (isInvalidAmount(amount)) {
      return 'ENTER AN AMOUNT'
    }
    if (!theAsset || theAsset.balance.lt(amount)) {
      return 'INSUFFICIENT BALANCE'
    }
    return null
  }, [amount, theAsset])

  useEffect(() => {
    let timestamp = 0
    if (periodLevel < 0) return
    switch (periodLevel) {
      case 0:
        timestamp = minTimeStamp
        break
      case 1:
        timestamp = 3600 * 24 * (30 * 6) * 1000
        break
      case 2:
        timestamp = 3600 * 24 * 364 * 1000
        break
      case 3:
        timestamp = maxTimeStamp
        break

      default:
        break
    }
    let period
    if (periodLevel === 3) {
      period = new Date().getTime() + timestamp
    } else {
      period = selected.lockEnd * 1000 + timestamp
    }
    const date = new Date(Math.floor(period / duration) * duration)
    setSelectedDate(date)
  }, [periodLevel, selected])

  const disabled = selectedDate > maxDate
  console.log(selectedDate, maxDate, disabled)

  return (
    <div className='mt-1 md:mt-5'>
      <BalanceInput title='Amount' inputAmount={amount} setInputAmount={setAmount} symbol='LYNX' balance={theAsset?.balance} logoURIs={['/logo.png']} />
      <StyledButton
        disabled={errorMsg || amountPending}
        pending={amountPending}
        onClickHandler={() => {
          if (errorMsg) {
            customNotify(errorMsg, 'warn')
            return
          }
          onIncreaseAmount(selected.id, amount)
        }}
        content={errorMsg || 'INCREASE LOCK AMOUNT'}
        className='py-2 md:py-3 text-white mt-4 text-base  md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-md'
      />
      {unlockTime > 0 ? (
        <>
          <p className='mt-6 text-white text-sm md:text-lg'>Lock Until</p>
          <div className='mt-1.5 md:mt-2.5 p-px w-full rounded-md'>
            <div className='bg-white/10 flex items-center px-2.5 h-[48px] md:h-[62px] rounded-md'>
              <img alt='' className='w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]' src='/images/lock/calendar-icon.svg' />
              <DatePicker
                className='bg-transparent w-full pl-[6px] text-base md:text-xl leading-10 placeholder-dimGray text-white font-dark'
                selected={selectedDate}
                dateFormat='yyyy/MM/dd'
                onChange={(date) => {
                  if (periodLevel >= 0) {
                    setPeriodLevel(-1)
                  }
                  if (date.getTime() === selectedDate.getTime()) {
                    return
                  }
                  setSelectedDate(new Date(Math.floor(date.getTime() / duration) * duration))
                }}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </div>
          <div className='mt-4 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-[11px] text-white text-[14px] lg:text-base font-semibold'>
            {periodLevels.map((level, index) => {
              if (level.value === periodLevel) {
                return (
                  <div
                    className='h-[36px] md:h-[40px] bg-themeOrange rounded-md flex items-center justify-center cursor-pointer font-medium'
                    key={`level-${index}`}
                    onClick={() => setPeriodLevel(level.value)}
                  >
                    {level.label}
                  </div>
                )
              } else {
                return (
                  <div
                    className='h-[36px] md:h-[40px] bg-neutral-500/90 rounded-md flex items-center justify-center cursor-pointer font-light'
                    key={`level-${index}`}
                    onClick={() => setPeriodLevel(level.value)}
                  >
                    {level.label}
                  </div>
                )
              }
            })}
          </div>
          {periodLevel === 3 && (
            <div className='flex items-center space-x-2 mt-4'>
              <Toggle checked={isPermanent} onChange={() => setIsPermanent(!isPermanent)} small toggleId='permanent' />
              <p className='text-[#DEDBF2] text-[16px] whitespace-nowrap'>Re-lock my LYNX permanently at maximum lock time</p>
            </div>
          )}
          <div className='my-3 lg:my-5 text-sm lg:text-base flex items-center justify-between'>
            <span className='text-white font-light'>New veLYNX Voting Power:</span>
            <div className='flex space-x-2'>
              <span className='text-white font-medium'>{formatAmount(votingPower)}</span>
              <span className='text-dimGray font-light'>{`(+${formatAmount(votingPower.minus(selected.voting_amount))})`}</span>
            </div>
          </div>
          <StyledButton
            disabled={durationPending || disabled}
            pending={durationPending}
            onClickHandler={() => {
              if (unlockTime === 0) {
                customNotify('Already Max Locked', 'warn')
                return
              }
              onIncreaseDuration(selected.id, unlockTime, isPermanent)
            }}
            content={`${disabled ? 'WAIT UNTIL END OF CURRENT EPOCH' : 'EXTEND DURATION'}`}
            className='py-2 md:py-3 text-white md:mt-4 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-md'
          />
        </>
      ) : (
        <StyledButton
          disabled={unlockPending || disabled}
          pending={unlockPending}
          onClickHandler={() => {
            if (unlockTime === 0) {
              customNotify('Already Max Locked', 'warn')
              return
            }
            onUnlock(selected.id)
          }}
          content={`UNLOCK PERMANENT`}
          className='py-2 md:py-3 text-white md:mt-4 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-md'
        />
      )}
    </div>
  )
}

export default ManageTab
