import React from 'react'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import useWalletModal from '../../../../hooks/useWalletModal'
import './howTo.scss'
import { Link } from 'react-router-dom'

const HowTo = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()

  return (
    <div className='wrapper'>
      <div className='header'>
        <p className='header-text'>How to take part?</p>
      </div>
      <div className='steps-wrapper'>
        <div className='step'>
          <div className='w-full flex flex-col align-center md:flex-row'>
            <div className='flex gap-4 w-[100%] flex-row md:w-[75%] '>
              <div className='step-number'>1</div>
              <div className='flex flex-col justify-center'>
                <p className='step-title'>Connect your wallet</p>
                <p className='step-text'>You'll require a compatible wallet to participate in the IDO sale!</p>
              </div>
            </div>
            <div className='step-action'>
              {account ? (
                <p className='text-emerald-400'>Wallet Connected!</p>
              ) : (
                <button
                  onClick={() => {
                    openWalletModal()
                  }}
                  className='action-button'
                >
                  CONNECT WALLET{' '}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='step'>
          <div className='w-full flex flex-col align-center md:flex-row'>
            <div className='flex gap-4 w-[100%] flex-row md:w-[75%]'>
              <div className='step-number'>2</div>
              <div className='flex flex-col justify-center'>
                <p className='step-title'>Get USDC</p>
                <p className='step-text'>You'll use them to purchase tokens during the sale.</p>
              </div>
            </div>
            <div className='step-action'>
              <Link to='/swap' className='action-button'>
                GET USDC
              </Link>
            </div>
          </div>
        </div>
        <div className='step'>
          <div className='w-full flex flex-row align-center '>
            <div className='flex flex-row w-[100%] gap-4'>
              <div className='step-number'>3</div>
              <div className='flex flex-col justify-center'>
                <p className='step-title'>Commit USDC</p>
                <p className='step-text'>
                  Once the sale concludes, you'll be able to claim the tokens you purchased. Any unused USDC tokens will be automatically refunded to your
                  wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='step'>
          <div className='w-full flex flex-row align-center '>
            <div className='flex flex-row w-[100%] gap-4'>
              <div className='step-number'>4</div>
              <div className='flex flex-col justify-center'>
                <p className='step-title'>Claim your tokens</p>
                <p className='step-text'>
                  After the sale finishes, you can start claiming tokens that you bought. Any unspent USDC tokens will be returned to your wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowTo
