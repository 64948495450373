import { Token } from '@uniswap/sdk-core'
import { ChainId } from '../../config/constants/v3/misc'
import { ETH } from './eth'

export const WETH_EXTENDED = {
  [ChainId.LINEA_TESTNET]: new Token(ChainId.LINEA_TESTNET, '0x2c1b868d6596a18e32e61b901e4060c872647b6c', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.LINEA_MAINNET]: new Token(ChainId.LINEA_MAINNET, '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET, '0xae13d989dac2f0debff460ac112a837c89baa7cd', 18, 'WBNB', 'Wrapped BNB'),
}

export class ExtendedEther extends ETH {
  static _cachedEther = {}

  get wrapped() {
    if (this.chainId in WETH_EXTENDED) return WETH_EXTENDED[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  static onChain(chainId) {
    return this._cachedEther[chainId] ?? (this._cachedEther[chainId] = new ExtendedEther(chainId))
  }
}

export const v3LiquidityRangeType = {
  MANUAL_RANGE: 'MANUAL_RANGE',
  GAMMA_RANGE: 'GAMMA_RANGE',
}
