import JSBI from 'jsbi'
import { NEGATIVE_ONE, ZERO } from './v3internalConstants'

export class LiquidityMath {
  /**
   * Cannot be constructed.
   */

  static addDelta(x, y) {
    if (JSBI.lessThan(y, ZERO)) {
      return JSBI.subtract(x, JSBI.multiply(y, NEGATIVE_ONE))
    } else {
      return JSBI.add(x, y)
    }
  }
}
