import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getERC20Contract, getIDOContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, MAX_UINT256, toWei } from '../utils/formatNumber'
import { getAddress } from '../utils/addressHelpers'

export const useIdoCommit = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleCommit = useCallback(
    async (ido, amount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const commituuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Commit ${ido.currency.symbol.toUpperCase()}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${ido.currency.symbol.toUpperCase()}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [commituuid]: {
              desc: `Commit tokens to the sale`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, getAddress(ido.currency.address))
      const allowance = await getAllowance(tokenContract, ido.address, account)
      if (fromWei(allowance, ido.currency.decimals).lt(amount)) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [ido.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [toWei(amount, ido.currency.decimals).toFixed(0), true]
      const idoContract = getIDOContract(web3, ido.address)
      try {
        await sendContract(dispatch, key, commituuid, idoContract, 'commitTokens', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Commited',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { handleCommit, pending }
}

export const useIdoClaim = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleClaim = useCallback(
    async (ido) => {
      const key = uuidv4()
      const claimuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim`,
          transactions: {
            [claimuuid]: {
              desc: `Claim sale tokens`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const idoContract = getIDOContract(web3, ido.address)
      try {
        await sendContract(dispatch, key, claimuuid, idoContract, 'withdrawTokens', [], account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Claimed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { handleClaim, pending }
}
