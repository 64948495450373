import { useEffect, useState, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { toWei, BIG_ZERO } from '../utils/formatNumber'
import { multicall } from '../utils/multicall'
import { crowdsaleAbi } from '../config/abi'
import useRefresh from './useRefresh'

export const getStatus = (timestamp, startTime, endTime) => {
  // Add an extra check to currentBlock because it takes awhile to fetch so the initial value is 0
  // making the UI change to an inaccurate status
  if (timestamp === 0) {
    return 'idle'
  }

  if (timestamp < startTime) {
    return 'coming_soon'
  }

  if (timestamp >= startTime && timestamp <= endTime) {
    return 'live'
  }

  if (timestamp > endTime) {
    return 'finished'
  }

  return 'idle'
}

/**
 * Gets all public data of an INO
 */
const useIdoPublicData = (ino) => {
  const { address, releaseTime, startTime: start, tokensToSell, price } = ino
  const time = Math.round(new Date().getTime() / 1000)
  const secondsUntilStart = start - time
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    status: 'idle',
    secondsUntilStart,
    secondsUntilEnd: 0,
    progress: 5,
    currencyPriceInUSD: BIG_ZERO,
    startTime: 0,
    endTime: 0,
    price: toWei(new BigNumber(price)),
    offeringAmount: new BigNumber(tokensToSell),
    commitmentsTotal: BIG_ZERO,
    tokenPrice: BIG_ZERO,
    auctionSuccessful: false,
    finalized: false,
    auctionEnded: false,
    usePointList: false,
  })

  const fetchInoData = useCallback(async () => {
    const inoCalls = [
      {
        address,
        name: 'marketInfo',
      },
      {
        address,
        name: 'marketPrice',
      },
      {
        address,
        name: 'marketStatus',
      },
      {
        address,
        name: 'tokenPrice',
      },
      {
        address,
        name: 'finalized',
      },
      {
        address,
        name: 'auctionSuccessful',
      },
      {
        address,
        name: 'auctionEnded',
      },
    ]

    const [marketInfo, marketPrice, marketStatus, tokenPrice, finalized, auctionSuccessful, auctionEnded] = await multicall(crowdsaleAbi, inoCalls)
    const bnbPrice = 0 // await getBnbPrice()
    const currentTime = Math.round(new Date().getTime() / 1000)
    const { startTime, endTime } = marketInfo
    const status = auctionEnded[0] ? 'finished' : getStatus(currentTime, startTime, endTime)
    const totalSeconds = endTime - startTime
    const secondsRemaining = endTime - currentTime
    const secondsUntilStart = startTime - currentTime

    // Calculate the total progress until finished or until start
    const progress = currentTime > startTime ? (secondsRemaining / totalSeconds) * 100 : ((currentTime - releaseTime) / (startTime - releaseTime)) * 100
    setState((prev) => ({
      ...prev,
      secondsUntilEnd: secondsRemaining,
      secondsUntilStart,
      startTime: startTime.toNumber(),
      endTime: endTime.toNumber(),
      status,
      progress,
      offeringAmount: new BigNumber(marketInfo.totalTokens.toString()),
      currencyPriceInUSD: new BigNumber(bnbPrice),
      commitmentsTotal: new BigNumber(marketStatus.commitmentsTotal.toString()),
      usePointList: marketStatus.usePointList,
      tokenPrice: new BigNumber(tokenPrice.toString()),
      price: new BigNumber(marketPrice.rate.toString()),
      finalized: finalized[0],
      auctionSuccessful: auctionSuccessful[0],
      auctionEnded: auctionEnded[0],
    }))
  }, [releaseTime, address, slowRefresh])

  useEffect(() => {
    const curTime = Math.round(new Date().getTime() / 1000)
    if (address) fetchInoData()
    else
      setState((prev) => ({
        ...prev,
        secondsUntilStart: start - curTime,
      }))
  }, [fetchInoData, address, start])

  return { ...state, fetchInoData }
}

export default useIdoPublicData
