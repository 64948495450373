import { useEffect, useState, useRef } from 'react'
import Web3 from 'web3'
import { defaultHttpProvider, getWeb3NoAccount } from '../utils/web3'
import { useAccount, useWalletClient } from 'wagmi'

const useWeb3 = () => {
  const library = useGetProvider()
  const refEth = useRef(library)
  const [web3, setweb3] = useState(library ? new Web3(library) : getWeb3NoAccount())

  useEffect(() => {
    if (library !== refEth.current) {
      setweb3(library ? new Web3(library) : getWeb3NoAccount())
      refEth.current = library
    }
  }, [library])

  return web3
}

const useGetProvider = () => {
  const { connector } = useAccount()
  const [provider, setProvider] = useState(defaultHttpProvider)

  useEffect(() => {
    const fetchProvider = async () => {
      const provider = await connector.getProvider()
      if (provider) setProvider(provider)
    }

    if (connector) fetchProvider()
  }, [connector, setProvider])

  return provider
}

export const useWeb3Wagmi = () => {
  const { address: account, isConnected } = useAccount()
  const { data: walletClient } = useWalletClient()
  const library = useGetProvider()

  return { account, walletClient, library, chainId: walletClient?.chain.id, active: isConnected }
}

export default useWeb3
