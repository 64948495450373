import { tokensList } from './tokens'

export const idos = [
  {
    id: 'lynx',
    address: '0x32913e7a013213322987137e3f2cff43002204fa',
    pointList: '0x121764bc2f50650384b6b9b0d8b103a72ddda362',
    isActive: true,
    name: 'Lynex the native liquidity layer at Linea',
    description: 'Lynex the native liquidity layer at Linea',
    saleAmount: '1,000,000 LYNX',
    currency: tokensList.usdc,
    token: tokensList.lynx,
    startTime: 1707735600,
    releaseTime: 1707915600,
    tokensToSell: 1000000000000000000000000,
    campaignId: '511111000',
    articleUrl: '#',
    price: 0.06,
  },
]

// https://lineascan.build/tx/0x0d05ade001331cdaab567a5d4dd18f5cd0c16aae4ec94d2af509b608ec2cafe2

// https://goerli.lineascan.build/tx/0x535ebcbb301aca2635d266f1d139644c2608d0d431707c6adc8cccfc1e16ff39
// https://goerli.lineascan.build/tx/0xee5f5f8c210657528f24e123a12af1955121e3bf1dc2b613459076caade9eada

// https://goerli.lineascan.build/tx/0x059d34c52dfd5a2da655567db78322cb1c90a3cf5044e3347a96e413de8583c9
// https://goerli.lineascan.build/tx/0x7773c524d1ada6ab0f4029a93036fc9ef56924faa10dcd6b7a313469bcc64b6a
// https://goerli.lineascan.build/tx/0x57207e94c96245cdbd5120067347669fec169910638e57160d4e73a4250a9ccf

// https://goerli.lineascan.build/tx/0x9a2ba37299ca766de55b4a1813e99a140239fc7423382520236f1413daba7293
// https://goerli.lineascan.build/tx/0x570d335f7f7f7f7fa8b327738f3ba131a7dc049a7229555a40b3f2acc0e6221e

// https://goerli.lineascan.build/tx/0xe2b06fa6775cfd7aeba38ef1cebccb8c7ad2c3b2f505701c86b8a1e29a06351d

// https://goerli.lineascan.build/tx/0x6891edb00b48ba4d23031cb3f0e6c77018c53dbfc512f82887801c1758b018aa
