import { useContext, useEffect, useState } from 'react'
// import { getETHAddress, getTHEAddress, getWBNBAddress } from '../utils/addressHelpers'
import { getTHEAddress, getWBNBAddress } from '../utils/addressHelpers'
import { BaseAssetsConetext } from '../context/BaseAssetsConetext'

const usePrices = () => {
  const [prices, setPrices] = useState({
    THE: 0,
    LYNX: 0.06,
    BNB: 0,
    ETH: 0,
    PSTAKE: 0,
    liveTHE: 0,
  })
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    if (baseAssets.length > 0) {
      const lynxAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getTHEAddress().toLowerCase())
      const bnbAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getWBNBAddress().toLowerCase())
      // const ethAsset = baseAssets.find((asset) => asset.address.toLowerCase() === getETHAddress().toLowerCase())
      // const pstakeAsset = baseAssets.find((asset) => asset.address.toLowerCase() === '0x4c882ec256823ee773b25b414d36f92ef58a7c0c')
      // const liveTHEAsset = baseAssets.find((asset) => asset.address.toLowerCase() === '0xcdc3a010a3473c0c4b2cb03d8489d6ba387b83cd')
      setPrices({
        // THE: theAsset ? theAsset.price : 0,
        THE: 0,
        LYNX: lynxAsset ? lynxAsset.price : 0,
        BNB: bnbAsset ? bnbAsset.price : 0,
        // ETH: ethAsset ? ethAsset.price : 0,
        ETH: 0,
        // PSTAKE: pstakeAsset ? pstakeAsset.price : 0,
        PSTAKE: 0,
        // liveTHE: liveTHEAsset ? liveTHEAsset.price : 0,
        liveTHE: 0,
      })
    }
  }, [baseAssets])

  return prices
}

export const useTokenPrice = (address) => {
  const [price, setPrice] = useState(0)
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    if (baseAssets.length > 0) {
      const asset = baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase())
      setPrice(asset ? asset.price : 0)
    }
  }, [baseAssets])

  return price
}

export default usePrices
