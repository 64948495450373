import React, { useContext, useEffect, useMemo, useState } from 'react'
import OtokenInput from '../../common/Input/OtokenInput'
import AddToMetamask from '../../common/Buttons/addToMetamask'
import StyledButton from '../../common/Buttons/styledButton'
import Slider from 'react-rangeslider'
import './otoken.scss'
import { getOptionAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import { formatUnits } from 'ethers/lib/utils'
import { useExcerciseLp } from '../../../hooks/useExercise'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import BigNumber from 'bignumber.js'
import { getTimePeriods, useTimestamp } from '../../../utils/time'
import { FusionsContext } from '../../../context/FusionsContext'

const Lp = ({ baseAssets, optionsData }) => {
  const { account } = useWeb3Wagmi()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [redeemAsset, setRedeemAsset] = useState(null)
  const [payAmount, setPayAmount] = useState('')
  const [payAsset, setPayAsset] = useState(null)
  const [liquidityAmount, setLiquidityAmount] = useState('')
  const totalUsdc = useMemo(() => {
    if (payAmount === '' || liquidityAmount === '') return '0'
    return formatAmount(parseFloat(payAmount) + parseFloat(liquidityAmount), false, 2)
  }, [payAmount, liquidityAmount])
  const [duration, setDuration] = useState(null)
  const [percent, setPercent] = useState(50)
  const [slippage, setSlippage] = useState(0.5)
  const { onExcerciseLp } = useExcerciseLp()
  const fusions = useContext(FusionsContext)

  const assets = baseAssets || []
  const option = optionsData.fetchedOptions

  const pool = useMemo(() => {
    return fusions.find((f) => f.gauge.address.toLowerCase() === option.gaugeAddress[0].toLowerCase())
  }, [fusions])

  const timestamp = useTimestamp()
  const secondsUntilEnd = pool?.account.gaugeLockEnd - timestamp

  const notAllowed =
    !account ||
    redeemAsset?.balance.isZero() ||
    Number(redeemAmount) === 0 ||
    redeemAsset?.balance.lt(redeemAmount) ||
    payAsset?.balance.isZero() ||
    payAsset?.balance.lt(BigNumber(totalUsdc.replace(',', ''))) ||
    secondsUntilEnd > duration

  useEffect(() => {
    let currentDuration = Math.abs(
      option.slopeInterceptForLpDiscount.slope.toNumber() * (100 - percent) + option.slopeInterceptForLpDiscount.intercept.toNumber(),
    )
    setDuration(currentDuration)
  }, [optionsData.fetchedOptions])

  const timer = getTimePeriods(duration)

  useEffect(() => {
    if (assets.length === 0) return
    if (!redeemAsset) {
      const oLynxtoken = assets.find((asset) => asset.address.toLowerCase() === getOptionAddress()?.toLowerCase())
      setRedeemAsset(oLynxtoken)
    } else {
      setRedeemAsset(redeemAsset)
    }
    if (!payAsset) {
      const paymentToken = assets.find((asset) => asset.address.toLowerCase() === option.paymentToken.toLowerCase())
      setPayAsset(paymentToken)
    } else {
      setPayAsset(payAsset)
    }
  }, [assets])

  useEffect(() => {
    if (!payAsset) return
    const twapValue = option.getPaymentAmountToExerciseLp.paymentAmount.toNumber() * 2
    const principal = (redeemAmount * twapValue * (100 - percent)) / 100
    setPayAmount(formatUnits(parseInt(principal), payAsset.decimals))
    const liquidity = redeemAmount * option.getPaymentAmountToExerciseLp.paymentAmountToAddLiquidity.toNumber()
    setLiquidityAmount(formatUnits(parseInt(liquidity), payAsset.decimals))
    let currentDuration = Math.abs(
      option.slopeInterceptForLpDiscount.slope.toNumber() * (100 - percent) + option.slopeInterceptForLpDiscount.intercept.toNumber(),
    )
    setDuration(currentDuration)
  }, [redeemAmount, percent])

  return (
    <div className='wrapper-otoken'>
      <div className='line'>
        <p className='title'>oLYNX balance</p>
        <p className='value'>{redeemAsset?.balance ? formatAmount(redeemAsset.balance.toString()) : '-'}</p>
      </div>
      <div className='line'>
        <p className='title'>USDC balance</p>
        <p className='value'>{payAsset?.balance ? formatAmount(payAsset.balance.toString()) : '-'}</p>
      </div>
      <div className='line'>
        <p className='title'>Duration</p>
        <p className='value'>{`${timer.days + timer.months * 30 + timer.years * 365}d, ${timer.hours}h, ${timer.minutes}m`}</p>
      </div>
      <div className='line'>
        <p className='title'>Target Discount</p>
        <Slider
          min={20}
          max={80}
          className='mx-4 my-0 w-full h-[4px]'
          value={percent}
          onChange={(value) => {
            setPercent(value)
          }}
        />
        <p className='value'>{percent}%</p>
      </div>
      <div>
        <div className='line'>
          <p className='text-white title'>You redeem oLYNX</p>
        </div>
        <OtokenInput
          asset={redeemAsset}
          setAsset={setRedeemAsset}
          amount={redeemAmount}
          onInputChange={(val) => {
            setRedeemAmount(val)
          }}
        />
      </div>
      <div>
        <div className='line'>
          <p className='text-white title'>You pay USDC</p>
        </div>
        <OtokenInput asset={payAsset} setAsset={setPayAsset} amount={payAmount} disabled />
      </div>
      <div>
        <div className='line'>
          <p className='text-white title'>You add USDC liquidity</p>
        </div>
        <OtokenInput asset={payAsset} setAsset={setPayAsset} amount={liquidityAmount} disabled />
      </div>
      <div className='flex items-center'>
        <p className='text-white text-base'>Max Slippage</p>
        <input
          className='max-w-[60px] h-[28px] rounded-md bg-white/10 text-white ml-4 mr-2 p-1 text-base block focus-visible:!outline-none'
          type={'number'}
          value={slippage}
          onChange={(e) => setSlippage(e.target.value || 0.5)}
          min={0}
        />
        <span className='text-base text-white'>%</span>
      </div>
      <div className='line'>
        <p className='title'>Total USDC needed</p>
        <p className='value'>{`${totalUsdc} USDC`}</p>
      </div>
      <StyledButton
        content={'REDEEM oLYNX/TOKEN LP'}
        disabled={notAllowed}
        className='w-full py-[13px] text-white lg:text-lg tracking-[1.36px] rounded-md'
        onClickHandler={() => {
          onExcerciseLp(payAsset, payAmount, redeemAmount, liquidityAmount, 100 - percent)
        }}
      />
      {account && (
        <div className='flex'>
          <AddToMetamask
            text='Add oLYNX to Metamask'
            tokenAddress={redeemAsset?.address}
            tokenSymbol={redeemAsset?.symbol}
            tokenDecimals={redeemAsset?.decimals}
            imgUrl={redeemAsset?.logoURI}
          />
        </div>
      )}
    </div>
  )
}

export default Lp
