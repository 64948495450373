const config = [
  {
    title: 'What is the difference between $LYNX and $veLYNX?',
    description: [
      "$LYNX is Lynex's ERC-20 utility token, enabling transactions and interactions within our ecosystem.",
      '$veLYNX, on the other hand, is the vote-escrow version of $LYNX, an ERC-721 governance token in the form of an NFT (non-fungible token).',
      'Holders can exercise voting rights in weekly pool elections and earn a proportionate share of the trading fees and incentives tied to their chosen pools.',
    ],
  },
  {
    title: 'What does it mean that the $veLYNX is locked?',
    description: [
      '$veLYNX represents $LYNX tokens that have been time-locked. As a $veLYNX holder, you retain complete ownership and can utilize its governance power.',
      'After the locked period, you can redeem your $veLYNX back to the freely tradable $LYNX.',
    ],
  },
  {
    title: 'What are the benefits of the $veLYNX token?',
    description: [
      '$veLYNX holders can vote for gauges weekly, and access 100% of the trading fees and bribes for the associated pool. Also, they can partake in governance and cast votes for the protocol improvement proposals.',
      '',
      '',
      '$veLYNX voters receive:',
      'Trading fees generated by the pool(s) they vote for',
      'Bribes deposited for the pools they vote for',
      'Weekly veLYNX distribution (rebase)',
    ],
  },
  {
    title: 'When can I claim my tokens?',
    description: ['You can claim your tokens on February the 15th, one day after the public sale ends.'],
  },
]
export default config
