import React from 'react'
import './info.scss'

const Portal = () => {
  return (
    <div className='info'>
      <div className='title'>
        <p>Claim Portal</p>
        <p className='text-2xl'>$LYNX/veLYNX</p>
      </div>
      <p className='text'>Claim your LYNX & veLYNX Tokens</p>
      <p className='text'>
        veLYNX is the vote-escrowed version of $LYNX. The longer the lock, the higher the amount of veLYNX voting power received. veLYNX holders have access
        100% of the trading fees and 100% of the incentives for the associated pool.{' '}
      </p>
      <div className='flex items-center space-x-2.5 cursor-pointer w-full sm:w-1/2 group'>
        <a className='learn-more' href='https://lynex.gitbook.io/lynex-docs/tokenomics/initial-token-distribution' target='_blank' rel='noreferrer'>
          Learn more
        </a>
        <img className={`group-hover:w-[35px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
      </div>
    </div>
  )
}

export default Portal
