import React, { useContext, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { BIG_ZERO, formatAmount } from '../../../utils/formatNumber'
import { FusionsContext } from '../../../context/FusionsContext'
import { useStake, useUnstake, useHarvest } from '../../../hooks/useGauge'
import StyledButton from '../../common/Buttons/styledButton'
import BalanceInput from '../../common/Input/BalanceInput'
import Toggle from '../../common/Toggle'
import './otoken.scss'
import { getTimePeriods, useTimestamp } from '../../../utils/time'

const Lp2 = ({ optionsData }) => {
  const [isStake, setIsStake] = useState(true)
  const [stakeAmount, setStakeAmount] = useState('')
  const { onStake, pending: stakePending } = useStake()
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const { onUnstake, pending: unstakePending } = useUnstake()
  const { onHarvest, pending: claimPending } = useHarvest()

  const fusions = useContext(FusionsContext)
  const option = optionsData.fetchedOptions
  const pool = useMemo(() => {
    return fusions.find((f) => f.gauge.address.toLowerCase() === option.gaugeAddress[0].toLowerCase())
  }, [fusions])

  const stakedLocked = pool?.account.gaugeLockedBalance || BIG_ZERO
  const lockedRatio = stakedLocked.div(pool?.account.gaugeBalance)
  const lockedUsd = lockedRatio.times(pool?.account.stakedUsd)
  const unlockedUsd = pool?.account.stakedUsd.minus(lockedUsd)
  const stakedUnlocked = pool?.account.gaugeBalance.minus(stakedLocked)

  const timestamp = useTimestamp()
  const secondsUntilEnd = pool?.account.gaugeLockEnd - timestamp
  const timer = getTimePeriods(secondsUntilEnd)

  const disableClaim = !pool || pool?.account.gaugeEarned.eq(BIG_ZERO)
  const disableStakeUnstake =
    !pool || (isStake ? pool.account.lpBalance.eq(BIG_ZERO) : pool.account.gaugeBalance.eq(BIG_ZERO) || stakedUnlocked.lt(BigNumber(withdrawAmount)))

  return (
    <div className='bg-white bg-opacity-10 px-6 z-[10] rounded-[10px]'>
      <div className='wrapper-otoken lg:w-[400px]'>
        <div className='block'>
          <div className='flex space-x-2 items-center'>
            <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Withdraw</p>
            <Toggle checked={isStake} onChange={() => setIsStake(!isStake)} toggleId='stakeOrWithdraw' />
            <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Stake</p>
          </div>
          <div className='line'>
            <p className='title'>Total staked</p>
            <p className='value'>{pool?.gauge.tvl ? formatAmount(pool.gauge.tvl) : '-'} USD</p>
          </div>
          <div className='line pb-3 border-b border-slate-500'>
            <p className='title'>APR</p>
            <p className='value'>{formatAmount(pool?.gauge.apr || 0)}%</p>
          </div>
          <div className='line'>
            <p className='title'>Staked without lock</p>
            <p className='value'>{pool?.account ? `$${formatAmount(unlockedUsd.toNumber())}` : '-'}</p>
          </div>
          <div className='line'>
            <p className='title'>Staked with lock</p>
            <p className='value'>{pool?.account ? `$${formatAmount(lockedUsd.toNumber())}` : '-'}</p>
          </div>
          <div className='line'>
            <p className='title'>Total Staked</p>
            <p className='value'>{pool?.account ? `$${formatAmount(pool?.account.stakedUsd)}` : '-'}</p>
          </div>
          <div className='line'>
            <p className='title'>Unlock Time</p>
            <p className='value'>{pool?.account ? `${timer.days + timer.months * 30}d, ${timer.hours}h, ${timer.minutes}m` : '-'}</p>
          </div>
          <BalanceInput
            inputAmount={isStake ? stakeAmount : withdrawAmount}
            setInputAmount={isStake ? setStakeAmount : setWithdrawAmount}
            symbol={pool?.symbol}
            balance={isStake ? pool?.account.lpBalance : pool?.account.gaugeBalance}
            logoURIs={[pool?.token0.logoURI, pool?.token1.logoURI]}
            sm
          />
          <StyledButton
            disabled={disableStakeUnstake}
            onClickHandler={() => {
              if (stakePending || unstakePending || disableStakeUnstake) {
                return
              }
              isStake ? onStake(pool, stakeAmount) : onUnstake(pool, withdrawAmount)
            }}
            content={isStake ? (stakePending ? 'PENDING...' : 'STAKE') : unstakePending ? 'PENDING...' : 'WITHDRAW'}
            className='w-full py-[13px] text-white lg:text-lg tracking-[1.36px] rounded-md'
          />
        </div>
        <div className='block'>
          <div className='line'>
            <p className='title !font-bold !text-white'>Earned</p>
          </div>
          <div className='line'>
            <p className='title'>Claimable rewards</p>
            <p className='value'>{pool?.account ? formatAmount(pool.account.gaugeEarned) : '0'} oLYNX</p>
          </div>
          <StyledButton
            content={`${claimPending ? 'PENDING...' : 'CLAIM'}`}
            disabled={disableClaim}
            className='w-full py-[13px] text-white lg:text-lg tracking-[1.36px] rounded-md'
            onClickHandler={() => {
              if (claimPending || disableClaim) {
                return
              }
              onHarvest(pool)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Lp2
