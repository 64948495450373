import { useDispatch, useSelector } from 'react-redux'
import {
  addBookMarkPair,
  addBookMarkToken,
  addImportedToken,
  removeImportedToken,
  clearImportedToken,
  removeBookmarkPair,
  removeBookmarkToken,
  updateBookmarkTokens,
} from './actions'
import { useCallback } from 'react'
import { useWeb3Wagmi } from '../../hooks/useWeb3'

export function useBlockNumber() {
  const { chainId } = useWeb3Wagmi()

  return useSelector((state) => state.application.blockNumber[chainId ?? -1])
}

export function useBookmarkTokens() {
  const bookmarkedTokens = useSelector((state) => state.application.bookmarkedTokens)
  const dispatch = useDispatch()
  const _addBookmarkToken = useCallback(
    (token) => {
      dispatch(addBookMarkToken(token))
    },
    [dispatch],
  )
  const _removeBookmarkToken = useCallback(
    (token) => {
      dispatch(removeBookmarkToken(token))
    },
    [dispatch],
  )
  const _updateBookmarkTokens = useCallback(
    (tokens) => {
      dispatch(updateBookmarkTokens(tokens))
    },
    [dispatch],
  )
  return {
    bookmarkTokens: bookmarkedTokens,
    addBookmarkToken: _addBookmarkToken,
    removeBookmarkToken: _removeBookmarkToken,
    updateBookmarkTokens: _updateBookmarkTokens,
  }
}

export function useBookmarkPairs() {
  const bookmarkedPairs = useSelector((state) => state.application.bookmarkedPairs)
  const dispatch = useDispatch()
  const _addBookmarkPair = useCallback(
    (pair) => {
      dispatch(addBookMarkPair(pair))
    },
    [dispatch],
  )
  const _removeBookmarkPair = useCallback(
    (pair) => {
      dispatch(removeBookmarkPair(pair))
    },
    [dispatch],
  )
  const _updateBookmarkPairs = useCallback(
    (pairs) => {
      dispatch(updateBookmarkTokens(pairs))
    },
    [dispatch],
  )
  return {
    bookmarkPairs: bookmarkedPairs,
    addBookmarkPair: _addBookmarkPair,
    removeBookmarkPair: _removeBookmarkPair,
    updateBookmarkPairs: _updateBookmarkPairs,
  }
}

export function useImportTokens() {
  const importedTokens = useSelector((state) => state.application.importedTokens)
  const dispatch = useDispatch()
  const _addImportedToken = useCallback(
    (token) => {
      if (!importedTokens.some((asset) => asset.address === token.address)) {
        const toAdd = { ...token }
        delete toAdd.balance
        dispatch(addImportedToken(toAdd))
      }
    },
    [dispatch],
  )
  const _removeImportedToken = useCallback(
    (token) => {
      if (importedTokens.some((asset) => asset.address === token.address)) {
        dispatch(removeImportedToken(token))
      }
    },
    [dispatch],
  )
  const _clearImportedToken = useCallback(() => {
    dispatch(clearImportedToken())
  }, [dispatch])
  return {
    importedTokens: importedTokens,
    addImportedToken: _addImportedToken,
    removeImportedToken: _removeImportedToken,
    clearImportedToken: _clearImportedToken,
  }
}
