import React, { useState, useEffect, useContext } from 'react'
import BigNumber from 'bignumber.js'
import { fetchUserFusions } from '../utils/fetchUserPairs'
import usePrices from '../hooks/usePrices'
import { getFusions } from '../utils/api'
import { ZERO_VALUE } from '../utils/formatNumber'
import { Presets } from '../state/mintV3/reducer'
import { PoolTypes, scSanityCheckEnabled } from '../config/constants'
import { useExtraRewardsInfo } from '../hooks/useGeneral'
import { BaseAssetsConetext } from './BaseAssetsConetext'

import { useWeb3Wagmi } from '../hooks/useWeb3'
import { bribeAddresses, gaugesAddresses } from '../config/auto'
import { ZERO_ADDRESS } from '../config/constants/v3/misc'
const FusionsContext = React.createContext([])

const FusionsContextProvider = ({ children }) => {
  const [fusions, setFusions] = useState([])
  const { account } = useWeb3Wagmi()
  const prices = usePrices()
  const extraRewardsInfo = useExtraRewardsInfo()
  const assets = useContext(BaseAssetsConetext)

  useEffect(() => {
    const getUserData = async () => {
      try {
        let { data: fusions } = await getFusions()
        if (scSanityCheckEnabled) {
          const filteredPools = fusions.filter(
            (pool) =>
              (gaugesAddresses.includes(pool.gauge?.address.toLowerCase()) && bribeAddresses.includes(pool.gauge?.bribe.toLowerCase())) ||
              pool.gauge?.address.toLowerCase() === ZERO_ADDRESS,
          )
          fusions = filteredPools
        }
        if (fusions.length > 0) {
          let bnbTheNarrow = '0xed044cd5654ad208b1bc594fd108c132224e3f3c'
          let bnbTheWide = '0xe8ec29b75d98d3cdc47db9797b00dcaabea2b15b'
          var bnbthe = '0x63Db6ba9E512186C2FAaDaCEF342FB4A40dc577c'
          var busdthe = '0x34B897289fcCb43c048b2Cea6405e840a129E021'
          var usdtthe = '0xA051eF9A6FBea340Bb734d022e7B6a3aD9fD9B06'
          let userInfos = []
          if (account) {
            userInfos = await fetchUserFusions(account, fusions)
          }

          const totalWeight = fusions.reduce((sum, current) => {
            return sum.plus(current.gauge.weight)
          }, new BigNumber(0))

          const userInfo = fusions
            .map((fusion) => {
              let kind
              if (fusion.isGamma) {
                if (['narrow', 'wide', 'yieldiq'].includes(fusion.type.toLowerCase())) {
                  kind = PoolTypes.FUSION
                } else {
                  kind = PoolTypes.STABLE
                }
              } else {
                if (fusion.type.toLowerCase() === 'stable') {
                  kind = PoolTypes.STABLE
                } else {
                  kind = PoolTypes.V1
                }
              }
              const asset0 = assets.find((ele) => ele.address.toLowerCase() === fusion.token0.address.toLowerCase())
              const asset1 = assets.find((ele) => ele.address.toLowerCase() === fusion.token1.address.toLowerCase())
              const token0 = {
                address: asset0?.address || fusion.token0.address,
                symbol: asset0?.symbol || 'UNKNOWN',
                decimals: asset0?.decimals || 18,
                logoURI: asset0?.logoURI || '/images/tokens/UKNOWN.png',
                price: asset0?.price || 0,
              }
              const token1 = {
                address: asset1?.address || fusion.token1.address,
                symbol: asset1?.symbol || 'UNKNOWN',
                decimals: asset1?.decimals || 18,
                logoURI: asset1?.logoURI || '/images/tokens/UKNOWN.png',
                price: asset1?.price || 0,
              }
              const token0Reserve = new BigNumber(fusion.token0.reserve)
              const token1Reserve = new BigNumber(fusion.token1.reserve)
              let totalTvl
              if (token0.price > 0 && token1.price > 0) {
                totalTvl = token0Reserve.times(token0.price).plus(token1Reserve.times(token1.price))
              } else if (token0.price > 0) {
                totalTvl = token0Reserve.times(token0.price).times(2)
              } else if (token1.price > 0) {
                totalTvl = token1Reserve.times(token1.price).times(2)
              } else {
                totalTvl = new BigNumber(0)
              }
              const lpPrice = fusion.totalSupply > 0 ? totalTvl.div(fusion.totalSupply) : new BigNumber(0)
              const gaugeTvl = lpPrice.times(fusion.gauge.totalSupply)
              const weightPercent = totalWeight.isZero() ? new BigNumber(0) : new BigNumber(fusion.gauge.weight).div(totalWeight).times(100)
              let bribeUsd = new BigNumber(0)
              fusion.gauge.bribes = JSON.parse(fusion.gauge.bribes)
              const poolBribes = fusion.gauge.bribes
              if (poolBribes) {
                if (poolBribes.bribe) {
                  poolBribes.bribe.forEach((ele) => {
                    const found = assets.find((asset) => asset.address.toLowerCase() === ele.address.toLowerCase())
                    bribeUsd = bribeUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                  })
                }
                if (poolBribes.fee) {
                  poolBribes.fee.forEach((ele) => {
                    const found = assets.find((asset) => asset.address.toLowerCase() === ele.address.toLowerCase())
                    bribeUsd = bribeUsd.plus(new BigNumber(ele.amount).times(found ? found.price : 0))
                  })
                }
              }
              const found = userInfos.find((item) => item.address.toLowerCase() === fusion.address.toLowerCase())
              let user = {
                lpBalance: ZERO_VALUE,
                gaugeBalance: ZERO_VALUE,
                gaugeEarned: ZERO_VALUE,
                totalLp: ZERO_VALUE,
                token0claimable: ZERO_VALUE,
                token1claimable: ZERO_VALUE,
                staked0: ZERO_VALUE,
                staked1: ZERO_VALUE,
                stakedUsd: ZERO_VALUE,
                earnedUsd: ZERO_VALUE,
                total0: ZERO_VALUE,
                total1: ZERO_VALUE,
                totalUsd: ZERO_VALUE,
              }
              let extraApr = ZERO_VALUE
              let extraRewards = {}
              let extraRewardsInUsd = ZERO_VALUE
              const foundExtra = extraRewardsInfo.find((ele) => ele.pairAddress === fusion.address)
              if (foundExtra) {
                extraApr = foundExtra['rewardRate'].times(86400).times(365).times(prices[foundExtra.doubleRewarderSymbol]).div(gaugeTvl).times(100)
                extraRewards = {
                  amount: foundExtra['pendingReward'],
                  symbol: foundExtra.doubleRewarderSymbol,
                }
                extraRewardsInUsd = extraRewards.amount.times(prices[foundExtra.doubleRewarderSymbol])
              }
              if (found) {
                user = {
                  ...found,
                  staked0: fusion.totalSupply ? found.gaugeBalance.times(fusion.token0.reserve).div(fusion.totalSupply) : ZERO_VALUE,
                  staked1: fusion.totalSupply ? found.gaugeBalance.times(fusion.token1.reserve).div(fusion.totalSupply) : ZERO_VALUE,
                  stakedUsd: found.gaugeBalance.times(lpPrice),
                  earnedUsd: found.gaugeEarned.times(prices['LYNX']).plus(extraRewardsInUsd),
                  total0: fusion.totalSupply ? found.totalLp.times(fusion.token0.reserve).div(fusion.totalSupply) : ZERO_VALUE,
                  total1: fusion.totalSupply ? found.totalLp.times(fusion.token1.reserve).div(fusion.totalSupply) : ZERO_VALUE,
                  totalUsd: found.totalLp.times(lpPrice),
                  extraRewards,
                }
              }
              return {
                ...fusion,
                stable: fusion.type === 'Stable',
                title: Presets[(fusion.isGamma ? 'GAMMA_' : '') + fusion.type.toUpperCase()] || 'VOLATILE',
                kind,
                tvl: totalTvl,
                token0: {
                  ...token0,
                  reserve: new BigNumber(fusion.token0.reserve),
                },
                token1: {
                  ...token1,
                  reserve: new BigNumber(fusion.token1.reserve),
                },
                gauge: {
                  ...fusion.gauge,
                  tvl: gaugeTvl,
                  apr: new BigNumber(fusion.gauge.apr).plus(extraApr),
                  voteApr: new BigNumber(fusion.gauge.voteApr),
                  projectedApr: new BigNumber(fusion.gauge.projectedApr),
                  weight: new BigNumber(fusion.gauge.weight),
                  weightPercent,
                  bribeUsd,
                  pooled0: fusion.totalSupply ? new BigNumber(fusion.token0.reserve).times(fusion.gauge.totalSupply).div(fusion.totalSupply) : new BigNumber(0),
                  pooled1: fusion.totalSupply ? new BigNumber(fusion.token1.reserve).times(fusion.gauge.totalSupply).div(fusion.totalSupply) : new BigNumber(0),
                },
                // TODO: Revisit this
                isValid: true, // fusion.address === '0x5929dbbc11711d2b9e9ca0752393c70de74261f5' ? false : fusion.isValid,
                account: user,
              }
            })
            .sort((a, b) => {
              return a.gauge.tvl.minus(b.gauge.tvl).times(-1).toNumber()
            })
            .sort(function (x, y) {
              return x.address == busdthe.toLowerCase() ? -1 : y.address == busdthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbthe.toLowerCase() ? -1 : y.address == bnbthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == usdtthe.toLowerCase() ? -1 : y.address == usdtthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbTheWide.toLowerCase() ? -1 : y.address == bnbTheWide ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbTheNarrow.toLowerCase() ? -1 : y.address == bnbTheNarrow ? 1 : 0
            })
          setFusions(userInfo)
        }
      } catch (e) {
        console.error('user fusions fetched had error', e)
      }
    }
    if (assets.length > 0) {
      getUserData()
    }
  }, [account, assets])

  return <FusionsContext.Provider value={fusions}>{children}</FusionsContext.Provider>
}

export { FusionsContext, FusionsContextProvider }
