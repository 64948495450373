import invariant from 'tiny-invariant'
import { NativeCurrency } from '@uniswap/sdk-core'
import { WETH_EXTENDED } from './constants'

/**
 * Ether is the main usage of a 'native' currency, i.e. for Ethereum mainnet and all testnets
 */
export class ETH extends NativeCurrency {
  static _etherCache = {}

  constructor(chainId) {
    super(chainId, 18, 'ETH', 'ETH')
  }

  get wrapped() {
    const weth9 = WETH_EXTENDED[this.chainId]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  static onChain(chainId) {
    return this._etherCache[chainId] ?? (this._etherCache[chainId] = new ETH(chainId))
  }

  equals(other) {
    return other.isNative && other.chainId === this.chainId
  }
}
