import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TaxAssets, TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getERC20Contract, getGaugeContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, MAX_UINT256, toWei } from '../utils/formatNumber'
import { getRouterAddress, getWBNBAddress } from '../utils/addressHelpers'
import { useRouter } from './useContract'
import moment from 'moment'

const useMigrateToFusion = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleMigrateToFusion = useCallback(
    async (pair, fusion) => {
      const key = uuidv4()
      const unstakeuuid = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Migrate ${pair.token0.symbol}/${pair.token1.symbol} (${pair.title})`,
          transactions: {
            [unstakeuuid]: {
              desc: `Unstake LP tokens from the gauge`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
            [approveuuid]: {
              desc: `Approve ${pair.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Stake LP tokens to new gauge`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      // Unstake LP
      let isUnstaked = true
      const gaugeContract = getGaugeContract(web3, pair.gauge.address)
      if (pair.account.gaugeBalance.gt(0)) {
        isUnstaked = false
        const unstakeParams = [toWei(pair.account.gaugeBalance, pair.decimals).toFixed(0)]
        setPending(true)
        try {
          await sendContract(dispatch, key, unstakeuuid, gaugeContract, 'withdraw', unstakeParams, account)
        } catch (err) {
          console.log('unstake error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isUnstaked) {
        dispatch(
          updateTransaction({
            key,
            uuid: unstakeuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      // Claim earnings
      let isHarvested = true
      if (pair.account.gaugeEarned.gt(0)) {
        isHarvested = false
        try {
          await sendContract(dispatch, key, harvestuuid, gaugeContract, 'getReward', [], account)
        } catch (err) {
          console.log('harvest error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isHarvested) {
        dispatch(
          updateTransaction({
            key,
            uuid: harvestuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      // Approve
      let isApproved = true
      const tokenContract = getERC20Contract(web3, pair.address)
      const allowance = await getAllowance(tokenContract, fusion.gauge.address, account)
      const lpBalance = await tokenContract.methods.balanceOf(account).call()
      if (fromWei(allowance, pair.decimals).lt(fromWei(lpBalance))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [fusion.gauge.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      // Stake
      const params = [lpBalance]
      const fusionContract = getGaugeContract(web3, fusion.gauge.address)
      try {
        await sendContract(dispatch, key, stakeuuid, fusionContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Migration Successful',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onMigrateToFusion: handleMigrateToFusion, pending }
}

const useMigrateToGamma = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const routerContract = useRouter()

  const handleMigrateToGamma = useCallback(
    async (pair, gamma) => {
      const key = uuidv4()
      const unstakeuuid = uuidv4()
      const harvestuuid = uuidv4()
      const approveuuid = uuidv4()
      const removeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Migrate ${pair.token0.symbol}/${pair.token1.symbol} (${pair.title})`,
          transactions: {
            [unstakeuuid]: {
              desc: `Unstake LP tokens from the gauge`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
            [approveuuid]: {
              desc: `Approve ${pair.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      // Unstake LP
      let isUnstaked = true
      const gaugeContract = getGaugeContract(web3, pair.gauge.address)
      if (pair.account.gaugeBalance.gt(0)) {
        isUnstaked = false
        const unstakeParams = [toWei(pair.account.gaugeBalance, pair.decimals).toFixed(0)]
        setPending(true)
        try {
          await sendContract(dispatch, key, unstakeuuid, gaugeContract, 'withdraw', unstakeParams, account)
        } catch (err) {
          console.log('unstake error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isUnstaked) {
        dispatch(
          updateTransaction({
            key,
            uuid: unstakeuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      // Claim earnings
      let isHarvested = true
      if (pair.account.gaugeEarned.gt(0)) {
        isHarvested = false
        try {
          await sendContract(dispatch, key, harvestuuid, gaugeContract, 'getReward', [], account)
        } catch (err) {
          console.log('harvest error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isHarvested) {
        dispatch(
          updateTransaction({
            key,
            uuid: harvestuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      // Approve
      let isApproved = true
      const tokenContract = getERC20Contract(web3, pair.address)
      const allowance = await getAllowance(tokenContract, getRouterAddress(), account)
      const lpBalance = await tokenContract.methods.balanceOf(account).call()
      if (fromWei(allowance, pair.decimals).lt(fromWei(lpBalance))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getRouterAddress(), MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const sendAmount = lpBalance
      const sendAmount0Min = 0
      const sendAmount1Min = 0
      const deadlineVal =
        '' +
        moment()
          .add(20 * 60, 'seconds')
          .unix()

      let func = 'removeLiquidity'
      let params = [pair.token0.address, pair.token1.address, pair.stable, sendAmount, sendAmount0Min, sendAmount1Min, account, deadlineVal]

      if (pair.token0.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
        func = TaxAssets.includes(pair.token1.address.toLowerCase()) ? 'removeLiquidityETHSupportingFeeOnTransferTokens' : 'removeLiquidityETH'
        params = [pair.token1.address, pair.stable, sendAmount, sendAmount1Min, sendAmount0Min, account, deadlineVal]
      }
      if (pair.token1.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
        func = TaxAssets.includes(pair.token0.address.toLowerCase()) ? 'removeLiquidityETHSupportingFeeOnTransferTokens' : 'removeLiquidityETH'
        params = [pair.token0.address, pair.stable, sendAmount, sendAmount0Min, sendAmount1Min, account, deadlineVal]
      }
      try {
        await sendContract(dispatch, key, removeuuid, routerContract, func, params, account)
      } catch (err) {
        console.log('remove error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Withdrawal Successful',
          link: `/liquidity/fusion?currency0=${gamma.token0.address}&currency1=${gamma.token1.address}&strategy=${gamma.title}`,
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onMigrateToGamma: handleMigrateToGamma, pending }
}

export { useMigrateToFusion, useMigrateToGamma }
