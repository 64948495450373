import { CurrencyAmount, Price } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { USDC as USDC_V3_TOKEN } from '../../config/constants/v3/routing'
import { useBestV3TradeExactOut } from './useBestV3Trade'

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.

// Two different consts used as a hack for allLiquidity flag in useUSDTPrice fn.
// Doing another way makes amounts in EnterAmount stuck somehow.
const STABLECOIN_AMOUNT_OUT_ALL = CurrencyAmount.fromRawAmount(USDC_V3_TOKEN, 1)
const STABLECOIN_AMOUNT_OUT_FILTERED = CurrencyAmount.fromRawAmount(USDC_V3_TOKEN, 1_000e1)

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDTPrice(currency, allLiquidity) {
  const amountOut = allLiquidity ? STABLECOIN_AMOUNT_OUT_ALL : STABLECOIN_AMOUNT_OUT_FILTERED
  const stablecoin = amountOut?.currency

  const v3USDCTrade = useBestV3TradeExactOut(currency, amountOut)

  return useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    if (v3USDCTrade.trade) {
      const { numerator, denominator } = v3USDCTrade.trade.route.midPrice
      return new Price(currency, stablecoin, denominator, numerator)
    }

    return undefined
  }, [currency, stablecoin, v3USDCTrade.trade])
}

export function useUSDTValue(currencyAmount, allLiquidity = false) {
  const price = useUSDTPrice(currencyAmount?.currency, allLiquidity)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}
