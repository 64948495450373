import BigNumber from 'bignumber.js'
import { fromWei } from './formatNumber'
import { managers } from '../config/constants/managers'
import { multicall } from './multicall'
import { veTheV3ApiAbi } from '../config/abi'
import { getVeTHEV3APIAddress } from './addressHelpers'

export const fetchManagersData = async () => {
  const calls = managers.map((manager) => {
    return {
      address: getVeTHEV3APIAddress(),
      name: 'getVotesFromAddress',
      params: [manager.address],
    }
  })

  const callsResult = await multicall(veTheV3ApiAbi, calls)

  const managersInfo = callsResult.map((call, index) => {
    const voteData = call[0]
    const votes = voteData.votes
    const totalVotes = votes.reduce((sum, current) => {
      return sum.plus(current.weight.toString())
    }, new BigNumber(0))
    return {
      ...managers[index],
      votingPower: fromWei(voteData.votingPower.toString()),
      epochVotes: fromWei(voteData.epochVotes.toString()),
      nextEpochVotes: fromWei(voteData.nextEpochVotes.toString()),
      voteTs: voteData.voteTs.toString(),
      voted: voteData.voted && voteData.votes.length > 0,
      votedCurrentEpoch: voteData.voted && voteData.votes.length > 0,
      votes: votes.map((item) => {
        return {
          address: item.pair,
          weight: fromWei(item.weight.toString()),
          weightPercent: totalVotes.isZero() ? new BigNumber(0) : new BigNumber(item.weight.toString()).div(totalVotes).times(100),
        }
      }),
    }
  })

  return managersInfo
}
