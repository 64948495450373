import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSearchParams } from 'react-router-dom'
import Settings from '../../common/Settings'
import TokenInput from '../../common/Input/TokenInput'
import useWalletModal from '../../../hooks/useWalletModal'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { useProceedSwap } from '../../../hooks/useSwap'
import StyledButton from '../../common/Buttons/styledButton'
// import SwapPopup from './popups/swapPopup'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import { useCurrency } from '../../../hooks/v3/Tokens'
import { Field } from '../../../state/mintV3/actions'
import { tryParseAmount, unwrappedToken } from '../../../v3lib/utils/utils'
import { useBestV3TradeExactIn, useBestV3TradeExactOut } from '../../../hooks/v3/useBestV3Trade'
import { computeRealizedLPFeePercent } from '../../../v3lib/utils/computeRealizedLPFeePercent'
import { formatAmount } from '../../../utils/formatNumber'
import { Percent, TradeType } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { useSwapCallback } from '../../../hooks/v3/useSwapCallback'
import WarningModal from './WarningModal'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const SwapFusion = () => {
  const [init, setInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [fromAsset, setFromAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const [setting, setSetting] = useState(false)
  const [reverseTransiction, setReverseTransiction] = useState(false)
  const [independentField, setIndependentField] = useState(Field.CURRENCY_A)
  const [typedValue, setTypedValue] = useState('')
  const [slippage, setSlippage] = useState(0.5)
  const [deadline, setDeadline] = useState(20)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const { onWrap, onUnwrap, swapPending: wrapPending } = useProceedSwap()
  const [searchParams] = useSearchParams()
  const baseAssets = useContext(BaseAssetsConetext)
  const inCurrency = useCurrency(fromAsset ? fromAsset.address : undefined)
  const outCurrency = useCurrency(toAsset ? toAsset.address : undefined)

  const isWrap = useMemo(() => {
    if (fromAsset && toAsset && fromAsset.address === 'ETH' && toAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const isUnwrap = useMemo(() => {
    if (fromAsset && toAsset && toAsset.address === 'ETH' && fromAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const showWrap = useMemo(() => {
    return isWrap || isUnwrap
  }, [isWrap, isUnwrap])

  const isExactIn = independentField === Field.CURRENCY_A
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inCurrency : outCurrency) ?? undefined)
  const bestV3TradeExactIn = useBestV3TradeExactIn(isExactIn ? parsedAmount : undefined, outCurrency ?? undefined)
  const bestV3TradeExactOut = useBestV3TradeExactOut(inCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)
  const v3Trade = (isExactIn ? bestV3TradeExactIn : bestV3TradeExactOut) ?? undefined
  const bestTrade = v3Trade.trade ?? undefined

  const allowedSlippage = new Percent(JSBI.BigInt(slippage * 100), JSBI.BigInt(10000))

  const { error, callback: swapCallback, pending: swapPending } = useSwapCallback(bestTrade, allowedSlippage, deadline)
  console.log('error :>> ', error)

  const dynamicFee = useMemo(() => {
    if (!bestTrade) return
    return bestTrade.swaps[0]?.route?.pools[0].fee
  }, [bestTrade])

  const { realizedLPFee, priceImpact } = useMemo(() => {
    if (!bestTrade) return { realizedLPFee: undefined, priceImpact: undefined }

    const realizedLpFeePercent = computeRealizedLPFeePercent(bestTrade)
    const realizedLPFee = bestTrade.inputAmount.multiply(realizedLpFeePercent)
    const priceImpact = bestTrade.priceImpact.subtract(realizedLpFeePercent)
    return { priceImpact, realizedLPFee }
  }, [bestTrade])

  const priceImpactInNumber = useMemo(() => {
    return priceImpact ? Number(priceImpact.toSignificant()) : 0
  }, [priceImpact])

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.CURRENCY_A]: parsedAmount,
            [Field.CURRENCY_B]: parsedAmount,
          }
        : {
            [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? parsedAmount : bestTrade?.inputAmount,
            [Field.CURRENCY_B]: independentField === Field.CURRENCY_B ? parsedAmount : bestTrade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, bestTrade],
  )

  const formattedAmounts = useMemo(() => {
    const dependentField = isExactIn ? Field.CURRENCY_B : Field.CURRENCY_A
    return {
      [independentField]: typedValue,
      [dependentField]: showWrap ? parsedAmounts[independentField]?.toExact() ?? '' : parsedAmounts[dependentField]?.toExact() ?? '',
    }
  }, [isExactIn, showWrap, parsedAmounts, independentField, typedValue])

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (!fromAsset || !toAsset) {
      return {
        isError: true,
        label: 'SELECT A TOKEN',
      }
    }

    if (!parsedAmount) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (fromAsset.balance && fromAsset.balance.lt(parsedAmounts[Field.CURRENCY_A]?.toExact())) {
      return {
        isError: true,
        label: 'INSUFFICIENT ' + fromAsset.symbol + ' BALANCE',
      }
    }

    if (isWrap) {
      return {
        isError: false,
        label: 'WRAP',
      }
    }

    if (isUnwrap) {
      return {
        isError: false,
        label: 'UNWRAP',
      }
    }

    if (!bestTrade) {
      return {
        isError: true,
        label: 'INSUFFICIENT LIQUIDITY FOR THIS TRADE',
      }
    }

    return {
      isError: false,
      label: 'SWAP',
    }
  }, [account, fromAsset, toAsset, parsedAmount, parsedAmounts, isWrap, isUnwrap])

  useEffect(() => {
    if (init || baseAssets.length === 0) return
    const inputCurrency = searchParams.get('inputCurrency')
    const outputCurrency = searchParams.get('outputCurrency')
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'USDC'))
    } else {
      setToAsset(to)
    }
    setInit(true)
  }, [baseAssets, searchParams])

  useEffect(() => {
    // NOTE: Refreshes balances
    if (baseAssets.length === 0) return
    const inputCurrency = fromAsset
    const outputCurrency = toAsset
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.address.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.address.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'USDC'))
    } else {
      setToAsset(to)
    }
  }, [baseAssets])

  const onInputFieldChange = (val) => {
    setIndependentField(Field.CURRENCY_A)
    setTypedValue(val)
  }

  const onOutputFieldChange = (val) => {
    setIndependentField(Field.CURRENCY_B)
    setTypedValue(val)
  }

  return (
    <>
      <div className='w-full max-w-[588px] mx-auto relative mt-[25px] pb-28 xl:pb-0 2xl:pb-[32px]'>
        <OutsideClickHandler
          onOutsideClick={() => {
            setSetting(false)
          }}
        >
          <div className='bg-white bg-opacity-10 p-px relative z-[10] rounded-[10px]'>
            <div className='rounded-[5px] px-3 md:px-6 py-3 md:py-4'>
              <div className='flex items-center justify-between'>
                <p className='f-f-fg text-[23px] md:text-[27px] leading-10 text-white font-semibold'>Swap</p>
                <button
                  onClick={() => {
                    setSetting(!setting)
                  }}
                  className=''
                >
                  <img alt='' src='/images/swap/bar.svg' />
                </button>
              </div>
              <div className='mt-3'>
                <div className='flex flex-col w-full items-center justify-center'>
                  <TokenInput
                    title='From'
                    asset={fromAsset}
                    isDollar
                    setAsset={setFromAsset}
                    otherAsset={toAsset}
                    setOtherAsset={setToAsset}
                    amount={formattedAmounts[Field.CURRENCY_A]}
                    onInputChange={(val) => {
                      onInputFieldChange(val)
                    }}
                  />

                  <button
                    onClick={() => {
                      const tempAsset = fromAsset
                      setFromAsset(toAsset)
                      setToAsset(tempAsset)
                    }}
                    className='focus:outline-none mt-5 z-[8]'
                  >
                    <img src='/images/swap/reverse-icon.svg' />
                  </button>
                  <TokenInput
                    title='To'
                    asset={toAsset}
                    isDollar
                    setAsset={setToAsset}
                    otherAsset={fromAsset}
                    setOtherAsset={setFromAsset}
                    amount={formattedAmounts[Field.CURRENCY_B]}
                    onInputChange={(val) => {
                      onOutputFieldChange(val)
                    }}
                    disabled
                  />
                </div>
              </div>

              <div className='mt-3'>
                <div className='flex items-center justify-between'>
                  <p className='text-white text-sm md:text-base leading-5'>Slippage Tolerance</p>
                  <p className='text-white text-sm md:text-base leading-5'>{slippage}%</p>
                </div>
              </div>

              {priceImpactInNumber > 5 && (
                <div className='mt-3'>
                  <div className='flex items-center justify-center p-5 bg-[#080808] border border-error rounded-[3px]'>
                    <p className='text-white text-sm md:text-base font-semibold'>
                      Price Impact Too High: <span className='text-error'>{formatAmount(priceImpactInNumber)}%</span>
                    </p>
                  </div>
                </div>
              )}

              {account ? (
                <StyledButton
                  disabled={btnMsg.isError || wrapPending || swapPending}
                  pending={wrapPending || swapPending}
                  onClickHandler={() => {
                    if (isWrap) {
                      onWrap(parsedAmount?.toExact())
                    } else if (isUnwrap) {
                      onUnwrap(parsedAmount?.toExact())
                    } else {
                      if (priceImpactInNumber > 5) {
                        setIsWarning(true)
                      } else {
                        swapCallback()
                      }
                    }
                  }}
                  content={btnMsg.label}
                  className='py-[13px] md:py-[14.53px] text-white mt-2 md:mt-3 md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
                />
              ) : (
                <StyledButton
                  onClickHandler={() => openWalletModal()}
                  content={'CONNECT WALLET'}
                  className='py-[13px] md:py-[14.53px] text-white mt-2 md:mt-3 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
                />
              )}
              {bestTrade && !showWrap && (
                <>
                  <div className='flex items-center justify-between mt-3'>
                    <p className='text-white text-sm md:text-base leading-5'>Price</p>
                    <div className='flex items-center space-x-1.5'>
                      <p className='text-white text-sm md:text-base leading-5'>
                        {reverseTransiction
                          ? `${bestTrade.executionPrice.toSignificant(4)} ${toAsset.symbol} per ${fromAsset.symbol}`
                          : `${Number(bestTrade.executionPrice.toSignificant()) === 0 ? 0 : bestTrade.executionPrice.invert().toSignificant(4)} ${
                              fromAsset.symbol
                            } per ${toAsset.symbol}`}
                      </p>
                      <button onClick={() => setReverseTransiction(!reverseTransiction)}>
                        <img alt='' src='/images/swap/reverse-small-icon.png' />
                      </button>
                    </div>
                  </div>
                  <div className='mt-[0.3rem]'>
                    <div className='flex items-center justify-between'>
                      <p className='text-white text-sm md:text-base leading-5'>Fee</p>
                      <div className='flex gap-x-2 items-center'>
                        <p className='text-white text-sm md:text-base leading-5'>
                          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${realizedLPFee.currency.symbol}` : '-'}
                        </p>
                        <p className='text-slate-400 text-xs md:text-sm leading-5'>{`(${dynamicFee / 10000}%)`}</p>
                      </div>
                    </div>
                  </div>
                  {priceImpactInNumber > 0 && (
                    <div className='mt-[0.3rem]'>
                      <div className='flex items-center justify-between'>
                        <p className='text-white text-sm md:text-base leading-5'>Price Impact</p>
                        <p
                          className={`text-white text-sm md:text-base leading-5 ${
                            priceImpactInNumber < 1
                              ? 'text-success'
                              : priceImpactInNumber < 2
                              ? 'text-white'
                              : priceImpactInNumber < 5
                              ? 'text-warn'
                              : 'text-error'
                          }`}
                        >
                          {formatAmount(priceImpactInNumber)}%
                        </p>
                      </div>
                    </div>
                  )}
                  <div className='mt-[0.3rem]'>
                    <div className='flex items-center justify-between'>
                      <p className='text-white text-sm md:text-base leading-5'>
                        {bestTrade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum Sold'}
                      </p>
                      <p className='text-white text-sm md:text-base leading-5'>
                        {bestTrade.tradeType === TradeType.EXACT_INPUT
                          ? `${bestTrade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${bestTrade.outputAmount.currency.symbol}`
                          : `${bestTrade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${bestTrade.inputAmount.currency.symbol}`}
                      </p>
                    </div>
                  </div>
                  <div className='mt-[0.3rem]'>
                    <div className='flex items-center justify-between'>
                      <p className='text-white text-sm md:text-base leading-5'>Route</p>
                      <p className='text-white text-sm md:text-base leading-5 flex items-center'>
                        {bestTrade.route.tokenPath.map((token, i, path) => {
                          const isLastItem = path.length - 1 === i
                          const currency = unwrappedToken(token)
                          return (
                            <Fragment key={i}>
                              <span>{currency.symbol}</span>
                              {!isLastItem && <span className='mx-1'>{`>`}</span>}
                            </Fragment>
                          )
                        })}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {setting && <Settings slippage={slippage} setSlippage={setSlippage} deadline={deadline} setDeadline={setDeadline} />}
            {isWarning && <WarningModal isOpen={isWarning} setIsOpen={setIsWarning} onClickHandler={swapCallback} priceImpact={priceImpactInNumber} />}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default SwapFusion
