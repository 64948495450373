import React from 'react'
import Hero from '../../components/pages/mint/newHero'
import Mint from '../../components/pages/mint/nft'
import DetailMint from '../../components/pages/mint/detailMint'
import './style.scss'

const Index = () => {
  const renderContent = () => {
    return (
      <p>
        theNFT stakers share the 1% royalty from secondary sales{' '}
        <a rel='noreferrer' href='https://opensea.io/' className='text-lg text-[#26FFFE]' target={'_blank'}>
          OpenSea
        </a>{' '}
        and other marketplaces.
      </p>
    )
  }
  return (
    <>
      <Hero />
      <div className='w-full mt-[88px] pb-[100px] md:pb-0 md:mt-[159px]'>
        <div className='relative'>
          <Mint
            reverse={true}
            src={'/images/mint/coins.png'}
            title='Earn Trading Fees'
            para={`theNFT stakers share a portion of Lynex's trading fees, claimable on a weekly basis.`}
          />

          <div className={'orangeGlow'} />
          <div className={'whiteGlow'} />
        </div>
        <Mint reverse={false} src={'/images/mint/statue.png'} title='Earn Royalties' para={renderContent()} />
        <DetailMint />
      </div>
    </>
  )
}

export default Index
