import { useMemo } from 'react'
import { Bound } from '../../state/mintV3/actions'
import { nearestUsableTick, TickMath } from '../../v3lib/utils'

export default function useIsTickAtLimit(tickLower, tickUpper, tickSpacing) {
  return useMemo(
    () => ({
      [Bound.LOWER]: tickLower && tickSpacing ? tickLower === nearestUsableTick(TickMath.MIN_TICK, parseInt(tickSpacing)) : undefined,
      [Bound.UPPER]: tickUpper && tickSpacing ? tickUpper === nearestUsableTick(TickMath.MAX_TICK, parseInt(tickSpacing)) : undefined,
    }),
    [tickLower, tickUpper, tickSpacing],
  )
}
