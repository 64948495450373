import React, { useState, useContext } from 'react'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import veLynx from '../../components/pages/otoken/veLynx'
import lynx from '../../components/pages/otoken/lynx'
// import transmute from '../../components/pages/otoken/transmute'
import lp from '../../components/pages/otoken/lp'
import Lp2 from '../../components/pages/otoken/lp2'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { usePollOptionsData } from '../../hooks/usePollOptionsData'
import bveLynx from '../../components/pages/otoken/bveLynx'

const options = [
  { type: 'LYNX', Component: lynx },
  { type: 'veLYNX', Component: veLynx },
  { type: 'bveLYNX', Component: bveLynx },
  // { type: 'Transmute', Component: transmute },
  { type: 'LYNX/USDC LP', Component: lp },
]

const textClass = 'text-[#b8b6cb] text-[12px] md:text-[14px] leading-4'

const Index = () => {
  const { account } = useWeb3Wagmi()
  const [optionType, setOptionType] = useState('veLYNX')
  const baseAssets = useContext(BaseAssetsConetext)
  const optionsData = usePollOptionsData()

  return (
    <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[32px] px-5 xl:px-0 '>
      <div className='max-w-[1200px] mx-auto w-full'>
        <div className='w-full'>
          <h1 className='text-[34px] md:text-[42px] font-semibold text-white'>Options</h1>

          <p className={`${textClass} mb-2`}>oLYNX is the Emissions Options Token for the Lynex Ecosystem:</p>
          <p className={`${textClass} md:leading-5`}>- Redeem for LYNX: Exchange oLYNX for LYNX at a discounted rate.</p>
          <p className={`${textClass} md:leading-5`}>- Redeem for veLYNX: Exchange oLYNX for veLYNX at a discounted rate.</p>
          <p className={`${textClass} md:leading-5`}>
            - Redeem for LYNX-ETH LP: Exchange oLYNX for LYNX-ETH LP tokens at a user-defined lock-up period, with the discount rate increasing for longer
            lock-ups.
          </p>
          <p className={`${textClass} my-4`}>Note: Discount rates are dynamic and market-responsive. They are subject to change.</p>
        </div>
        <div className='flex flex-col space-y-4 lg:space-y-0 lg:flex-row items-start w-full'>
          <div className='bg-white bg-opacity-10 p-6 relative z-[10] rounded-[10px] mx-auto'>
            <div className='flex w-full justify-around h-11 rounded-[5px] bg-white/10 cursor-pointer min-w-[200px] lg:min-w-[600px]'>
              {options.map((option) => {
                return (
                  <div
                    key={option.type}
                    onClick={() => setOptionType(option.type)}
                    className={`w-full h-full flex justify-center items-center text-xs md:text-base
              ${
                option.type !== optionType
                  ? 'text-[#A2A0B7] font-normal -mr-[3px] -ml-px'
                  : 'text-white font-semibold border-[#DF832F] border rounded-[5px] -ml-px bg-[#DF832F]'
              } `}
                  >
                    {option.type}
                  </div>
                )
              })}
            </div>

            {options.map((option) => {
              if (option.type === optionType && optionsData.fetchedOptions) {
                // TODO: loading state
                return <option.Component key={option.type} baseAssets={baseAssets} optionsData={optionsData} />
              }
              return null
            })}
          </div>
          {optionType === 'LYNX/USDC LP' && account && <Lp2 key='lp2' baseAssets={baseAssets} optionsData={optionsData} />}
        </div>
      </div>
    </div>
  )
}

export default Index
