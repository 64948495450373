import { ZERO_VALUE } from '../../utils/formatNumber'

export const managers = [
  {
    title: 'lynex',
    logo: 'lynex.svg',
    address: '0xeA4fE8a84491F909484f3516D77402Eb193A36F9',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
  },
  {
    title: 'ichi',
    logo: 'ichi.svg',
    address: '0xE17F3126F7c32126638DC62999554eAd59B352e6',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
  },
  {
    title: 'gamma',
    logo: 'gamma.svg',
    address: '0x4D021999c71a0f28364681A3CB978618C40b6358',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
  },
]
