import React, { useState, useEffect } from 'react'
import useRefresh from '../hooks/useRefresh'
import { useLocation } from 'react-router-dom'
import { fetchFusionPoolsDaily } from '../utils/fetchFusionPools'

const PoolsContext = React.createContext([])

const PoolsContextProvider = ({ children }) => {
  const [pools, setPools] = useState([])
  const { fastRefresh } = useRefresh()
  const route = useLocation()
  const { pathname } = route

  useEffect(() => {
    const getPoolData = async () => {
      try {
        const data = await fetchFusionPoolsDaily()
        setPools(data)
      } catch (e) {
        console.error('fusion pools fetched had error', e)
      }
    }
    if (['/swap', '/liquidity/fusion', '/liquidity', '/liquidity-info'].includes(pathname)) {
      getPoolData()
    }
  }, [fastRefresh, pathname])

  return <PoolsContext.Provider value={pools}>{children}</PoolsContext.Provider>
}

export { PoolsContext, PoolsContextProvider }
