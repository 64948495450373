export const menuLinks = {
  59144: [
    {
      name: 'SWAP',
      link: '/swap',
    },
    {
      name: 'LIQUIDITY',
      link: '/liquidity-info',
    },
    {
      name: 'PRE MINING',
      link: '/mining',
      class: 'special',
    },
    {
      name: 'IDO',
      link: '/ido',
    },
    // {
    //   name: 'LOCK',
    //   link: '/lock',
    // },
    // {
    //   name: 'VOTE',
    //   link: '/vote',
    // },
    // {
    //   name: 'REWARDS',
    //   link: '/rewards',
    // },
    // {
    //   name: 'GAUGES',
    //   link: '/whitelist',
    // },
    // {
    //   name: 'theNFT',
    //   link: '/theNFT',
    // },
    // {
    //   name: 'REFERRAL',
    //   link: '/referral',
    // },
    {
      name: 'BRIDGE',
      dropdown: true,
      link: [
        {
          name: 'Linea Bridge',
          link: 'https://bridge.linea.build/',
          external: true,
        },
        {
          name: 'Owlto',
          link: 'https://owlto.finance/bridge',
          external: true,
        },
        {
          name: 'Symbiosis',
          link: 'https://app.symbiosis.finance/swap?chainOut=Linea&tokenOut=ETH',
          external: true,
        },
        {
          name: 'Orbiter',
          link: 'https://www.orbiter.finance/?source=Ethereum&dest=Linea&token=ETH',
          external: true,
        },
        {
          name: 'rhino.fi',
          link: 'https://app.rhino.fi/bridge?token=ETH&chainOut=LINEA&chain=BASE',
          external: true,
        },
      ],
    },
    {
      name: 'MORE',
      dropdown: true,
      link: [
        {
          name: 'Analytics',
          link: '/analytics',
        },
        // {
        //   name: 'Analytics',
        //   link: 'https://info.thena.fi/',
        //   external: true,
        // },
        // {
        //   name: 'V1',
        //   link: 'https://v1.thena.fi/',
        //   external: true,
        // },
        {
          name: 'Docs',
          link: 'https://lynex.gitbook.io/',
          external: true,
        },
      ],
    },
  ],
  59140: [
    {
      name: 'SWAP',
      link: '/swap',
    },
    {
      name: 'LIQUIDITY',
      link: '/liquidity',
    },
    // {
    //   name: 'PRE MINING',
    //   link: '/mining',
    //   class: 'special',
    // },
    {
      name: 'LOCK',
      link: '/lock',
    },
    {
      name: 'VOTE',
      // link: '/vote',
      dropdown: true,
      link: [
        {
          name: 'DELEGATE',
          link: '/delegate',
        },
        {
          name: 'LIQUIDITY',
          link: '/vote',
        },
      ],
    },
    {
      name: 'REWARDS',
      link: '/rewards',
    },
    {
      name: 'GAUGES',
      link: '/whitelist',
    },
    {
      name: 'IDO',
      link: '/ido',
    },
    {
      name: 'OPTIONS',
      link: '/otoken',
    },
    // {
    //   name: 'theNFT',
    //   link: '/theNFT',
    // },
    // {
    //   name: 'REFERRAL',
    //   link: '/referral',
    // },
    {
      name: 'BRIDGE',
      dropdown: true,
      link: [
        {
          name: 'Linea Bridge',
          link: 'https://bridge.linea.build/',
          external: true,
        },
        {
          name: 'Owlto',
          link: 'https://owlto.finance/bridge',
          external: true,
        },
        {
          name: 'Symbiosis',
          link: 'https://app.symbiosis.finance/swap?chainOut=Linea&tokenOut=ETH',
          external: true,
        },
        {
          name: 'Orbiter',
          link: 'https://www.orbiter.finance/?source=Ethereum&dest=Linea&token=ETH',
          external: true,
        },
        {
          name: 'rhino.fi',
          link: 'https://app.rhino.fi/bridge?token=ETH&chainOut=LINEA&chain=BASE',
          external: true,
        },
      ],
    },
    {
      name: 'MORE',
      dropdown: true,
      link: [
        {
          name: 'Airdrop',
          link: '/claim',
        },
        {
          name: 'Analytics',
          link: '/analytics',
        },
        // {
        //   name: 'Analytics',
        //   link: 'https://info.thena.fi/',
        //   external: true,
        // },
        // {
        //   name: 'V1',
        //   link: 'https://v1.thena.fi/',
        //   external: true,
        // },
        {
          name: 'Docs',
          link: 'https://lynex.gitbook.io/',
          external: true,
        },
      ],
    },
  ],
}
