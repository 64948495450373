import React, { useCallback, useMemo } from 'react'
import JSBI from 'jsbi'
import { Field } from '../../../../../../state/mintV3/actions'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useProceedSwap } from '../../../../../../hooks/useSwap'
import { WETH_EXTENDED, v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { customNotify } from '../../../../../../utils/notify'
import { useGammaAdd } from '../../../../../../hooks/v3/useGamma'
import { useAlgebraAdd } from '../../../../../../hooks/v3/useAlgebra'
import { useActivePreset } from '../../../../../../state/mintV3/hooks'
import { ZERO_ADDRESS } from '../../../../../../utils/formatNumber'
import { defaultChainId } from '../../../../../../config/constants'

const chainId = defaultChainId

export const AddLiquidityButton = ({ baseCurrency, quoteCurrency, mintInfo, slippage, deadline, gammaPairs }) => {
  const { liquidityRangeType, errorMessage } = mintInfo
  const { onWrap, swapPending: wrappingETH } = useProceedSwap()
  const { onGammaAdd, onGammaAddAndStake, pending: gammaPending } = useGammaAdd()
  const { onAlgebraAdd, pending: algebraPending } = useAlgebraAdd()
  const amountA = mintInfo.parsedAmounts[Field.CURRENCY_A]
  const amountB = mintInfo.parsedAmounts[Field.CURRENCY_B]
  const wethBalance = useCurrencyBalance(WETH_EXTENDED[chainId])
  const preset = useActivePreset()

  const gammaPair = useMemo(() => {
    return gammaPairs && gammaPairs.find((pair) => pair.title === preset)
  }, [gammaPairs, preset])

  const amountToWrap = useMemo(() => {
    if (!baseCurrency || !quoteCurrency || !amountA || !amountB || liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE) return
    if (baseCurrency.isNative || baseCurrency.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountA.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wethBalance.numerator)
      }
      return
    } else if (quoteCurrency.isNative || quoteCurrency.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountB.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wethBalance.numerator)
      }
      return
    }
    return
  }, [amountA, amountB, baseCurrency, quoteCurrency, liquidityRangeType, wethBalance])

  const onAddLiquidity = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    if (liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE) {
      onGammaAdd(amountA, amountB, amountToWrap, gammaPair)
    } else {
      onAlgebraAdd(amountA, amountB, baseCurrency, quoteCurrency, mintInfo, slippage, deadline)
    }
  }, [errorMessage, amountToWrap, onWrap, onGammaAdd, baseCurrency, quoteCurrency, liquidityRangeType, amountA, amountB, mintInfo, slippage, deadline])

  const onAddLiquidityAndStake = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    onGammaAddAndStake(amountA, amountB, amountToWrap, gammaPair)
  }, [errorMessage, amountToWrap, onGammaAddAndStake, amountA, amountB])

  return (
    <>
      {gammaPair && gammaPair.gauge && gammaPair.gauge.address !== ZERO_ADDRESS && (
        <StyledButton
          disabled={wrappingETH || gammaPending || algebraPending}
          onClickHandler={onAddLiquidityAndStake}
          content={'ADD LIQUIDITY AND STAKE'}
          className='py-[13px] md:py-[14.53px] mt-3 text-white  text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      )}
      <StyledButton
        disabled={wrappingETH || gammaPending || algebraPending}
        onClickHandler={onAddLiquidity}
        content={'ADD LIQUIDITY'}
        className='py-[13px] md:py-[14.53px] mt-3 text-white  text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
      />
    </>
  )
}
