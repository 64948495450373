import React from 'react'
import { registerToken } from '../../../hooks/wallet'
import TransparentButton from './transparentButton'

const AddToMetamaskButton = ({ tokenAddress, tokenSymbol, tokenDecimals, imgUrl, text }) => {
  const renderContent = () => {
    return (
      <div className='relative flex items-center justify-center space-x-2 z-10'>
        <img className='w-[20px] h-[20px]' src='/images/common/metamask-logo.svg' alt='' />
        <span>{text}</span>
      </div>
    )
  }
  return (
    <TransparentButton
      onClickHandler={() => registerToken(tokenAddress, tokenSymbol, tokenDecimals, imgUrl)}
      content={renderContent()}
      className='
      py-[6px] px-[8px] text-white text-xs'
    />
  )
}

export default AddToMetamaskButton
