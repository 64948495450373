import React from 'react'
import 'react-rangeslider/lib/index.css'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'
import { useAlgebraClaim } from '../../../../../../hooks/v3/useAlgebra'

const CollectModal = ({ isOpen, setIsOpen, position, feeValue0, feeValue1 }) => {
  const logoURI0 = useCurrencyLogo(feeValue0?.currency)
  const logoURI1 = useCurrencyLogo(feeValue1?.currency)
  const { pending, onAlgebraClaim } = useAlgebraClaim()

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Collect Fees'} width={588}>
      <div className='px-4 pt-5 pb-3 rounded-[5px] border border-themeOrange mt-[13px]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI0} className={'w-[22px] md:w-6'} />
            <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>{feeValue0?.currency.symbol}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue0?.toSignificant()}</span>
          </div>
        </div>
        <div className='flex items-center justify-between mt-2'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI1} className={'w-[22px] md:w-6'} />
            <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>{feeValue1?.currency.symbol}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue1?.toSignificant()}</span>
          </div>
        </div>
        <StyledButton
          pending={pending}
          onClickHandler={() => onAlgebraClaim(position.tokenId, feeValue0, feeValue1)}
          content={'Claim'}
          className='mt-[15px]  py-[13px] md:py-[14.53px] text-white  text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
        <p className='mt-2 md:mt-1.5 text-[#DEDBF2] leading-5 text-sm md:text-base font-light text-center'>
          Collecting fees will withdraw currently available fees for you.
        </p>
      </div>
    </Modal>
  )
}

export default CollectModal
