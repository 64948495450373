import Web3 from 'web3'
import getRpcUrl from './getRpcUrl'

const RPC_URL = getRpcUrl()
export const defaultHttpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })
const web3NoAccount = new Web3(defaultHttpProvider)

const getWeb3NoAccount = () => {
  return web3NoAccount
}

export { getWeb3NoAccount }
export default web3NoAccount
