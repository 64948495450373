import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'

const PercentChange = ({ value }) => (
  <div
    className={`px-2 py-1 leading-5 text-[15px] table rounded-md ${
      value > 0 ? 'bg-[#51B961] bg-opacity-10 text-[#51B961]' : value < 0 ? 'bg-[#CF3A41] bg-opacity-10 text-[#CF3A41]' : 'text-white bg-white bg-opacity-10'
    }`}
  >
    {value > 0 ? '+' : value < 0 ? '-' : ''}
    {formatAmount(Math.abs(value))}%
  </div>
)

export default PercentChange
