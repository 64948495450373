import React from 'react'
import './style.scss'

const Box = () => {
  return (
    <div className='box'>
      <div className='left-side'>
        <div className='frame-1'>
          <div>
            <img className='image' alt='Image' src='/images/home/home-swap.png' />
          </div>
          <div>
            <div className='header'>Swap Your Tokens</div>
            <div className='subheader'>With Low Slippage</div>
            <div className='paragraph'>
              <p className='p'>
                Lynex&#39;s smart routing, deep liquidity, and latest AMM technology allow you to enjoy low slippage and high return when swapping one
                cryptocurrency for another.
              </p>
            </div>
            <div className='link-wrapper'>
              <div className='text-wrapper'>Swap Now</div>
              <img className='spear-svg' alt='Spear' src='/images/common/spear.svg' />
            </div>
          </div>
        </div>
      </div>
      <div className='right-side'>
        <div className='frame-2'>
          <img className='image-2' alt='Image' src='/images/home/home-stake.png' />
          <div className='text'>
            <div className='header'>Stake and Earn</div>
            <div className='subheader'>Passive Income</div>
            <div className='paragraph'>
              <p className='p'>Stake your assets for instant passive income streams. No deposit or withdrawal fees. You have full control over your funds.</p>
            </div>
            <div className='link-wrapper'>
              <div className='text-wrapper'>Start Staking</div>
              <img className='spear-svg' alt='Spear' src='/images/common/spear.svg' />
            </div>
          </div>
        </div>
        <div className='frame-3'>
          <div className='rotative-img'>
            <img src='/images/home/Liquidity3.png' />
            <img className='rotate1' src='/images/home/Liquidity3-2.png' />
            <img className='rotate2' src='/images/home/Liquidity3-3.png' />
          </div>
          <div className='text'>
            <div className='header'>Liquidity Layer</div>
            <div className='subheader'>for Linea blockchain</div>
            <div className='paragraph'>
              <p className='p'>
                Lynex was designed to onboard the next generation of protocols to the Linea blockchain by opening up a free market for LYNX emissions. Protocols
                can bribe veLYNX holders or acquire veLYNX to redirect emissions to their pools, offering a flexible and capital efficient solution to bootstrap
                and scale liquidity.
              </p>
            </div>
            <div className='link-wrapper'>
              <div className='text-wrapper'>Go Vote</div>
              <img className='spear-svg' alt='Spear' src='/images/common/spear.svg' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Box
