import { getAddress } from '@ethersproject/address'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import client, { blockClient, fusionClient } from '../apollo/client'
import {
  GET_BLOCK,
  GLOBAL_CHART,
  GET_BLOCKS,
  TOKENS_CURRENT,
  TOKENS_DYNAMIC,
  TOKEN_CHART,
  TOKEN_DATA2,
  PAIR_CHART,
  PAIR_DATA,
  PAIRS_BULK1,
  PAIRS_HISTORICAL_BULK,
  PRICES_BY_BLOCK,
  ALL_PAIRS,
  ALL_TOKENS,
  TOKEN_INFO,
  TOKEN_INFO_OLD,
  SWAP_TRANSACTIONS,
  PAIR_ID,
  GLOBAL_DATA,
} from '../apollo/queries'
import { Percent, JSBI, ETHER, Token } from 'thena-sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from 'ethers/lib/utils'
import { FEEPERCENT, formatTokenSymbol } from './fusionGraph'
import { PAIR_ID_V3, SWAP_TRANSACTIONS_v3, TOKENS_FROM_ADDRESSES_V3, TOP_POOLS_FUSION_TOKEN_0, TOP_POOLS_FUSION_TOKEN_1 } from '../apollo/fusionQueries'
import { AnalyticChart, STABLE_FEE, VOLATILE_FEE, timeframeOptions } from '../config/constants'
import { getScanUrl } from '.'

dayjs.extend(utc)
dayjs.extend(weekOfYear)

const TOKEN_OVERRIDES = {}

export function getTimeframe(timeWindow) {
  const utcEndTime = dayjs.utc()
  // based on window, get starttime
  let utcStartTime
  switch (timeWindow) {
    case timeframeOptions.WEEK:
      utcStartTime = utcEndTime.subtract(1, 'week').endOf('day').unix() - 1
      break
    case timeframeOptions.MONTH:
      utcStartTime = utcEndTime.subtract(1, 'month').endOf('day').unix() - 1
      break
    case timeframeOptions.ALL_TIME:
      utcStartTime = utcEndTime.subtract(1, 'year').endOf('day').unix() - 1
      break
    default:
      utcStartTime = utcEndTime.subtract(1, 'year').startOf('year').unix() - 1
      break
  }
  return utcStartTime
}

export async function getBlockFromTimestamp(timestamp) {
  const result = await blockClient.query({
    query: GET_BLOCK,
    variables: {
      timestampFrom: timestamp,
      timestampTo: timestamp + 600,
    },
    fetchPolicy: 'network-only',
  })
  return result?.data?.blocks?.[0]?.number
}

export function formatCompact(unformatted, decimals = 18, maximumFractionDigits = 3, maxPrecision = 4) {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits,
  })

  if (!unformatted) return '0'

  if (unformatted === Infinity) return '∞'

  let formatted = Number(unformatted)

  if (unformatted instanceof BigNumber) {
    formatted = Number(formatUnits(unformatted.toString(), decimals))
  }

  return formatter.format(Number(formatted.toPrecision(maxPrecision)))
}

export const getPercentChange = (valueNow, value24HoursAgo) => {
  const adjustedPercentChange = ((valueNow - value24HoursAgo) / value24HoursAgo) * 100
  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return 0
  }
  return adjustedPercentChange
}

export async function splitQuery(query, localClient, vars, list, skipCount = 100) {
  let fetchedData = {}
  let allFound = false
  let skip = 0

  while (!allFound) {
    let end = list.length
    if (skip + skipCount < list.length) {
      end = skip + skipCount
    }
    const sliced = list.slice(skip, end)
    const queryStr = query(...vars, sliced)
    const result = await localClient.query({
      query: queryStr,
      fetchPolicy: 'network-only',
    })
    fetchedData = {
      ...fetchedData,
      ...result.data,
    }
    if (Object.keys(result.data).length < skipCount || skip + skipCount > list.length) {
      allFound = true
    } else {
      skip += skipCount
    }
  }

  return fetchedData
}

export async function getBlocksFromTimestamps(timestamps, skipCount = 500) {
  if (timestamps?.length === 0) {
    return []
  }

  const fetchedData = await splitQuery(GET_BLOCKS, blockClient, [], timestamps, skipCount)

  const blocks = []
  if (fetchedData) {
    for (const t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split('t')[1],
          number: Number(fetchedData[t][0]['number']),
        })
      }
    }
  }

  return blocks
}

export const get2DayPercentChange = (valueNow, value24HoursAgo, value48HoursAgo) => {
  // get volume info for both 24 hour periods
  const currentChange = valueNow - value24HoursAgo
  const previousChange = value24HoursAgo - value48HoursAgo

  const adjustedPercentChange = ((currentChange - previousChange) / previousChange) * 100

  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return [currentChange, 0]
  }
  return [currentChange, adjustedPercentChange]
}

export const getTokenInfo = async (ethPrice, ethPriceOld, address) => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
  const utcOneWeekBack = utcCurrentTime.subtract(7, 'day').unix()
  const utcTwoWeekBack = utcCurrentTime.subtract(14, 'day').unix()
  const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
  const twoDayBlock = await getBlockFromTimestamp(utcTwoDaysBack)
  const oneWeekBlock = await getBlockFromTimestamp(utcOneWeekBack)
  const twoWeekBlock = await getBlockFromTimestamp(utcTwoWeekBack)

  try {
    const current = await client.query({
      query: TOKEN_INFO(address),
      fetchPolicy: 'network-only',
    })

    let oneDayResult, twoDayResult, oneWeekResult, twoWeekResult
    try {
      oneDayResult = await client.query({
        query: TOKEN_INFO_OLD(oneDayBlock, address),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    try {
      twoDayResult = await client.query({
        query: TOKEN_INFO_OLD(twoDayBlock, address),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    try {
      oneWeekResult = await client.query({
        query: TOKEN_INFO_OLD(oneWeekBlock, address),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    try {
      twoWeekResult = await client.query({
        query: TOKEN_INFO_OLD(twoWeekBlock, address),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    const currentData = current && current.data && current.data.tokens && current.data.tokens.length > 0 ? current.data.tokens : undefined

    const oneDayData =
      oneDayResult && oneDayResult.data && oneDayResult.data.tokens && oneDayResult.data.tokens.length > 0
        ? oneDayResult.data.tokens.reduce((obj, cur) => {
            return { ...obj, [cur.id]: cur }
          }, {})
        : undefined

    const twoDayData =
      twoDayResult && twoDayResult.data && twoDayResult.data.tokens && twoDayResult.data.tokens.length > 0
        ? twoDayResult.data.tokens.reduce((obj, cur) => {
            return { ...obj, [cur.id]: cur }
          }, {})
        : undefined

    const oneWeekData =
      oneWeekResult && oneWeekResult.data && oneWeekResult.data.tokens && oneWeekResult.data.tokens.length > 0
        ? oneWeekResult.data.tokens.reduce((obj, cur) => {
            return { ...obj, [cur.id]: cur }
          }, {})
        : undefined

    const twoWeekData =
      twoWeekResult && twoWeekResult.data && twoWeekResult.data.tokens && twoWeekResult.data.tokens.length > 0
        ? twoWeekResult.data.tokens.reduce((obj, cur) => {
            return { ...obj, [cur.id]: cur }
          }, {})
        : undefined

    const bulkResults = await Promise.all(
      currentData &&
        currentData.map(async (token) => {
          const data = token

          let oneDayHistory = oneDayData ? oneDayData[token.id] : undefined
          let twoDayHistory = twoDayData ? twoDayData[token.id] : undefined
          let oneWeekHistory = oneWeekData ? oneWeekData[token.id] : undefined
          let twoWeekHistory = twoWeekData ? twoWeekData[token.id] : undefined

          // this is because old history data returns exact same data as current data when the old data does not exist
          if (
            Number(oneDayHistory?.totalLiquidity ?? 0) === Number(data?.totalLiquidity ?? 0) &&
            Number(oneDayHistory?.tradeVolume ?? 0) === Number(data?.tradeVolume ?? 0) &&
            Number(oneDayHistory?.derivedETH ?? 0) === Number(data?.derivedETH ?? 0)
          ) {
            oneDayHistory = null
          }

          if (
            Number(twoDayHistory?.totalLiquidity ?? 0) === Number(data?.totalLiquidity ?? 0) &&
            Number(twoDayHistory?.tradeVolume ?? 0) === Number(data?.tradeVolume ?? 0) &&
            Number(twoDayHistory?.derivedETH ?? 0) === Number(data?.derivedETH ?? 0)
          ) {
            twoDayHistory = null
          }

          if (
            Number(oneWeekHistory?.totalLiquidity ?? 0) === Number(data?.totalLiquidity ?? 0) &&
            Number(oneWeekHistory?.tradeVolume ?? 0) === Number(data?.tradeVolume ?? 0) &&
            Number(oneWeekHistory?.derivedETH ?? 0) === Number(data?.derivedETH ?? 0)
          ) {
            oneWeekHistory = null
          }

          if (
            Number(twoWeekHistory?.totalLiquidity ?? 0) === Number(data?.totalLiquidity ?? 0) &&
            Number(twoWeekHistory?.tradeVolume ?? 0) === Number(data?.tradeVolume ?? 0) &&
            Number(twoWeekHistory?.derivedETH ?? 0) === Number(data?.derivedETH ?? 0)
          ) {
            twoWeekHistory = null
          }

          // calculate percentage changes and daily changes
          const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
            data.tradeVolumeUSD,
            oneDayHistory?.tradeVolumeUSD ?? 0,
            twoDayHistory?.tradeVolumeUSD ?? 0,
          )

          const [oneWeekVolumeUSD] = get2DayPercentChange(data.tradeVolumeUSD, oneWeekHistory?.tradeVolumeUSD ?? 0, twoWeekHistory?.tradeVolumeUSD ?? 0)

          const currentLiquidityUSD = data?.totalLiquidity * ethPrice * data?.derivedETH
          const oldLiquidityUSD = (oneDayHistory?.totalLiquidity ?? 0) * ethPriceOld * (oneDayHistory?.derivedETH ?? 0)

          // percent changes
          const priceChangeUSD = getPercentChange(data?.derivedETH * ethPrice, oneDayHistory?.derivedETH ? oneDayHistory?.derivedETH * ethPriceOld : 0)

          // set data
          data.priceUSD = data?.derivedETH * ethPrice
          data.totalLiquidityUSD = currentLiquidityUSD
          data.oneDayVolumeUSD = oneDayVolumeUSD
          data.oneWeekVolumeUSD = oneWeekVolumeUSD
          data.volumeChangeUSD = volumeChangeUSD
          data.priceChangeUSD = priceChangeUSD
          data.liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0)
          data.symbol = formatTokenSymbol(data.id, data.symbol)

          // new tokens
          if (!oneDayHistory && data) {
            data.oneDayVolumeUSD = data.tradeVolumeUSD
            data.oneDayVolumeETH = data.tradeVolume * data.derivedETH
          }

          // update name data for
          updateNameData({
            token0: data,
          })

          // HOTFIX for Aave
          if (data.id === '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9') {
            const aaveData = await client.query({
              query: PAIR_DATA('0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f'),
              fetchPolicy: 'network-only',
            })
            const result = aaveData.data.pairs[0]
            data.totalLiquidityUSD = Number(result.reserveUSD) / 2
            data.liquidityChangeUSD = 0
            data.priceChangeUSD = 0
          }
          return data
        }),
    )
    return bulkResults
  } catch (e) {
    console.log(e)
  }
}

export const getTopTokens = async (ethPrice, ethPriceOld, count = 500) => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)

  let current
  try {
    current = await client.query({
      query: TOKENS_CURRENT(count),
      fetchPolicy: 'network-only',
    })
  } catch (e) {
    console.log('error :>> ', e)
  }

  let oneDayData = {}
  try {
    const oneDayResult = await client.query({
      query: TOKENS_DYNAMIC(oneDayBlock, count),
      fetchPolicy: 'network-only',
    })
    oneDayData = oneDayResult?.data?.tokens.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})
  } catch (e) {
    console.log('error :>> ', e)
  }

  const bulkResults = await Promise.all(
    current &&
      oneDayData &&
      current?.data?.tokens?.map(async (token) => {
        const data = token

        // let liquidityDataThisToken = liquidityData?.[token.id]
        let oneDayHistory = oneDayData?.[token.id]

        // this is because old history data returns exact same data as current data when the old data does not exist
        if (
          Number(oneDayHistory?.totalLiquidity ?? 0) === Number(data?.totalLiquidity ?? 0) &&
          Number(oneDayHistory?.tradeVolume ?? 0) === Number(data?.tradeVolume ?? 0) &&
          Number(oneDayHistory?.derivedETH ?? 0) === Number(data?.derivedETH ?? 0)
        ) {
          oneDayHistory = null
        }

        const oneDayVolumeUSD = (data?.tradeVolumeUSD ?? 0) - (oneDayHistory?.tradeVolumeUSD ?? 0)

        const currentLiquidityUSD = data?.totalLiquidity * ethPrice * data?.derivedETH
        const oldLiquidityUSD = (oneDayHistory?.totalLiquidity ?? 0) * ethPriceOld * (oneDayHistory?.derivedETH ?? 0)

        // percent changes
        const priceChangeUSD = getPercentChange(data?.derivedETH * ethPrice, oneDayHistory?.derivedETH ? oneDayHistory?.derivedETH * ethPriceOld : 0)

        // set data
        data.priceUSD = data?.derivedETH * ethPrice
        data.totalLiquidityUSD = currentLiquidityUSD
        data.oneDayVolumeUSD = oneDayVolumeUSD
        data.priceChangeUSD = priceChangeUSD
        data.liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0)
        data.symbol = formatTokenSymbol(data.id, data.symbol)

        // new tokens
        if (!oneDayHistory && data) {
          data.oneDayVolumeUSD = data.tradeVolumeUSD
          data.oneDayVolumeETH = data.tradeVolume * data.derivedETH
        }

        // update name data for
        updateNameData({
          token0: data,
        })

        // HOTFIX for Aave
        if (data.id === '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9') {
          const aaveData = await client.query({
            query: PAIR_DATA('0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f'),
            fetchPolicy: 'network-only',
          })
          const result = aaveData.data.pairs[0]
          data.totalLiquidityUSD = Number(result.reserveUSD) / 2
          data.liquidityChangeUSD = 0
          data.priceChangeUSD = 0
        }
        return data
      }),
  )
  return bulkResults
}

export const getTimestampsForChanges = () => {
  const utcCurrentTime = dayjs()
  //utcCurrentTime = utcCurrentTime.subtract(0.3,  'day');
  const t1 = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
  const t2 = utcCurrentTime.subtract(2, 'day').startOf('minute').unix()
  const tWeek = utcCurrentTime.subtract(1, 'week').startOf('minute').unix()
  return [t1, t2, tWeek]
}

export const getTokenPairsV1 = async (tokenAddress) => {
  try {
    // fetch all current and historical data
    const result = await client.query({
      query: TOKEN_DATA2(tokenAddress),
      fetchPolicy: 'network-only',
    })
    return result.data?.['pairs'].map((pair) => pair.id)
  } catch (e) {
    console.log(e)
    return
  }
}

export const getTokenPairsFusion = async (tokenAddress) => {
  try {
    // fetch all current and historical data
    const result_1 = await fusionClient.query({
      query: TOP_POOLS_FUSION_TOKEN_1,
      variables: {
        address: tokenAddress,
      },
      fetchPolicy: 'network-only',
    })
    const result_0 = await fusionClient.query({
      query: TOP_POOLS_FUSION_TOKEN_0,
      variables: {
        address: tokenAddress,
      },
      fetchPolicy: 'network-only',
    })
    const pools_1 = result_1.data?.['pools'].map((pair) => pair.id)
    const pools_0 = result_0.data?.['pools'].map((pair) => pair.id)
    return [...pools_0, ...pools_1]
  } catch (e) {
    console.log(e)
    return
  }
}

export function getSecondsOneDay() {
  return 60 * 60 * 24
}

export const getIntervalTokenData = async (tokenAddress, startTime, interval = 3600, latestBlock) => {
  const utcEndTime = dayjs.utc()
  let time = startTime

  // create an array of hour start times until we reach current hour
  // buffer by half hour to catch case where graph isnt synced to latest block
  const timestamps = []
  while (time < utcEndTime.unix()) {
    timestamps.push(time)
    time += interval
  }

  // backout if invalid timestamp format
  if (timestamps.length === 0) {
    return []
  }

  // once you have all the timestamps, get the blocks for each timestamp in a bulk query
  let blocks
  try {
    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks.length === 0) {
      return []
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return Number(b.number) <= latestBlock
      })
    }

    const result = await splitQuery(PRICES_BY_BLOCK, client, [tokenAddress], blocks, 50)

    // format token ETH price results
    const values = []
    for (const row in result) {
      const timestamp = row.split('t')[1]
      const derivedETH = Number(result[row]?.derivedETH ?? 0)
      if (timestamp) {
        values.push({
          timestamp,
          derivedETH,
        })
      }
    }

    // go through eth usd prices and assign to original values array
    let index = 0
    for (const brow in result) {
      const timestamp = brow.split('b')[1]
      if (timestamp) {
        values[index].priceUSD = result[brow].ethPrice * values[index].derivedETH
        index += 1
      }
    }

    const formattedHistory = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistory.push({
        timestamp: values[i].timestamp,
        open: Number(values[i].priceUSD),
        close: Number(values[i + 1].priceUSD),
      })
    }

    return formattedHistory
  } catch (e) {
    console.log(e)
    console.log('error fetching blocks')
    return []
  }
}

export const getPairAddress = async (token0Address, token1Address) => {
  const pairData = await client.query({
    query: PAIR_ID(token0Address.toLowerCase(), token1Address.toLowerCase()),
  })
  const pairs = pairData && pairData.data ? pairData.data.pairs0.concat(pairData.data.pairs1) : undefined
  if (!pairs || pairs.length === 0) return
  const pairId = pairs[0].id
  const tokenReversed = pairData.data.pairs1.length > 0
  return { pairId, tokenReversed }
}

export const getPairAddressV3 = async (token0Address, token1Address) => {
  const pairData = await fusionClient.query({
    query: PAIR_ID_V3(token0Address.toLowerCase(), token1Address.toLowerCase()),
  })
  const pairs = pairData && pairData.data ? pairData.data.pairs0.concat(pairData.data.pairs1) : undefined
  if (!pairs || pairs.length === 0) return
  const pairId = pairs[0].id
  const tokenReversed = pairData.data.pairs1.length > 0
  return { pairId, tokenReversed }
}

export const getSwapTransactions = async (pairId, startTime) => {
  const oneDayAgo = dayjs.utc().subtract(1, 'day').unix()
  const sTimestamp = startTime ?? oneDayAgo
  try {
    const result = await client.query({
      query: SWAP_TRANSACTIONS,
      variables: {
        allPairs: [pairId],
        lastTime: sTimestamp,
      },
      fetchPolicy: 'network-only',
    })
    const swaps = result.data.swaps

    return swaps
  } catch (e) {
    return
  }
}

export const getSwapTransactionsV3 = async (pairId, startTime) => {
  const oneDayAgo = dayjs.utc().subtract(1, 'day').unix()
  const sTimestamp = startTime ?? oneDayAgo
  try {
    const result = await fusionClient.query({
      query: SWAP_TRANSACTIONS_v3,
      variables: {
        address: pairId,
        lastTime: sTimestamp,
      },
      fetchPolicy: 'network-only',
    })
    const swaps = result.data.swaps.map((swap) => {
      return {
        transaction: { id: swap.transaction.id, timestamp: swap.timestamp },
        pair: swap.pool,
        to: swap.recipient,
        amount0In: swap.amount0,
        amount0Out: Number(swap.amount0) * -1,
        amount1In: swap.amount1,
        amount1Out: Number(swap.amount1) * -1,
        amountUSD: swap.amountUSD,
      }
    })

    return swaps
  } catch (e) {
    console.log('ccc', e)
    return
  }
}

export const getTokenChartData = async (tokenAddress, startTime) => {
  let data = []
  const utcEndTime = dayjs.utc()
  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const result = await client.query({
        query: TOKEN_CHART,
        variables: {
          startTime: startTime,
          tokenAddr: tokenAddress,
          skip,
        },
        fetchPolicy: 'network-only',
      })
      if (result.data.tokenDayDatas.length < 1000) {
        allFound = true
      }
      skip += 1000
      data = data.concat(result.data.tokenDayDatas)
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = getSecondsOneDay()
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.dailyVolumeUSD)
    })

    // fill in empty days
    let timestamp = data[0] && data[0].date ? data[0].date : startTime
    let latestLiquidityUSD = data[0] && data[0].totalLiquidityUSD
    let latestPriceUSD = data[0] && data[0].priceUSD
    //let latestPairDatas = data[0] && data[0].mostLiquidPairs
    let index = 1
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay
      const currentDayIndex = (nextDay / oneDay).toFixed(0)
      if (!dayIndexSet.has(currentDayIndex)) {
        data.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
          //mostLiquidPairs: latestPairDatas,
        })
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
        latestPriceUSD = dayIndexArray[index].priceUSD
        //latestPairDatas = dayIndexArray[index].mostLiquidPairs
        index = index + 1
      }
      timestamp = nextDay
    }
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
  } catch (e) {
    console.log(e)
  }
  return data
}

export const getPairChartData = async (pairAddress, isStable) => {
  let data = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(1, 'month').startOf('minute')
  const startTime = utcStartTime.unix() - 1
  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const result = await client.query({
        query: PAIR_CHART,
        variables: {
          startTime: startTime,
          pairAddress: pairAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.pairDayDatas)
      if (result.data.pairDayDatas.length < 1000) {
        allFound = true
      }
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = 24 * 60 * 60
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.dailyVolumeUSD)
      dayData.reserveUSD = Number(dayData.reserveUSD)
      dayData.feesUSD = Number(dayData.dailyVolumeUSD) * (isStable ? STABLE_FEE : VOLATILE_FEE)
    })

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].date ? data[0].date : startTime
      let latestLiquidityUSD = data[0].reserveUSD
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dayString: nextDay,
            dailyVolumeUSD: 0,
            feesUSD: 0,
            reserveUSD: latestLiquidityUSD,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].reserveUSD
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
  } catch (e) {
    console.log(e)
  }

  return data
}

export const getBulkPairData = async (pairList, ethPrice) => {
  const [t1, t2, tWeek] = getTimestampsForChanges()
  const a = await getBlocksFromTimestamps([t1, t2, tWeek], 500)
  const [{ number: b1 }, { number: b2 }, { number: bWeek }] = a
  try {
    const current = await client.query({
      query: PAIRS_BULK1,
      variables: {
        allPairs: pairList,
      },
      fetchPolicy: 'network-only',
    })

    let oneDayResult, twoDayResult, oneWeekResult

    try {
      oneDayResult = await client.query({
        query: PAIRS_HISTORICAL_BULK(b1, pairList),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    try {
      twoDayResult = await client.query({
        query: PAIRS_HISTORICAL_BULK(b2, pairList),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    try {
      oneWeekResult = await client.query({
        query: PAIRS_HISTORICAL_BULK(bWeek, pairList),
        fetchPolicy: 'network-only',
      })
    } catch (e) {
      console.log('error :>> ', e)
    }

    const oneDayData = oneDayResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    const twoDayData = twoDayResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    const oneWeekData = oneWeekResult?.data?.pairs.reduce((obj, cur) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    const pairData = await Promise.all(
      current &&
        current.data.pairs.map(async (pair) => {
          let data = pair
          let oneDayHistory = oneDayData?.[pair.id]
          if (!oneDayHistory) {
            try {
              const newData = await client.query({
                query: PAIR_DATA(pair.id, b1),
                fetchPolicy: 'network-only',
              })
              oneDayHistory = newData.data.pairs[0]
            } catch (e) {
              console.log('error :>> ', e)
            }
          }
          let twoDayHistory = twoDayData?.[pair.id]
          if (!twoDayHistory) {
            try {
              const newData = await client.query({
                query: PAIR_DATA(pair.id, b2),
                fetchPolicy: 'network-only',
              })
              twoDayHistory = newData.data.pairs[0]
            } catch (e) {
              console.log('error :>> ', e)
            }
          }
          let oneWeekHistory = oneWeekData?.[pair.id]
          if (!oneWeekHistory) {
            try {
              const newData = await client.query({
                query: PAIR_DATA(pair.id, bWeek),
                fetchPolicy: 'network-only',
              })
              oneWeekHistory = newData.data.pairs[0]
            } catch (e) {
              console.log('error :>> ', e)
            }
          }

          // this is because old history data returns exact same data as current data when the old data does not exist
          if (
            Number(oneDayHistory?.reserveUSD ?? 0) === Number(data?.reserveUSD ?? 0) &&
            Number(oneDayHistory?.volumeUSD ?? 0) === Number(data?.volumeUSD ?? 0) &&
            Number(oneDayHistory?.totalSupply ?? 0) === Number(data?.totalSupply ?? 0)
          ) {
            oneDayHistory = null
          }

          if (
            Number(twoDayHistory?.reserveUSD ?? 0) === Number(data?.reserveUSD ?? 0) &&
            Number(twoDayHistory?.volumeUSD ?? 0) === Number(data?.volumeUSD ?? 0) &&
            Number(twoDayHistory?.totalSupply ?? 0) === Number(data?.totalSupply ?? 0)
          ) {
            twoDayHistory = null
          }
          if (
            Number(oneWeekHistory?.reserveUSD ?? 0) === Number(data?.reserveUSD ?? 0) &&
            Number(oneWeekHistory?.volumeUSD ?? 0) === Number(data?.volumeUSD ?? 0) &&
            Number(oneWeekHistory?.totalSupply ?? 0) === Number(data?.totalSupply ?? 0)
          ) {
            oneWeekHistory = null
          }

          data = parseData(data, oneDayHistory, twoDayHistory, oneWeekHistory, ethPrice, b1)
          return data
        }),
    )
    return pairData
  } catch (e) {
    console.log(e)
  }
}

const parseData = (data, oneDayData, twoDayData, oneWeekData, ethPrice, oneDayBlock) => {
  const volumeKey = data && data.volumeUSD && Number(data.volumeUSD) > 0 ? 'volumeUSD' : 'untrackedVolumeUSD'
  // get volume changes
  const currentVolume = data && data[volumeKey] ? Number(data[volumeKey]) : 0
  const oneDayVolume = oneDayData && oneDayData[volumeKey] ? Number(oneDayData[volumeKey]) : 0
  const twoDayVolume = twoDayData && twoDayData[volumeKey] ? Number(twoDayData[volumeKey]) : 0
  const oneWeekVolume = oneWeekData && oneWeekData[volumeKey] ? Number(oneWeekData[volumeKey]) : 0
  const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(currentVolume, oneDayVolume, twoDayVolume)
  const [oneDayVolumeUntracked, volumeChangeUntracked] = get2DayPercentChange(
    data?.untrackedVolumeUSD,
    oneDayData?.untrackedVolumeUSD ? Number(oneDayData?.untrackedVolumeUSD) : 0,
    twoDayData?.untrackedVolumeUSD ? twoDayData?.untrackedVolumeUSD : 0,
  )

  const oneWeekVolumeUSD = currentVolume - oneWeekVolume

  const oneWeekVolumeUntracked = Number(oneWeekData ? data?.untrackedVolumeUSD - oneWeekData?.untrackedVolumeUSD : data.untrackedVolumeUSD)

  // set volume properties
  data.oneDayVolumeUSD = oneDayVolumeUSD
  data.oneWeekVolumeUSD = oneWeekVolumeUSD
  data.volumeChangeUSD = volumeChangeUSD
  data.oneDayVolumeUntracked = oneDayVolumeUntracked
  data.oneWeekVolumeUntracked = oneWeekVolumeUntracked
  data.volumeChangeUntracked = volumeChangeUntracked
  data.token0 = {
    ...data.token0,
    symbol: formatTokenSymbol(data.token0.id, data.token0.symbol),
  }
  data.token1 = {
    ...data.token1,
    symbol: formatTokenSymbol(data.token1.id, data.token1.symbol),
  }

  // set liquidity properties
  data.trackedReserveUSD = data.trackedReserveETH * ethPrice
  data.liquidityChangeUSD = getPercentChange(data.reserveUSD, oneDayData?.reserveUSD)

  // format if pair hasnt existed for a day or a week
  if (!oneDayData && data && data.createdAtBlockNumber > oneDayBlock) {
    data.oneDayVolumeUSD = Number(data.volumeUSD ?? 0) ?? Number(data.untrackedVolumeUSD ?? 0)
  }
  if (!oneDayData && data) {
    data.oneDayVolumeUSD = Number(data.volumeUSD ?? 0) ?? Number(data.untrackedVolumeUSD ?? 0)
  }
  if (!oneWeekData && data) {
    data.oneWeekVolumeUSD = Number(data.volumeUSD ?? 0) ?? Number(data.untrackedVolumeUSD ?? 0)
  }

  // format incorrect names
  updateNameData(data)

  return data
}

export function updateNameData(data) {
  if (data?.token0?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token0.id)) {
    data.token0.name = TOKEN_OVERRIDES[data.token0.id].name
    data.token0.symbol = TOKEN_OVERRIDES[data.token0.id].symbol
  }

  if (data?.token1?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token1.id)) {
    data.token1.name = TOKEN_OVERRIDES[data.token1.id].name
    data.token1.symbol = TOKEN_OVERRIDES[data.token1.id].symbol
  }

  return data
}

export async function getGlobalDataV1() {
  // data for each day , historic data used for % changes
  let current = {}
  let oneDayData = {}
  let twoDayData = {}
  let data = {}

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()

    // get the blocks needed for time travel queries
    let [oneDayBlock, twoDayBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcTwoDaysBack])

    // fetch the global data
    const [result, oneDayResult, twoDayResult] = await Promise.all([
      client.query({
        query: GLOBAL_DATA(),
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: GLOBAL_DATA(oneDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: GLOBAL_DATA(twoDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
    ])
    current = result.data.factories[0]
    oneDayData = oneDayResult.data.factories[0]
    twoDayData = twoDayResult.data.factories[0]
    const liquidityChangeUSD = getPercentChange(current.totalLiquidityUSD, oneDayData.totalLiquidityUSD)
    const volumeUSD = Number(current.totalVolumeUSD) - Number(oneDayData.totalVolumeUSD)
    const prevVolumeUSD = Number(oneDayData.totalVolumeUSD) - Number(twoDayData.totalVolumeUSD)
    const volumeChange = getPercentChange(volumeUSD, prevVolumeUSD)

    return (data = {
      totalLiquidityUSD: Number(current.totalLiquidityUSD),
      prevTotalLiquidityUSD: Number(oneDayData.totalLiquidityUSD),
      liquidityChangeUSD,
      volumeUSD,
      prevVolumeUSD,
      volumeChange,
      feesUSD: 0,
      prevFeesUSD: 0,
      feesChange: 0,
    })
  } catch (e) {
    console.log(e)
  }

  data = {
    totalLiquidityUSD: 0,
    prevTotalLiquidityUSD: 0,
    liquidityChangeUSD: 0,
    volumeUSD: 0,
    prevVolumeUSD: 0,
    volumeChange: 0,
    feesUSD: 0,
    prevFeesUSD: 0,
    feesChange: 0,
  }

  return data
}

export async function getAllPairsOnUniswap() {
  try {
    let allFound = false
    let pairs = []
    let skipCount = 0
    while (!allFound) {
      const result = await client.query({
        query: ALL_PAIRS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      skipCount = skipCount + 10
      pairs = pairs.concat(result?.data?.pairs)
      if (result?.data?.pairs.length < 10 || pairs.length > 10) {
        allFound = true
      }
    }
    return pairs
  } catch (e) {
    console.log(e)
  }
}

export async function getAllTokensOnUniswap() {
  try {
    let allFound = false
    let skipCount = 0
    let tokens = []
    while (!allFound) {
      const result = await client.query({
        query: ALL_TOKENS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < 10 || tokens.length > 10) {
        allFound = true
      }
      skipCount = skipCount += 10
    }
    return tokens
  } catch (e) {
    console.log(e)
  }
}

export const getChartData = async (oldestDateToFetch) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      const result = await client.query({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'network-only',
      })
      skip += 1000
      data = data.concat(
        result.data.dayDatas.map((item) => {
          return { ...item, dailyVolumeUSD: Number(item.dailyVolumeUSD) }
        }),
      )
      if (result.data.dayDatas.length < 1000) {
        allFound = true
      }
    }

    if (data) {
      const dayIndexSet = new Set()
      const dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].totalLiquidityUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    data.pop()
    let startIndexWeekly = -1
    let currentWeek = -1
    data.forEach((entry) => {
      const week = dayjs.utc(dayjs.unix(entry.date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        startIndexWeekly++
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {}
      weeklyData[startIndexWeekly].date = entry.date
      weeklyData[startIndexWeekly].weeklyVolumeUSD = (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + entry.dailyVolumeUSD
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export function isAddress(value) {
  try {
    return getAddress(value || '')
  } catch {
    return false
  }
}

export function currencyId(currency, chainId) {
  if (currency === ETHER[chainId]) return 'ETH'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export function calculateSlippageAmount(value, slippage) {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

export function isTokensOnList(defaultTokens, currencies, chainId) {
  return currencies.map((currency) => {
    if (currency === ETHER[chainId] || currency.isNative) return true
    return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
  })
}

export const ExplorerDataType = {
  TRANSACTION: 'transaction',
  TOKEN: 'token',
  ADDRESS: 'address',
  BLOCK: 'block',
}

export function getEtherscanLink(data, type) {
  switch (type) {
    case 'transaction':
    case ExplorerDataType.TRANSACTION: {
      return `https://${getScanUrl()}tx/${data}`
    }
    case 'token':
    case ExplorerDataType.TOKEN: {
      return `https:/${getScanUrl()}token/${data}`
    }
    case 'block':
    case ExplorerDataType.BLOCK: {
      return `https://${getScanUrl()}block/${data}`
    }
    case 'address':
    case ExplorerDataType.ADDRESS:
    default: {
      return `https://${getScanUrl()}address/${data}`
    }
  }
}

export function basisPointsToPercent(num) {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export const shortenTx = (tx) => {
  if (tx.length) {
    const txLength = tx.length
    const first = tx.slice(0, 6)
    const last = tx.slice(txLength - 4, txLength)
    return `${first}...${last}`
  }
  return ''
}

export function formatDateFromTimeStamp(timestamp, format, addedDay = 0) {
  return dayjs.unix(timestamp).add(addedDay, 'day').utc().format(format)
}

export function getFormattedPrice(price) {
  if (price < 0.001 && price > 0) {
    return '<0.001'
  } else if (price > -0.001 && price < 0) {
    return '>-0.001'
  } else {
    const beforeSign = price > 0 ? '+' : ''
    return beforeSign + price.toLocaleString('us')
  }
}

export function getFormattedPercent(percent) {
  if (percent < 0.001 && percent > 0) {
    return '<+0.001%'
  } else if (percent > -0.001 && percent < 0) {
    return '>-0.001%'
  } else if (percent > 10000) {
    return '>+10000%'
  } else if (percent < -10000) {
    return '<-10000%'
  } else {
    const beforeSign = percent > 0 ? '+' : ''
    return beforeSign + percent.toLocaleString('us') + '%'
  }
}

// set different bg and text colors for price percent badge according to price.
export function getPriceClass(price, transparent = false) {
  if (price > 0) {
    return transparent ? 'text-success' : 'bg-successLight text-success'
  } else if (price === 0) {
    return transparent ? 'text-hint' : 'bg-gray1 text-hint'
  } else {
    return transparent ? 'text-error' : 'bg-errorLight text-error'
  }
}

export function getDaysCurrentYear() {
  const year = Number(dayjs().format('YYYY'))
  return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365
}

export function getOneYearFee(dayVolume, reserveUSD) {
  if (!dayVolume || !reserveUSD) {
    return 0
  }

  return (dayVolume * FEEPERCENT * getDaysCurrentYear()) / reserveUSD
}

export function getChartDates(chartData /*, durationIndex */) {
  if (chartData) {
    const dates = []
    chartData.forEach((value, ind) => {
      const month = formatDateFromTimeStamp(Number(value.date), 'MMM')
      const monthLastDate = ind > 0 ? formatDateFromTimeStamp(Number(chartData[ind - 1].date), 'MMM') : ''
      if (monthLastDate !== month) {
        dates.push(month)
      }
      const durationIndex = AnalyticChart.ONE_MONTH_CHART
      if (durationIndex === AnalyticChart.ONE_MONTH_CHART || durationIndex === AnalyticChart.THREE_MONTH_CHART) {
        const dateStr = formatDateFromTimeStamp(Number(value.date), 'D')
        if (Number(dateStr) % (durationIndex === AnalyticChart.ONE_MONTH_CHART ? 3 : 7) === 0) {
          //Select dates(one date per 3 days for 1 month chart and 7 days for 3 month chart) for x axis values of volume chart on week mode
          dates.push(dateStr)
        }
      }
    })
    return dates
  } else {
    return []
  }
}

export function getChartStartTime(durationIndex) {
  const utcEndTime = dayjs.utc()
  const months = durationIndex === AnalyticChart.SIX_MONTH_CHART ? 6 : durationIndex === AnalyticChart.THREE_MONTH_CHART ? 3 : 1
  const startTime =
    utcEndTime
      .subtract(months, durationIndex === AnalyticChart.ONE_YEAR_CHART ? 'year' : 'month')
      .endOf('day')
      .unix() - 1
  return startTime
}

export function getLimitedData(data, count) {
  const dataCount = data.length
  const newArray = []
  data.forEach((value, index) => {
    if (dataCount <= count) {
      newArray.push(value)
    } else {
      if (index === dataCount - Math.floor((dataCount / count) * (count - newArray.length))) {
        newArray.push(value)
      }
    }
  })
  return newArray
}

export function getYAXISValuesAnalytics(chartData) {
  if (!chartData) return
  // multiply 0.99 to the min value of chart values and 1.01 to the max value in order to show all data in graph. Without this, the scale of the graph is set strictly and some values may be hidden.
  const minValue = Math.min(...chartData) * 0.99
  const maxValue = Math.max(...chartData) * 1.01
  const step = (maxValue - minValue) / 8
  const values = []
  for (let i = 0; i < 9; i++) {
    values.push(maxValue - i * step)
  }
  return values
}

export const getTokenInfoSwapDetails = async (bnbPrice, bnbPriceOld, address) => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const [oneDayBlock] = await getBlocksFromTimestamps([utcOneDayBack], 100)

  try {
    const currentDataV2 = await client.query({
      query: TOKEN_INFO(address.toLowerCase()),
      fetchPolicy: 'network-only',
    })

    const oneDayDataV2 = await client.query({
      query: TOKEN_INFO_OLD(oneDayBlock.number, address.toLowerCase()),
      fetchPolicy: 'network-only',
    })

    const currentDataV3 = await fusionClient.query({
      query: TOKENS_FROM_ADDRESSES_V3(undefined, [address.toLowerCase()]),
      fetchPolicy: 'network-only',
    })

    const oneDayDataV3 = await fusionClient.query({
      query: TOKENS_FROM_ADDRESSES_V3(oneDayBlock.number, [address.toLowerCase()]),
      fetchPolicy: 'network-only',
    })

    const currentV2 =
      currentDataV2 && currentDataV2.data && currentDataV2.data.tokens && currentDataV2.data.tokens.length > 0 ? currentDataV2.data.tokens[0] : undefined

    const oneDayV2 =
      oneDayDataV2 && oneDayDataV2.data && oneDayDataV2.data.tokens && oneDayDataV2.data.tokens.length > 0 ? oneDayDataV2.data.tokens[0] : undefined

    const currentV3 =
      currentDataV3 && currentDataV3.data && currentDataV3.data.tokens && currentDataV3.data.tokens.length > 0 ? currentDataV3.data.tokens[0] : undefined

    const oneDayV3 =
      oneDayDataV3 && oneDayDataV3.data && oneDayDataV3.data.tokens && oneDayDataV3.data.tokens.length > 0 ? oneDayDataV3.data.tokens[0] : undefined

    const manageUntrackedVolume = currentV3 ? (+currentV3.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
    const manageUntrackedTVL = currentV3 ? (+currentV3.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''

    const oneDayVolumeUSD =
      Number(currentV3 && currentV3[manageUntrackedVolume] ? currentV3[manageUntrackedVolume] : 0) +
      Number(currentV2 && currentV2.tradeVolumeUSD ? currentV2.tradeVolumeUSD : 0) -
      Number(oneDayV3 && oneDayV3[manageUntrackedVolume] ? oneDayV3[manageUntrackedVolume] : 0) -
      Number(oneDayV2 && oneDayV2.tradeVolumeUSD ? oneDayV2.tradeVolumeUSD : 0)

    const totalLiquidityUSD =
      (currentV3 ? Number(currentV3[manageUntrackedTVL]) : 0) + (currentV2 ? (currentV2.totalLiquidity ?? 0) * bnbPrice * (currentV2.derivedETH ?? 0) : 0)

    const priceUSDV3 = currentV3 ? parseFloat(currentV3.derivedMatic) * bnbPrice : 0
    const priceUSDOneDayV3 = oneDayV3 ? parseFloat(oneDayV3.derivedMatic) * bnbPriceOld : 0
    const priceUSDV2 = currentV2 && currentV2.derivedETH ? currentV2.derivedETH * bnbPrice : 0
    const priceUSDOneDayV2 = oneDayV2 && oneDayV2.derivedETH ? oneDayV2.derivedETH * bnbPriceOld : 0
    const priceUSD = priceUSDV2 ?? priceUSDV3
    const priceUSDOneDay = priceUSDOneDayV2 ?? priceUSDOneDayV3

    const priceChangeUSD = priceUSD && priceUSDOneDay ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneDay.toString())) : 0

    return {
      totalLiquidityUSD,
      oneDayVolumeUSD,
      priceUSD,
      priceChangeUSD,
    }
  } catch (e) {
    console.log(e)
    return
  }
}
