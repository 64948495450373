import { useEffect, useState } from 'react'
import useRefresh from './useRefresh'
import { getOptionAddress } from '../utils/addressHelpers'
import { multicall, multicallChunk } from '../utils/multicall'
import BigNumber from 'bignumber.js'
import { ERC20Abi, olynxAbi } from '../config/abi'
import { useWeb3Wagmi } from './useWeb3'

export const BIG_TEN = new BigNumber(10)
export const BIG_ZERO = new BigNumber(0)

export const usePollOptionsData = () => {
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3Wagmi()
  const [fetchedOptions, setFetchedOptions] = useState()
  const [userData, setUserData] = useState()

  useEffect(() => {
    const fetchUserDataAsync = async ({ account, paymentToken }) => {
      return fetchOptionUserData(account, paymentToken)
    }

    const fetchOptionAsync = async () => {
      const optionData = await fetchOption()
      let userDataResult
      if (account) {
        userDataResult = await fetchUserDataAsync({ account, paymentToken: optionData.paymentToken })
        setUserData(userDataResult)
      }
      setFetchedOptions(optionData)
    }

    if (getOptionAddress()) fetchOptionAsync()
  }, [slowRefresh, account, setUserData, setFetchedOptions])
  return { fetchedOptions, userData }
}

const fetchOption = async () => {
  const optionPublicData = await fetchOptionPublicData()

  return { ...optionPublicData }
}

async function fetchOptionUserData(account, paymentToken) {
  const userAllowances = await fetchOptionUserAllowances(account, paymentToken)
  const userTokenBalances = await fetchUserTokenBalances(account, paymentToken)

  return { userAllowances, userTokenBalances }
}

const fetchOptionPublicData = async () => {
  try {
    const optionAddress = getOptionAddress()
    let calls = [
      {
        address: optionAddress,
        name: 'discount',
      },
      {
        address: optionAddress,
        name: 'veMaxDiscount',
      },
      {
        address: optionAddress,
        name: 'paymentToken',
      },
      {
        address: optionAddress,
        name: 'getDiscountedPrice',
        params: ['1000000000000000000', '0'],
      },
      {
        address: optionAddress,
        name: 'getDiscountedPrice',
        params: ['1000000000000000000', '40'],
      },
      {
        address: optionAddress,
        name: 'lockDurationForMinLpDiscount',
      },
      {
        address: optionAddress,
        name: 'lockDurationForMaxLpDiscount',
      },
      {
        address: optionAddress,
        name: 'minLPDiscount',
      },
      {
        address: optionAddress,
        name: 'maxLPDiscount',
      },
      {
        address: optionAddress,
        name: 'getPaymentTokenAmountForExerciseLp',
        params: ['1000000000000000000', '50'],
      },
      {
        address: optionAddress,
        name: 'gauge',
      },
      {
        address: optionAddress,
        name: 'getSlopeInterceptForLpDiscount',
      },
    ]

    const [
      discount,
      veDiscount,
      paymentToken,
      veDiscountPrice,
      discountPrice,
      lockDurationForMinLpDiscount,
      lockDurationForMaxLpDiscount,
      minLPDiscount,
      maxLPDiscount,
      getPaymentTokenAmountForExerciseLp,
      gaugeAddress,
      slopeInterceptForLpDiscount,
    ] = await multicallChunk(olynxAbi, calls, 6)

    return {
      discount: discount[0],
      veDiscount: veDiscount[0],
      paymentToken: paymentToken[0],
      veDiscountPrice: veDiscountPrice[0],
      discountPrice: discountPrice[0],
      lockDurationForMinLpDiscount: lockDurationForMinLpDiscount[0],
      lockDurationForMaxLpDiscount: lockDurationForMaxLpDiscount[0],
      slopeInterceptForLpDiscount: slopeInterceptForLpDiscount,
      minLPDiscount: minLPDiscount[0],
      maxLPDiscount: maxLPDiscount[0],
      getPaymentAmountToExerciseLp: getPaymentTokenAmountForExerciseLp,
      gaugeAddress,
    }
  } catch (e) {
    console.error(e)
    console.warn('Something went wrong fetching option info')
    return {
      discount: '0',
      veDiscount: '0',
      paymentToken: '0xf56dc6695cf1f5c364edebc7dc7077ac9b586068',
      veDiscountPrice: '0',
      discountPrice: '0',
    }
  }
}

export const fetchOptionUserAllowances = async (account, paymentToken) => {
  const calls = [
    {
      address: paymentToken,
      name: 'allowance',
      params: [account, getOptionAddress()],
    },
  ]
  const rawLpAllowances = await multicall(ERC20Abi, calls)
  const parsedLpAllowances = rawLpAllowances.map((balance) => {
    return new BigNumber(balance).toJSON()
  })
  return parsedLpAllowances[0]
}

export const fetchUserTokenBalances = async (account, paymentToken) => {
  const calls = [
    {
      address: paymentToken,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getOptionAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]

  const rawTokenBalances = await multicall(ERC20Abi, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}
