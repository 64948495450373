import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import Settings from '../../components/common/Settings'
import Tab from '../../components/common/Tab'
import AddLiquidity from '../../components/pages/liquidity/addLiquidity'
import RemoveLiquidity from '../../components/pages/liquidity/removeLiquidity'
import { FusionsContext } from '../../context/FusionsContext'

const ManageLiquidity = () => {
  const [settings, setSettings] = useState(false)
  const [isAdd, setIsAdd] = useState(true)
  const [slippage, setSlippage] = useState(0.5)
  const [deadline, setDeadline] = useState(20)
  const fusions = useContext(FusionsContext)
  const navigate = useNavigate()
  const { address } = useParams()

  const pairs = useMemo(() => {
    return fusions.filter((fusion) => !fusion.isGamma)
  }, [fusions])

  useEffect(() => {
    if (pairs && pairs.length > 0 && address) {
      const item = pairs.find((ele) => ele.address.toLowerCase() === address.toLowerCase())
      if (!item) {
        navigate('/404')
        return
      }
    }
  }, [pairs, address])

  return (
    <>
      <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
        <div className='max-w-[1104px] mx-auto w-full'>
          <div className='flex justify-center'>
            <div className='flex h-11'>
              <Link
                to={'/liquidity/manage'}
                className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-white font-semibold border-themeOrange border rounded-[5px] -mr-px popup-gradientbg`}
              >
                V1
              </Link>
              <Link
                to={'/liquidity/fusion'}
                className={`w-[100px] h-full flex justify-center items-center text-[#A2A0B7] cursor-pointer font-normal border-[#555367] border-r border-t border-b rounded-r-[5px] -ml-[3px] -mr-px`}
              >
                FUSION
              </Link>
            </div>
          </div>
        </div>
        <div className='w-full max-w-[520px] lg:max-w-[595px] gradient-bg p-px rounded-[5px] mx-auto relative  mt-6'>
          <OutsideClickHandler
            onOutsideClick={() => {
              setSettings(false)
            }}
          >
            <div className='w-full popup-gradientbg px-3 py-3 rounded-[5px] md:px-6 md:py-5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <button
                    className='mr-[20px]'
                    onClick={() => {
                      navigate('/liquidity')
                    }}
                  >
                    <img alt='' src='/images/swap/back-arrow.svg' />
                  </button>
                  <h4 className='text-[1.3rem] md:text-[22px] f-f-fg text-white font-bold'>Manage Liquidity</h4>
                </div>
                <button
                  onClick={() => {
                    setSettings(!settings)
                  }}
                  className=''
                >
                  <img className='w-3/4 sm:w-auto' alt='' src='/images/swap/bar.svg' />
                </button>
              </div>
              {settings && <Settings slippage={slippage} setSlippage={setSlippage} deadline={deadline} setDeadline={setDeadline} />}
              <div className='w-full mt-4 md:mt-[29px]'>
                <Tab leftTitle='Add' rightTitle='Remove' isLeft={isAdd} setIsLeft={setIsAdd} />
                {isAdd ? (
                  <AddLiquidity slippage={slippage} deadline={deadline} pairs={pairs} pairAddress={address} />
                ) : (
                  <RemoveLiquidity slippage={slippage} deadline={deadline} pairs={pairs} pairAddress={address} />
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  )
}

export default ManageLiquidity
