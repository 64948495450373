// import { useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/v3/hooks'
import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useAlgebra } from '../useContract'
import { multicall } from '../../utils/multicall'
import { algebraAbi } from '../../config/abi/v3'
import { getAlgebraAddress } from '../../utils/addressHelpers'
import useRefresh from '../useRefresh'

export function useV3Positions(account) {
  const [positions, setPositions] = useState([])
  const positionManager = useAlgebra()
  const { fastRefresh } = useRefresh()

  const fetchInfo = useCallback(async () => {
    const accountBalance = await positionManager.methods.balanceOf(account).call()
    if (Number(accountBalance) === 0) return
    const tokenRequests = []
    for (let i = 0; i < accountBalance; i++) {
      tokenRequests.push(i)
    }
    const tokenIdCalls = tokenRequests.map((item) => {
      return {
        address: getAlgebraAddress(),
        name: 'tokenOfOwnerByIndex',
        params: [account, item],
      }
    })
    const tokenIds = await multicall(algebraAbi, tokenIdCalls)
    const inputs = tokenIds.map((tokenId) => Number(tokenId[0]))
    const positionCalls = inputs.map((input) => {
      return {
        address: getAlgebraAddress(),
        name: 'positions',
        params: [input],
      }
    })
    const positionRes = await multicall(algebraAbi, positionCalls)
    const result = positionRes.map((pos, index) => {
      return {
        tokenId: BigNumber.from(inputs[index]),
        fee: pos.fee,
        feeGrowthInside0LastX128: pos.feeGrowthInside0LastX128,
        feeGrowthInside1LastX128: pos.feeGrowthInside1LastX128,
        liquidity: pos.liquidity,
        nonce: pos.nonce,
        operator: pos.operator,
        tickLower: pos.tickLower,
        tickUpper: pos.tickUpper,
        token0: pos.token0,
        token1: pos.token1,
        tokensOwed0: pos.tokensOwed0,
        tokensOwed1: pos.tokensOwed1,
      }
    })
    setPositions(result)
  }, [account])

  useEffect(() => {
    if (account) {
      fetchInfo()
    } else {
      setPositions([])
    }
  }, [fastRefresh, fetchInfo, account])

  return positions
}
