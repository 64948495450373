import React from 'react'
import './info.scss'

const Info = () => {
  return (
    <div className='info'>
      <div className='title'>
        <p>Community Airdrop</p>
        <p className='text-2xl'>$LYNX/veLYNX</p>
      </div>
      <p className='text'>10% of the initial supply to be distributed to valuable DeFi community members</p>
      <div className='flex items-center space-x-2.5 cursor-pointer w-full sm:w-1/2 group'>
        <a
          className='learn-more'
          href='https://lynex.gitbook.io/lynex-docs/tokenomics/initial-token-distribution/community-airdrop'
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
        <img className={`group-hover:w-[35px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
      </div>
    </div>
  )
}

export default Info
