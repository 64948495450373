import BigNumber from 'bignumber.js'
import { getV3VoterContract, getVeTHEContract, getVeTHEV3APIContract } from './contractHelpers'
import { fromWei } from './formatNumber'
import { epochDuration } from '../hooks/useGeneral'

export const fetchUserVeTHEs = async (web3, account) => {
  const veTHEAPIContract = getVeTHEV3APIContract(web3)
  const votingEscrowContract = getVeTHEContract(web3)
  const voterContract = getV3VoterContract(web3)
  const currentEpoch = await voterContract.methods._epochTimestamp().call()
  const currentWeek = Math.floor(new Date().getTime() / (epochDuration * 1000))
  const [thisWeekVotingPower, nextWeekVotingPower, veTHEInfos, votesInfos] = await Promise.all([
    votingEscrowContract.methods.getPastVotes(account, currentEpoch).call(),
    votingEscrowContract.methods.getPastVotes(account, parseInt(currentEpoch) + epochDuration).call(),
    veTHEAPIContract.methods.getNFTFromAddress(account).call(),
    veTHEAPIContract.methods.getVotesFromAddress(account).call(),
  ])

  const totalVotes = votesInfos.votes.reduce((sum, current) => {
    return sum.plus(current.weight)
  }, new BigNumber(0))

  const accountInfo = {
    votingPower: fromWei(votesInfos.votingPower),
    epochVotes: fromWei(votesInfos.epochVotes),
    nextEpochVotes: fromWei(votesInfos.nextEpochVotes),
    voteTs: votesInfos.voteTs,
    voted: votesInfos.voted && votesInfos.votes.length > 0,
    votedCurrentEpoch: votesInfos.voted && votesInfos.votes.length > 0,
    votes: votesInfos.votes.map((item) => {
      return {
        address: item.pair,
        weight: fromWei(item.weight),
        weightPercent: totalVotes.isZero() ? new BigNumber(0) : new BigNumber(item.weight).div(totalVotes).times(100),
      }
    }),
  }
  const userVeInfo = veTHEInfos.map((veTHE) => {
    const lockedEnd = Number(veTHE[7])
    const diff = Math.ceil((lockedEnd - new Date().getTime() / 1000) / 86400)

    const votedWeek = Math.floor(Number(veTHE[8]) / epochDuration)
    const votedCurrentEpoch = votedWeek === currentWeek && veTHE.votes.length > 0 // && veTHE[1]

    return {
      thisWeekVotingPower: fromWei(thisWeekVotingPower),
      nextWeekVotingPower: fromWei(nextWeekVotingPower),
      decimals: Number(veTHE[0]),
      voted: veTHE[1],
      votedCurrentEpoch,
      attachments: veTHE[2],
      id: veTHE[3],
      amount: fromWei(veTHE[4]),
      voting_amount: fromWei(veTHE[5]),
      rebase_amount: fromWei(veTHE[6]),
      lockEnd: lockedEnd,
      delegatee: veTHE.delegatee,
      vote_ts: veTHE[8],
      votes: veTHE[9].map((item) => {
        return {
          address: item[0],
          weight: fromWei(item[1]),
          weightPercent: totalVotes.isZero() ? new BigNumber(0) : new BigNumber(item[1]).div(totalVotes).times(100),
        }
      }),
      diffDates: diff > 0 ? 'Expires in ' + diff + ' days' : 'Expired ' + diff * -1 + ' days ago',
    }
  })
  return { accountInfo, userVeInfo }
}
