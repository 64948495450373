import { useCallback, useState } from 'react'
import useWeb3, { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { getMerkleTreeContract } from '../../../../utils/contractHelpers'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { completeTransaction, openTransaction } from '../../../../state/transactions/actions'
import { TransactionType } from '../../../../config/constants'
import { sendContract } from '../../../../utils/api'

export const useAirdropClaim = (type) => {
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const web3 = useWeb3()

  const handleClaim = useCallback(
    async (amount, veShare, proof) => {
      const key = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim earnings`,
          transactions: {
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const merkleContract = getMerkleTreeContract(web3, type)
      setPending(true)
      try {
        const params = [account, amount, veShare, proof]
        await sendContract(dispatch, key, harvestuuid, merkleContract, 'claim', params, account)
      } catch (err) {
        console.log('claim error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Airdrop Claimed',
        }),
      )
      setPending(false)
    },
    [account, web3, type],
  )

  return { onClaim: handleClaim, pending }
}
