import React, { useContext, useState } from 'react'
import GammaPosition from './gammaPosition'
import useWalletModal from '../../../../../hooks/useWalletModal'
import StyledButton from '../../../../common/Buttons/styledButton'
import { GammaContext } from '../../../../../context/GammaContext'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3'

const MyGammaPools = () => {
  const [autoLp, setAutoLp] = useState(-1)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const fusions = useContext(GammaContext)
  const gammaPositions = fusions.filter((pos) => pos.account.totalLp.gt(0) && pos.isGamma)
  console.log(gammaPositions, fusions)

  return (
    <div className={`max-h-[550px] overflow-auto w-full  rounded-md bg-white/10 py-3 px-4 lg:px-6 relative`}>
      <p className='text-lg lg:text-[22px] leading-[22px] lg:leading-[27px] f-f-fg font-semibold text-white'>Your Automatic LP</p>
      {!account ? (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-white text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      ) : gammaPositions.length > 0 ? (
        <div className=' mt-2.5 md:mt-[9px]'>
          {gammaPositions.map((position, id) => {
            return <GammaPosition autoLp={autoLp} setAutoLp={setAutoLp} id={id} key={id} position={position} />
          })}
        </div>
      ) : (
        <p className='mt-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>You do not have any liquidity positions.</p>
      )}
    </div>
  )
}

export default MyGammaPools
