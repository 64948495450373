import React, { useState } from 'react'
import config from './config'
import './faq.scss'

const Faq = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className='wrapper'>
      <div className='title' onClick={() => setIsExpanded(!isExpanded)}>
        <p>faq</p>
        <img className='arrow' src={`${isExpanded ? '/images/header/chevron.svg' : '/images/header/chevron-down.svg'}`} alt='' />
      </div>
      <div className={`qa-wrapper ${!isExpanded ? 'invisible' : ''}`}>
        {config.map(({ title, description }, i) => (
          <div className={`qa ${!isExpanded ? 'h-0' : ''}`} key={`qa + ${i}`}>
            <p className={`question ${!isExpanded ? 'h-0' : ''}`} key={title} id={title}>
              {title}
            </p>
            {description.map((desc) => {
              return (
                <p className='answer' key={desc}>
                  {desc}
                </p>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq
