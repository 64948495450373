import { InjectedConnector } from '@web3-react/injected-connector'
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// import { WalletConnect } from '@web3-react/walletconnect-v2'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import getNodeUrl from './getRpcUrl'
import { WalletConnectConnectorV2 } from './walletConnectConnectorV2'
import { BitgetConnector } from './bitgetWallet'

// const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const defaultChainId = process.env.REACT_APP_CHAIN_ID

const supportedChainIds = [Number(defaultChainId)]

export const injected = new InjectedConnector({
  supportedChainIds,
})

export const bitgetWallet = new BitgetConnector({
  supportedChainIds,
})

const walletconnect = new WalletConnectConnectorV2({
  projectId: process.env.REACT_APP_PROJECT_ID,
  chains: supportedChainIds,
  showQrModal: true,
  rpcMap: { [supportedChainIds[0]]: rpcUrl },
})

const walletlink = new WalletLinkConnector({
  url: rpcUrl,
  appName: 'Lynex',
  supportedChainIds: supportedChainIds,
})

const binanceChainWalletConnector = new BscConnector({ supportedChainIds })

export const ConnectorNames = {
  MetaMask: 'MetaMask',
  TrustWallet: 'TrustWallet',
  WalletConnect: 'WalletConnectConnectorV2',
  Coinbase: 'Coinbase',
  Bitget: 'BitgetWallet',
  BinanceChainWallet: 'BinanceChainWallet',
  Coin98Wallet: 'Coin98Wallet',
}

export const connectorsByName = {
  [ConnectorNames.MetaMask]: injected,
  [ConnectorNames.TrustWallet]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.Coinbase]: walletlink,
  [ConnectorNames.Bitget]: bitgetWallet,
  [ConnectorNames.BinanceChainWallet]: binanceChainWalletConnector,
  [ConnectorNames.Coin98Wallet]: injected,
}
