import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TaxAssets, TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getRouterAddress, getWBNBAddress } from '../utils/addressHelpers'
import { getERC20Contract, getGaugeContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, MAX_UINT256, toWei } from '../utils/formatNumber'
import { useRouter } from './useContract'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import useRefresh from './useRefresh'

const useAddLiquidity = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const routerAddress = getRouterAddress()
  const routerContract = useRouter()
  const web3 = useWeb3()

  const handleAdd = useCallback(
    async (firstAsset, secondAsset, firstAmount, secondAmount, isStable, slippage, deadline) => {
      const key = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const supplyuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${firstAsset.symbol} and ${secondAsset.symbol} (${isStable ? 'Stable' : 'Volatile'})`,
          transactions: {
            [approve0uuid]: {
              desc: `Approve ${firstAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${secondAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (firstAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, firstAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, firstAsset.decimals).lt(firstAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approve0uuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      if (secondAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, secondAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, secondAsset.decimals).lt(firstAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approve1uuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 1 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const sendSlippage = new BigNumber(100).minus(slippage).div(100)
      const sendAmount0 = toWei(firstAmount, firstAsset.decimals).toFixed(0)
      const sendAmount1 = toWei(secondAmount, secondAsset.decimals).toFixed(0)
      const deadlineVal =
        '' +
        moment()
          .add(Number(deadline) * 60, 'seconds')
          .unix()
      const sendAmount0Min = toWei(sendSlippage.times(firstAmount), firstAsset.decimals).toFixed(0)
      const sendAmount1Min = toWei(sendSlippage.times(secondAmount), secondAsset.decimals).toFixed(0)

      let func = 'addLiquidity'
      let params = [firstAsset.address, secondAsset.address, isStable, sendAmount0, sendAmount1, sendAmount0Min, sendAmount1Min, account, deadlineVal]
      let sendValue = '0'

      if (firstAsset.address === 'ETH') {
        func = 'addLiquidityETH'
        params = [secondAsset.address, isStable, sendAmount1, sendAmount1Min, sendAmount0Min, account, deadlineVal]
        sendValue = sendAmount0
      }
      if (secondAsset.address === 'ETH') {
        func = 'addLiquidityETH'
        params = [firstAsset.address, isStable, sendAmount0, sendAmount0Min, sendAmount1Min, account, deadlineVal]
        sendValue = sendAmount1
      }

      try {
        await sendContract(dispatch, key, supplyuuid, routerContract, func, params, account, sendValue)
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added',
        }),
      )
      setPending(false)
    },
    [account, web3, routerContract],
  )

  const handleAddAndStake = useCallback(
    async (pair, firstAsset, secondAsset, firstAmount, secondAmount, isStable, slippage, deadline) => {
      const key = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const approve2uuid = uuidv4()
      const supplyuuid = uuidv4()
      const stakeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${firstAsset.symbol} and ${secondAsset.symbol} (${isStable ? 'Stable' : 'Volatile'})`,
          transactions: {
            [approve0uuid]: {
              desc: `Approve ${firstAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${secondAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
            [approve2uuid]: {
              desc: `Approve ${pair.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Stake LP tokens in the gauge`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (firstAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, firstAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, firstAsset.decimals).lt(firstAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approve0uuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      if (secondAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, secondAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, secondAsset.decimals).lt(firstAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approve1uuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 1 error :>> ', err)
            setPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const sendSlippage = new BigNumber(100).minus(slippage).div(100)
      const sendAmount0 = toWei(firstAmount, firstAsset.decimals).toFixed(0)
      const sendAmount1 = toWei(secondAmount, secondAsset.decimals).toFixed(0)
      const deadlineVal =
        '' +
        moment()
          .add(Number(deadline) * 60, 'seconds')
          .unix()
      const sendAmount0Min = toWei(sendSlippage.times(firstAmount), firstAsset.decimals).toFixed(0)
      const sendAmount1Min = toWei(sendSlippage.times(secondAmount), secondAsset.decimals).toFixed(0)

      let func = 'addLiquidity'
      let params = [firstAsset.address, secondAsset.address, isStable, sendAmount0, sendAmount1, sendAmount0Min, sendAmount1Min, account, deadlineVal]
      let sendValue = '0'

      if (firstAsset.address === 'ETH') {
        func = 'addLiquidityETH'
        params = [secondAsset.address, isStable, sendAmount1, sendAmount1Min, sendAmount0Min, account, deadlineVal]
        sendValue = sendAmount0
      }
      if (secondAsset.address === 'ETH') {
        func = 'addLiquidityETH'
        params = [firstAsset.address, isStable, sendAmount0, sendAmount0Min, sendAmount1Min, account, deadlineVal]
        sendValue = sendAmount1
      }

      try {
        await sendContract(dispatch, key, supplyuuid, routerContract, func, params, account, sendValue)
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }

      isApproved = true
      const pairContract = getERC20Contract(web3, pair.address)
      const allowance = await getAllowance(pairContract, pair.gauge.address, account)
      const balanceOf = await pairContract.methods.balanceOf(account).call()
      if (fromWei(allowance).lt(fromWei(balanceOf))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve2uuid, pairContract, 'approve', [pair.gauge.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }

      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve2uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const gaugeContract = getGaugeContract(web3, pair.gauge.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', [balanceOf], account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added and Staked',
        }),
      )
      setPending(false)
    },
    [account, web3, routerContract],
  )

  return { onAdd: handleAdd, onAddAndStake: handleAddAndStake, pending }
}

const useQuoteRemove = (pair, withdrawAmount) => {
  const [outputs, setOutputs] = useState({
    firstAmount: '',
    secondAmount: '',
  })
  const contract = useRouter()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchInfo = async () => {
      const res = await contract.methods
        .quoteRemoveLiquidity(pair.token0.address, pair.token1.address, pair.stable, toWei(withdrawAmount).dp(0).toString(10))
        .call()
      setOutputs({
        firstAmount: fromWei(res.amountA, pair.token0.decimals).toString(10),
        secondAmount: fromWei(res.amountB, pair.token1.decimals).toString(10),
      })
    }
    if (pair && withdrawAmount && withdrawAmount !== '') {
      fetchInfo()
    } else {
      setOutputs({
        firstAmount: '',
        secondAmount: '',
      })
    }
  }, [contract, fastRefresh, pair, withdrawAmount])

  return outputs
}

const useRemoveLiquidity = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const routerAddress = getRouterAddress()
  const routerContract = useRouter()
  const web3 = useWeb3()

  const handleRemove = useCallback(
    async (pair, withdrawAmount, slippage, deadline, firstAmount, secondAmount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const removeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Remove liquidity from ${pair.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${pair.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      const tokenContract = getERC20Contract(web3, pair.address)
      const allowance = await getAllowance(tokenContract, routerAddress, account)
      if (fromWei(allowance, pair.decimals).lt(withdrawAmount)) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const sendSlippage = new BigNumber(100).minus(slippage).div(100)
      const sendAmount = toWei(withdrawAmount, pair.decimals).toFixed(0)
      const sendAmount0Min = toWei(firstAmount, pair.token0.decimals).times(sendSlippage).toFixed(0)
      const sendAmount1Min = toWei(secondAmount, pair.token1.decimals).times(sendSlippage).toFixed(0)
      const deadlineVal =
        '' +
        moment()
          .add(Number(deadline) * 60, 'seconds')
          .unix()

      let func = 'removeLiquidity'
      let params = [pair.token0.address, pair.token1.address, pair.stable, sendAmount, sendAmount0Min, sendAmount1Min, account, deadlineVal]

      if (pair.token0.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
        func = TaxAssets.includes(pair.token1.address.toLowerCase()) ? 'removeLiquidityETHSupportingFeeOnTransferTokens' : 'removeLiquidityETH'
        params = [pair.token1.address, pair.stable, sendAmount, sendAmount1Min, sendAmount0Min, account, deadlineVal]
      }
      if (pair.token1.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
        func = TaxAssets.includes(pair.token0.address.toLowerCase()) ? 'removeLiquidityETHSupportingFeeOnTransferTokens' : 'removeLiquidityETH'
        params = [pair.token0.address, pair.stable, sendAmount, sendAmount0Min, sendAmount1Min, account, deadlineVal]
      }
      try {
        await sendContract(dispatch, key, removeuuid, routerContract, func, params, account)
      } catch (err) {
        console.log('remove error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3, routerContract],
  )

  return { onRemove: handleRemove, pending }
}

export { useAddLiquidity, useRemoveLiquidity, useQuoteRemove }
