import React, { useState, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import Pagination from '../../common/Pagination'
import Sticky from 'react-stickynode'
import StyledButton from '../../common/Buttons/styledButton'
import { NumberOfRows } from '../../../config/constants'
import { BIG_ZERO, formatAmount } from '../../../utils/formatNumber'
import useWalletModal from '../../../hooks/useWalletModal'
import NoFound from '../../common/NoFound'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { useDelegate } from '../../../hooks/useLock'

const Item = ({ usd, content, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return usd.gt(0) ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd)}</p>
        <button className={`${arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[260px] !bg-themeBlack !border !border-white !text-white !text-base !py-[9px] !px-6 !opacity-100 after:!bg-themeBlack'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>$0</div>
  )
}

const Apr = ({ apr, content, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{`${formatAmount(apr)}%`}</p>
        <button className={`${arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[260px] !bg-themeBlack !border !border-white !text-white !text-base !py-[9px] !px-6 !opacity-100 after:!bg-themeBlack'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </div>
  )
}

const Row = ({ idx, pool, delegated, tokenId }) => {
  const { openWalletModal } = useWalletModal()
  const { account } = useWeb3Wagmi()
  const { onDelegate, pending } = useDelegate()

  return (
    <div key={idx} className='table-row-wrapper'>
      <div className='flex w-full lg:w-[20%] items-center  space-x-3'>
        <div className='flex items-center  -space-x-2'>
          <img className='relative z-10' alt='' src={`/images/delegate/${pool.logo}`} />
        </div>
      </div>
      <div className='flex w-full lg:w-[20%] items-center  space-x-3'>
        <div className='flex items-center  -space-x-2'>
          <span className={`${pool && pool.votedCurrentEpoch ? `text-[#2CBA52]` : 'text-error'} text-lg md:text-1xl font-semibold`}>
            {pool && pool.votedCurrentEpoch ? 'Voted' : 'Pending'}
          </span>
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Voting APR</p>
        <Apr
          type={'apr'}
          apr={pool?.votedCurrentEpoch ? pool.apr : 0}
          content={
            <>
              <p>Weekly average: {formatAmount(pool.apr, true)}%</p>
              <p>Monthly average: {formatAmount(pool.apr, true)}%</p>
            </>
          }
          idx={idx}
        />
      </div>
      {/* second row */}
      <div className='flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Rewards</p>
        <Item
          type={'rewards'}
          usd={pool?.votedCurrentEpoch ? pool?.allBribeUsd || BIG_ZERO : BIG_ZERO}
          content={
            pool.aggregatedRewards?.length > 0 && (
              <>
                <div className='text-themeOrange'>Projected Rewards</div>
                {pool.aggregatedRewards.map((reward, idx) => {
                  return (
                    <p key={`bribe-${idx}`}>
                      {formatAmount(reward.amount)} {reward.symbol}
                    </p>
                  )
                })}
              </>
            )
          }
          idx={idx}
        />
      </div>{' '}
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[18%] justify-center'>
        <div className='text-sm xl:text-base flex items-center space-x-1.5'>
          <div className='flex flex-col items-start justify-center'>
            <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Votes</p>
            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(pool.epochVotes)}</div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Votes</p>
        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(pool.votingPower)}</div>
        {/* <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center space-x-1.5'>
          <p>{account ? formatAmount(pool.votes) + '%' : '-'}</p>
        </div> */}
      </div>{' '}
      <div className='w-full lg:w-[24%] mt-3.5 lg:mt-0'>
        {!account ? (
          <div className='w-full  flex  items-center lg:justify-end'>
            <StyledButton
              onClickHandler={() => openWalletModal()}
              content={'Connect Wallet'}
              className='flex items-center justify-center text-white tracking-[1.44px] py-[10px] px-[16px] rounded-[6px]'
            />
          </div>
        ) : (
          <div className='flex flex-col lg:items-end justify-end w-full'>
            <StyledButton
              disabled={delegated || pending || !tokenId}
              onClickHandler={() => {
                onDelegate(tokenId, pool.address)
              }}
              content={delegated ? 'DELEGATED' : 'DELEGATE'}
              className='flex items-center justify-center text-white tracking-[1.44px] py-[10px] px-[32px] rounded-[6px]'
            />
          </div>
        )}
      </div>
    </div>
  )
}

const Table = ({ pools, veTHE, sort, sortOptions }) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)

  const sortedPools = useMemo(() => {
    return pools.sort((a, b) => {
      let res
      switch (sort.value) {
        case sortOptions[0].value:
          res = a.gauge.voteApr
            .minus(b.gauge.voteApr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[1].value:
          res = a.gauge.weight
            .minus(b.gauge.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[2].value:
          res = a.gauge.bribeUsd
            .minus(b.gauge.bribeUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[3].value:
          res = a.votes.weight
            .minus(b.votes.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [pools, sort])

  const pageCount = useMemo(() => {
    return Math.ceil(sortedPools.length / pageSize)
  }, [sortedPools, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  return (
    <>
      <div className='w-full mt-4 lg:mt-8'>
        {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
          <div className='w-full'>
            <Sticky
              enabled={true}
              innerActiveClass={'bg-themeOrangeLight'}
              top={95}
              activeClass={''}
              innerClass={'px-6 lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'}
              className={`z-[100]`}
            >
              <div className='w-[20%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
              <div className='w-[20%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
              {sortOptions.map((option, index) => (
                <div className={`${index === 0 ? 'w-[14%]' : 'w-[18%]'} font-medium text-[17px] xl:text-[18px] text-white f-f-fg`} key={`header-${index}`}>
                  <div className='flex items-center space-x-1 -ml-1 relative'>
                    <p className='flex items-center'>{option.label}</p>
                  </div>
                </div>
              ))}
              <div className='w-[24%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
            </Sticky>
            <div className='flex flex-col gap-3'>
              {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((pool, idx) => {
                return <Row pool={pool} tokenId={veTHE?.id} idx={idx} key={idx} delegated={veTHE?.delegatee.toLowerCase() === pool.address.toLowerCase()} />
              })}
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
              total={sortedPools.length}
            />
          </div>
        ) : (
          <NoFound title='No pools found' />
        )}
      </div>
    </>
  )
}

export default Table
