import React from 'react'
import Card from '../../components/pages/whiteList/card'
import { useNavigate } from 'react-router-dom'
const Index = () => {
  const navigate = useNavigate()
  const cards = [
    {
      img: '/images/whiteList/icon-4.svg',
      title: 'Add Gauge',
      para: 'Create a new gauge which can be used for staking and voting.',
      button: 'ADD GAUGE',
      route: '/whitelist/gauge',
      CTA: true,
    },
    {
      img: '/images/whiteList/icon-5.svg',
      title: 'Add Bribe',
      para: 'Add a bribe reward for an existing gauge to incentivize votes on it.',
      button: 'ADD BRIBE',
      route: '/whitelist/bribe',
      CTA: true,
    },
  ]

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-[160px] mx-auto lg:pb-[75px]'>
        <div className='lg:flex items-end justify-between lg:space-x-[60px]'>
          <div className='w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Gauges</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Create a new Gauge or add a bribe on existing gauge.{' '}
              <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
          {/* <Timer arr={balance} className={`w-full lg:w-1/2 mt-4 lg:mt-0`} /> */}
        </div>
        <div className='w-full mt-11 grid lg:grid-cols-2 gap-6'>
          {cards.map((item, idx) => {
            return (
              <Card
                className={''}
                title={item.title}
                img={item.img}
                para={item.para}
                button={item.button}
                cta={item.CTA}
                key={idx}
                setOpen={() => {
                  navigate(item.route)
                }}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Index
