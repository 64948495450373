import React from 'react'
import { Link } from 'react-router-dom'
import V3AddLiquidity from '../../components/pages/liquidity/v3/addLiquidity'
import MyGammaPools from '../../components/pages/liquidity/v3/myGammaPools'
import MyAlgebraPools from '../../components/pages/liquidity/v3/myAlgebraPools'

const ManageLiquidity = () => {
  return (
    <div className='w-full max-w-[1104px] mx-auto pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
      <div className='flex justify-center'>
        <div className='flex h-11'>
          <Link
            to={'/liquidity/manage'}
            className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-[#A2A0B7] font-normal border-[#555367] border-l border-t border-b rounded-l-[5px] -mr-[3px] -ml-px`}
          >
            V1
          </Link>
          <Link
            to={'/liquidity/fusion'}
            className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-white font-semibold border-themeOrange border rounded-[5px] -mr-px popup-gradientbg`}
          >
            FUSION
          </Link>
        </div>
      </div>
      <div className='lg:flex items-start lg:space-x-6 mt-6'>
        <V3AddLiquidity />
        <div className='lg:max-w-[550px] w-full mt-5 lg:mt-0'>
          <MyGammaPools />
          <MyAlgebraPools />
        </div>
      </div>
    </div>
  )
}

export default ManageLiquidity
