import React from 'react'
import './hero.scss'
import CTA from '../../../common/Buttons/cta'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()
  // const [loadCount, setLoadCount] = useState(0)

  // const setVideoLoaded = () => {
  //   if (loadCount <= 1) {
  //     setLoadCount(loadCount + 1)
  //   }
  // }
  return (
    <div className='hero-container'>
      <div>
        <p className='hero-header'>The Native Liquidity Layer Of Linea</p>
        <p className='hero-text'>
          Step into the heart of Linea's intrinsic on-chain liquidity marketplace. Driven by state-of-the-art DEX infrastructure, we present an exceptionally
          efficient DeFi solution. Immerse yourself in the capabilities of Lynex now!
        </p>
        <div className='hero-actions'>
          <CTA
            icon={true}
            className='md:max-w-[250px] xl:max-w-[350px]'
            title={'SWAP NOW'}
            onClickHandler={() => {
              navigate('/swap')
            }}
          />
        </div>
      </div>
      <img className='hero-image' src='/images/home/hero.png' alt='hero image' />
    </div>
  )
}

export default Index
