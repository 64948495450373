import React, { useMemo, useState } from 'react'
import TransparentButton from '../../../components/common/Buttons/transparentButton'
import StyledButton from '../../../components/common/Buttons/styledButton'
import TransictionTable from '../../../components/pages/analytics/transictionTable'
import AnalyticsHeader from '../../../components/pages/analytics/analyticsHeader'
import { useNavigate, useParams } from 'react-router'
import { usePairChartData, usePairData, usePairTransactions } from '../../../context/PairData'
import { useLogoFromAddress } from '../../../hooks/v3/useCurrencyLogo'
import { formatAmount } from '../../../utils/formatNumber'
import { getChartDates, getYAXISValuesAnalytics } from '../../../utils/analyticsHelper'
import dayjs from 'dayjs'
import AreaChart from '../../../components/common/AreaChart'
import { getScanUrl } from '../../../utils'

const ChartIndexes = {
  Volume: 'Volume',
  TVL: 'TVL',
  Fees: 'Fees',
}

const PairDetails = () => {
  const [chartIndex, setChartIndex] = useState(ChartIndexes.Volume)
  const { id: pairAddress } = useParams()
  const navigate = useNavigate()
  const pairData = usePairData(pairAddress)
  const { token0, token1 } = pairData
  const transactions = usePairTransactions(pairAddress, pairData)
  const pairChartData = usePairChartData(pairAddress, pairData)
  const token0Logo = useLogoFromAddress(token0?.id)
  const token1Logo = useLogoFromAddress(token1?.id)

  const currentVal = useMemo(() => {
    switch (chartIndex) {
      case ChartIndexes.TVL:
        return Number(pairData?.totalValueLockedUSD)
      case ChartIndexes.Volume:
        return Number(pairData?.oneDayVolumeUSD)
      case ChartIndexes.Fees:
        return Number(pairData?.oneDayFeesUSD)
      default:
        return
    }
  }, [pairData, chartIndex])

  const result = useMemo(() => {
    if (!pairChartData) return
    const res = [...pairChartData]
    if (chartIndex !== ChartIndexes.TVL) {
      res.pop()
    }
    return res
  }, [pairChartData, chartIndex])

  const chartData = useMemo(() => {
    if (!result) return
    return result.map((item) => {
      switch (chartIndex) {
        case ChartIndexes.Volume:
          return Number(item.dailyVolumeUSD)
        case ChartIndexes.TVL:
          return Number(item.reserveUSD)
        case ChartIndexes.Fees:
          return Number(item.feesUSD)
        default:
          return
      }
    })
  }, [result, chartIndex])

  return (
    <div className='w-full pt-20 pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
      <div className='max-w-[1104px] mx-auto w-full'>
        <AnalyticsHeader data={pairData} />
        {Object.keys(pairData).length > 0 && (
          <>
            <div className='lg:flex items-center justify-between mt-7 md:mt-9'>
              <div>
                <div className='flex items-center space-x-1.5'>
                  <div className='flex items-center space-x-2'>
                    <div className='flex items-center -space-x-4'>
                      <img alt='' src={token0Logo} className='w-[30px] md:w-9 z-10' />
                      <img alt='' src={token1Logo} className='w-[30px] md:w-9' />
                    </div>
                    <div className='text-[23px] md:text-[27px] leading-8 font-semibold text-[#DEDBF2]'>
                      <span
                        className='cursor-pointer'
                        onClick={() => {
                          navigate(`/analytics/total/token/${token0.id}`)
                        }}
                      >
                        {token0.symbol}
                      </span>
                      /
                      <span
                        className='cursor-pointer'
                        onClick={() => {
                          navigate(`/analytics/total/token/${token1.id}`)
                        }}
                      >
                        {token1.symbol}
                      </span>
                    </div>
                  </div>
                  {pairData.isFusion && (
                    <div className={`px-2 py-1 leading-5 text-[15px] table rounded-md bg-white bg-opacity-[0.07] text-[#DEDBF2]`}>
                      {Number(pairData.fee) / 10000}%
                    </div>
                  )}
                </div>
                <div className='table md:flex items-center md:space-x-2 mt-[5px] md:mt-2'>
                  <div className={`px-2 py-1 leading-5 text-sm flex items-center space-x-1  rounded-full bg-white bg-opacity-[0.07] text-[#DEDBF2]`}>
                    <img alt='' src={token0Logo} className='w-5' />
                    <p>
                      1 {token0.symbol} = {formatAmount(Number(token0.derivedETH || token0.derivedMatic) / Number(token1.derivedETH || token1.derivedMatic))}{' '}
                      {token1.symbol}
                    </p>
                  </div>
                  <div
                    className={`mt-1.5 lg:mt-0 px-2 py-1 leading-5 text-sm flex items-center space-x-1  rounded-full bg-white bg-opacity-[0.07] text-[#DEDBF2]`}
                  >
                    <img alt='' src={token1Logo} className='w-5' />
                    <p>
                      1 {token1.symbol} = {formatAmount(Number(token1.derivedETH || token1.derivedMatic) / Number(token0.derivedETH || token0.derivedMatic))}{' '}
                      {token0.symbol}
                    </p>
                  </div>
                </div>
              </div>
              <div className='md:flex items-center md:space-x-5 mt-[18px] md:mt-0'>
                <div className='flex items-center space-x-3 md:space-x-5 mt-3 md:mt-0'>
                  <TransparentButton
                    content={'ADD/REMOVE LIQUIDITY'}
                    onClickHandler={() => {
                      if (pairData.isFusion) {
                        navigate(`/liquidity/fusion?currency0=${token0.id}&currency1=${token1.id}`)
                      } else {
                        navigate(`/liquidity/manage/${pairData.id}`)
                      }
                    }}
                    className={
                      'h-[42px] md:h-[52px] px-7 whitespace-nowrap text-white  flex flex-col items-center justify-center w-full text-sm mdLg:text-[17px] tracking-[1.12px] md:tracking-[1.36px]'
                    }
                  />
                  <StyledButton
                    content={'SWAP'}
                    onClickHandler={() => {
                      navigate(`/swap`)
                    }}
                    className='relative px-6 md:px-9  text-shadow-10 py-3 md:py-[15px] leading-[21px] md:leading-[22px] f-f-fg font-bold  text-white  text-sm md:text-lg tracking-[2.1px] lg:tracking-[3.6px] rounded-[3px] transition-all duration-300 ease-in-out'
                  />
                </div>
              </div>
            </div>
            <div className='lg:flex lg:space-x-6 space-y-[26px] lg:space-y-0 mt-5 lg:mt-6'>
              <div className='gradient-bg p-px rounded-md w-full lg:w-1/4'>
                <div className='bg-themeBlack rounded-md px-6 py-[22px] h-full'>
                  <p className='text-[#DEDBF2] f-f-fg'>Total Tokens Locked</p>
                  <div className='flex items-center justify-between text-[#DEDBF2] mt-1.5'>
                    <div className={`text-sm flex items-center space-x-1 `}>
                      <img alt='' src={token0Logo} className='w-5' />
                      <p>{token0.symbol} :</p>
                    </div>
                    <p className='text-[15px] leading-5'>{formatAmount(pairData.reserve0)}</p>
                  </div>
                  <div className='flex items-center justify-between text-[#DEDBF2] mt-1.5'>
                    <div className={`text-sm flex items-center space-x-1 `}>
                      <img alt='' src={token1Logo} className='w-5' />
                      <p>{token1.symbol} :</p>
                    </div>
                    <p className='text-[15px] leading-5'>{formatAmount(pairData.reserve1)}</p>
                  </div>
                  <div className='mt-6'>
                    <p className='text-[#DEDBF2] leading-5 text-sm md:text-base'>Total Liquidity</p>
                    <p className='text-white leading-5 text-lg'>${formatAmount(pairData.totalValueLockedUSD)}</p>
                  </div>
                  <div className='mt-6'>
                    <p className='text-[#DEDBF2] leading-5 text-sm md:text-base'>24h Trading Vol</p>
                    <p className='text-white leading-5 text-lg'>${formatAmount(pairData.oneDayVolumeUSD)}</p>
                  </div>
                  <div className='mt-6'>
                    <p className='text-[#DEDBF2] leading-5 text-sm md:text-base'>24h Fees</p>
                    <p className='text-white leading-5 text-lg'>${formatAmount(pairData.oneDayFeesUSD)}</p>
                  </div>
                  <div className='mt-6'>
                    <p className='text-[#DEDBF2] leading-5 text-sm md:text-base'>Contract Address</p>
                    <p
                      className='text-themeOrange leading-5 text-lg cursor-pointer'
                      onClick={() => {
                        window.open(getScanUrl() + 'address/' + pairData.id, '_blank')
                      }}
                    >
                      {pairData.id && pairData.id.slice(0, 6) + '...' + pairData.id.slice(38, 42)}
                    </p>
                  </div>
                </div>
              </div>
              <div className='gradient-bg p-px rounded-md w-full lg:w-3/4'>
                <div className='bg-themeBlack rounded-md px-6 py-[22px] h-full'>
                  <div className='gradient-bg pb-px'>
                    <div className='bg-themeBlack pb-[13px] flex items-center w-full space-x-5'>
                      {Object.values(ChartIndexes).map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`${chartIndex === item ? 'text-white' : 'text-[#757384]'} font-medium cursor-pointer f-f-fg text-lg leading-[22px]`}
                            onClick={() => setChartIndex(item)}
                          >
                            {item}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='mt-3'>
                    <div className='text-[#DEDBF2] f-f-fg text-sm lg:text-base'>{chartIndex}</div>
                    <div className='mt-1 flex items-center space-x-3'>
                      <div className='text-white font-bold text-[20px] lg:text-[27px]'>${formatAmount(currentVal)}</div>
                    </div>
                    <div className='text-[#B8B6CB] text-[10px] lg:text-[15px]'>{dayjs().format('MMM DD, YYYY')}</div>
                  </div>
                  {result && (
                    <AreaChart
                      data={chartData}
                      yAxisValues={getYAXISValuesAnalytics(chartData)}
                      dates={result.map((value) => value.date)}
                      height={275}
                      categories={getChartDates(result)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <p className='text-[27px] leading-8 text-white f-f-fg font-medium mt-10'>Transactions</p>
        <TransictionTable data={transactions} />
      </div>
    </div>
  )
}

export default PairDetails
