import { pairV3APIAbi } from '../config/abi'
import { getPairSimpleAPIAddress, getPairV3APIAddress } from './addressHelpers'
import { getFactoryContract, getPairAPIContract } from './contractHelpers'
import { fromWei } from './formatNumber'
import { multicall } from './multicall'

export const fetchUserPairs = async (web3, account) => {
  const factoryContract = getFactoryContract(web3)
  const pairLength = await factoryContract.methods.allPairsLength().call()

  const pairAPIContract = getPairAPIContract(web3)
  const pairInfos = await pairAPIContract.methods.getAllPair(account, pairLength, 0).call()

  return pairInfos.map((pair) => {
    return {
      address: pair[0], // pair contract address
      lpBalance: fromWei(pair[23], Number(pair[3])), // account LP tokens balance
      gaugeBalance: fromWei(pair[26], Number(pair[3])), // account pair staked in gauge balance
      gaugeEarned: fromWei(pair[27], Number(pair[22])), // account earned emissions for this pair
      totalLp: fromWei(pair[23], Number(pair[3])).plus(fromWei(pair[26], Number(pair[3]))), // account total LP tokens balance
      token0claimable: fromWei(pair[10], Number(pair[8])), // claimable 1st token from fees (for unstaked positions)
      token1claimable: fromWei(pair[15], Number(pair[13])), // claimable 2nd token from fees (for unstaked positions)
    }
  })
}

const fetchGammaInfo = async (account, pools) => {
  const calls = pools.map((pool) => {
    return {
      address: getPairV3APIAddress(),
      name: 'getPair',
      params: [pool.address, account],
    }
  })

  const res = await multicall(pairV3APIAbi, calls)
  return res
}

const fetchGammaPoolsInfo = async (account, pools) => {
  const calls = pools.map((pool) => {
    return {
      address: getPairSimpleAPIAddress(),
      name: 'getPair',
      params: [pool.address, account],
    }
  })

  let calls1 = calls.slice(0, 50)
  let calls2 = calls.slice(50, 150)

  const [res1, res2] = await Promise.all([multicall(pairV3APIAbi, calls1), multicall(pairV3APIAbi, calls2)])
  return [...res1, ...res2]
}

export const fetchUserFusions = async (account, pools) => {
  const pairInfos = await fetchGammaInfo(account, pools)

  return pairInfos.map((pool) => {
    const pair = pool[0]
    const lpDecimals = Number(pair.decimals)
    const gaugeBalance = fromWei(pair.account_gauge_balance._hex, lpDecimals)
    const gaugeLockedBalance = fromWei(pair.account_locked_gauge_balance._hex, lpDecimals)
    const withdrawBalance = gaugeBalance.minus(gaugeLockedBalance)
    return {
      address: pair.pair_address, // pair contract address
      lpBalance: fromWei(pair.account_lp_balance._hex, lpDecimals), // account LP tokens balance
      gaugeBalance, // account pair staked in gauge balance
      gaugeLockedBalance, // account pair locked in gauge balance
      withdrawBalance, // How much LP is available to withdraw now
      gaugeLockEnd: pair.account_lock_end.toNumber(), // account pair staked in gauge balance
      gaugeEarned: fromWei(pair.account_gauge_earned._hex, Number(pair.emissions_token_decimals)), // account earned emissions for this pair
      totalLp: fromWei(pair.account_gauge_balance._hex, lpDecimals).plus(fromWei(pair.account_lp_balance._hex, lpDecimals)), // account total LP tokens balance
      token0claimable: fromWei(pair.claimable0._hex, Number(pair.token0_decimals)), // claimable 1st token from fees (for unstaked positions)
      token1claimable: fromWei(pair.claimable1._hex, Number(pair.token1_decimals)), // claimable 2nd token from fees (for unstaked positions)
    }
  })
}
export const fetchUserGammaPools = async (account, pools) => {
  const pairInfos = await fetchGammaPoolsInfo(account, pools)
  return pairInfos.map((pool) => {
    const pair = pool[0]
    return {
      address: pair[0], // pair contract address
      lpBalance: fromWei(pair[23]._hex, Number(pair[3])), // account LP tokens balance
      gaugeBalance: fromWei(pair[26]._hex, Number(pair[3])), // account pair staked in gauge balance
      gaugeEarned: fromWei(pair[27]._hex, Number(pair[22])), // account earned emissions for this pair
      totalLp: fromWei(pair[23]._hex, Number(pair[3])).plus(fromWei(pair[26]._hex, Number(pair[3]))), // account total LP tokens balance
      token0claimable: fromWei(pair[10]._hex, Number(pair[8])), // claimable 1st token from fees (for unstaked positions)
      token1claimable: fromWei(pair[15]._hex, Number(pair[13])), // claimable 2nd token from fees (for unstaked positions)
      totalSupply: fromWei(pair.total_supply._hex, Number(pair[3])),
      token0Reserve: fromWei(pair.reserve0._hex, Number(pair[8])),
      token1Reserve: fromWei(pair.reserve1._hex, Number(pair[13])),
    }
  })
}
