import React from 'react'
import Overview from '../../components/pages/analytics/overview'
import AnalyticsHeader from '../../components/pages/analytics/analyticsHeader'

const Index = () => {
  return (
    <>
      <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalyticsHeader />
          <Overview />
        </div>
      </div>
    </>
  )
}

export default Index
