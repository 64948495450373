import { useEffect, useState, useCallback } from 'react'
import { useWeb3Wagmi } from './useWeb3'
import { BIG_ZERO } from '../utils/formatNumber'
import BigNumber from 'bignumber.js'
import { multicall } from '../utils/multicall'
import { crowdsaleAbi, pointListAbi } from '../config/abi'
import { useERC20 } from './useContract'
import useRefresh from './useRefresh'

/**
 * Gets all data from an INO related to a wallet
 */
const useIdoWalletData = (ido) => {
  const [state, setState] = useState({
    amountTokenCommited: BIG_ZERO,
    claimed: BIG_ZERO,
    tokensClaimable: BIG_ZERO,
    isPendingTx: false,
    points: BIG_ZERO,
    hasClaimed: false,
  })

  const { address, currency, pointList } = ido

  const { account } = useWeb3Wagmi()
  const { fastRefresh } = useRefresh()
  const allowance = useAllowance(currency.address, address, true)

  const setPendingTx = (status) =>
    setState((prevState) => ({
      ...prevState,
      isPendingTx: status,
    }))

  const setIsClaimed = () => {
    setState((prevState) => ({
      ...prevState,
      hasClaimed: true,
    }))
  }

  const fetchInoData = useCallback(async () => {
    const ifoCalls = ['commitments', 'claimed', 'tokensClaimable'].map((method) => ({
      address,
      name: method,
      params: [account],
    }))

    const [commitments, claimed, tokensClaimable] = await multicall(crowdsaleAbi, ifoCalls)
    const [accountPoints] = await multicall(pointListAbi, [
      {
        address: pointList,
        name: 'points',
        params: [account],
      },
    ])

    const claimable = new BigNumber(tokensClaimable.toString())
    const amountTokenCommited = new BigNumber(commitments.toString())
    setState((prevState) => ({
      ...prevState,
      amountTokenCommited,
      claimed: new BigNumber(claimed.toString()),
      tokensClaimable: claimable,
      hasClaimed: amountTokenCommited.gt(0) && claimable.eq(0),
      isPendingTx: false,
      points: new BigNumber(accountPoints.toString()),
    }))
  }, [account, address])

  useEffect(() => {
    if (account && address) {
      fetchInoData()
    }
  }, [account, fetchInoData, address, fastRefresh, pointList])

  return { ...state, allowance, setPendingTx, setIsClaimed, fetchIfoData: fetchInoData }
}

export const useAllowance = (tokenAddress, contractAddress, forceUpdate) => {
  const [allowance, setAllowance] = useState(new BigNumber(-1))
  const { account, chainId } = useWeb3Wagmi()
  const contract = useERC20(tokenAddress)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchAllowance = async () => {
      if (contract === null) setAllowance(new BigNumber(-1))
      else {
        const res = await contract.methods.allowance(account, contractAddress).call()
        setAllowance(new BigNumber(res))
      }
    }
    if (account && tokenAddress) {
      fetchAllowance()
    }
  }, [account, contract, chainId, contractAddress, tokenAddress, forceUpdate, fastRefresh])

  return allowance
}

export default useIdoWalletData
