import { defaultChainId } from '../config/constants'
import addresses from '../config/constants/contracts'
import { FUSION_ROUTER_ADDRESSES, GAMMA_UNIPROXY_ADDRESSES, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, QUOTER_ADDRESSES } from '../config/constants/v3/addresses'

export const getAddress = (address) => {
  return address[defaultChainId]
}

export const getMultiCallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getTHEAddress = () => {
  return getAddress(addresses.THE)
}

export const getWBNBAddress = () => {
  return getAddress(addresses.WBNB)
}

export const getETHAddress = () => {
  return getAddress(addresses.ETH)
}

export const getThenianAddress = () => {
  return getAddress(addresses.thenian)
}

export const getRouterAddress = () => {
  return getAddress(addresses.router)
}

export const getFactoryAddress = () => {
  return getAddress(addresses.factory)
}

export const getVeTHEAddress = () => {
  return getAddress(addresses.veTHE)
}

export const getVeDistAddress = () => {
  return getAddress(addresses.veDist)
}

export const getVoterAddress = () => {
  return getAddress(addresses.voter)
}

export const getMinterAddress = () => {
  return getAddress(addresses.minter)
}

export const getPairAPIAddress = () => {
  return getAddress(addresses.pairAPI)
}

export const getRewardsAPIAddress = () => {
  return getAddress(addresses.rewardsAPI)
}

export const getVeTHEAPIAddress = () => {
  return getAddress(addresses.veTHEAPI)
}

export const getPairV3APIAddress = () => {
  return getAddress(addresses.pairV3API)
}

export const getPairSimpleAPIAddress = () => {
  return getAddress(addresses.pairApiSimple)
}

export const getRewardsV3APIAddress = () => {
  return getAddress(addresses.rewardsV3API)
}

export const getVeTHEV3APIAddress = () => {
  return getAddress(addresses.veTHEV3API)
}

export const getStakingAddress = () => {
  return getAddress(addresses.staking)
}

export const getRoyaltyAddress = () => {
  return getAddress(addresses.royalty)
}

export const getDibsAddress = () => {
  return getAddress(addresses.dibs)
}

export const getDibsLotteryAddress = () => {
  return getAddress(addresses.dibsLottery)
}

export const getMuonAddress = () => {
  return getAddress(addresses.muon)
}

export const getQuoterAddress = () => {
  return getAddress(QUOTER_ADDRESSES)
}

export const getGammaUNIProxyAddress = () => {
  return getAddress(GAMMA_UNIPROXY_ADDRESSES)
}

export const getAlgebraAddress = () => {
  return getAddress(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES)
}

export const getV3VoterAddress = () => {
  return getAddress(addresses.v3voter)
}

export const getFusionRouterAddress = () => {
  return getAddress(FUSION_ROUTER_ADDRESSES)
}

export const getOpenOceanRouterAddress = () => {
  return getAddress(addresses.openOcean)
}

export const getPreFarmingAddress = () => {
  return getAddress(addresses.preFarming)
}

export const getPreMiningAddress = () => {
  return getAddress(addresses.preFarmingP2)
}

export const getOptionAddress = () => {
  return getAddress(addresses.oLYNX)
}

export const getbveLynxAddress = () => {
  return getAddress(addresses.bveLYNX)
}

export const getAirdropClaimAddress = () => {
  return getAddress(addresses.airdropClaim)
}

export const getMerkleTreeAddress = (type) => {
  return getAddress(addresses.merkleTree[type])
}
