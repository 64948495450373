import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useActivePreset, useV3MintActionHandlers } from '../../state/mintV3/hooks'
import { useGammaUNIProxy } from '../useContract'
import useWeb3, { useWeb3Wagmi } from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import { getERC20Contract, getGammaHyperVisorContract, getGaugeContract, getWBNBContract } from '../../utils/contractHelpers'
import { getAllowance, sendContract } from '../../utils/api'
import { MAX_UINT256, formatAmount, fromWei } from '../../utils/formatNumber'

const useGammaAdd = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const gammaUNIProxyContract = useGammaUNIProxy()
  const web3 = useWeb3()
  const preset = useActivePreset()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleGammaAdd = useCallback(
    async (amountA, amountB, amountToWrap, gammaPair) => {
      const baseCurrency = amountA.currency
      const quoteCurrency = amountB.currency
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      const quoteCurrencyAddress = quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
      const gammaPairAddress = gammaPair ? gammaPair.address : undefined
      if (!amountA || !amountB || !gammaPairAddress) return
      const key = uuidv4()
      const wrapuuid = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const supplyuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${baseCurrency.symbol}/${quoteCurrency.symbol} liquidity`,
          transactions: {
            ...(amountToWrap
              ? {
                  [wrapuuid]: {
                    desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} ETH for WETH`,
                    status: TransactionType.WAITING,
                    hash: null,
                  },
                }
              : {}),
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${quoteCurrency.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      if (amountToWrap) {
        const wbnbContract = getWBNBContract(web3)
        try {
          await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, amountToWrap.toString(10))
        } catch (err) {
          console.log('wrap error :>> ', err)
          setPending(false)
          return
        }
      }

      let isApproved = true
      const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
      const baseAllowance = await getAllowance(baseTokenContract, gammaPairAddress, account)
      if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve0uuid, baseTokenContract, 'approve', [gammaPairAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
      const quoteAllowance = await getAllowance(quoteTokenContract, gammaPairAddress, account)
      if (fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve1uuid, quoteTokenContract, 'approve', [gammaPairAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const firstParam = (baseCurrencyAddress === gammaPair.token0.address.toLowerCase() ? amountA : amountB).numerator.toString()
      const secondParam = (baseCurrencyAddress === gammaPair.token0.address.toLowerCase() ? amountB : amountA).numerator.toString()

      try {
        await sendContract(
          dispatch,
          key,
          supplyuuid,
          gammaUNIProxyContract,
          'deposit',
          [firstParam, secondParam, account, gammaPairAddress, [0, 0, 0, 0]],
          account,
        )
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }
      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added',
        }),
      )
      setPending(false)
    },
    [account, web3, gammaUNIProxyContract, preset],
  )

  const handleGammaAddAndStake = useCallback(
    async (amountA, amountB, amountToWrap, gammaPair) => {
      const baseCurrency = amountA.currency
      const quoteCurrency = amountB.currency
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      const quoteCurrencyAddress = quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
      const gammaPairAddress = gammaPair ? gammaPair.address : undefined
      if (!amountA || !amountB || !gammaPairAddress) return
      const key = uuidv4()
      const wrapuuid = uuidv4()
      const approve0uuid = uuidv4()
      const approve1uuid = uuidv4()
      const supplyuuid = uuidv4()
      const approve2uuid = uuidv4()
      const stakeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${gammaPair.symbol} liquidity`,
          transactions: {
            ...(amountToWrap
              ? {
                  [wrapuuid]: {
                    desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} ETH for WETH`,
                    status: TransactionType.WAITING,
                    hash: null,
                  },
                }
              : {}),
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
              hash: null,
            },
            [approve1uuid]: {
              desc: `Approve ${quoteCurrency.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
            [approve2uuid]: {
              desc: `Approve ${gammaPair.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Stake LP tokens in the gauge`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      if (amountToWrap) {
        const wbnbContract = getWBNBContract(web3)
        try {
          await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, amountToWrap.toString(10))
        } catch (err) {
          console.log('wrap error :>> ', err)
          setPending(false)
          return
        }
      }

      let isApproved = true
      const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
      const baseAllowance = await getAllowance(baseTokenContract, gammaPairAddress, account)
      if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve0uuid, baseTokenContract, 'approve', [gammaPairAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true
      const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
      const quoteAllowance = await getAllowance(quoteTokenContract, gammaPairAddress, account)
      if (fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve1uuid, quoteTokenContract, 'approve', [gammaPairAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve1uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const firstParam = (baseCurrencyAddress === gammaPair.token0.address.toLowerCase() ? amountA : amountB).numerator.toString()
      const secondParam = (baseCurrencyAddress === gammaPair.token0.address.toLowerCase() ? amountB : amountA).numerator.toString()

      try {
        await sendContract(
          dispatch,
          key,
          supplyuuid,
          gammaUNIProxyContract,
          'deposit',
          [firstParam, secondParam, account, gammaPairAddress, [0, 0, 0, 0]],
          account,
        )
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }
      isApproved = true
      const gammaPairContract = getERC20Contract(web3, gammaPairAddress)
      const pairBalance = await gammaPairContract.methods.balanceOf(account).call()
      const pairAllowance = await getAllowance(gammaPairContract, gammaPair.gauge.address, account)
      if (fromWei(pairAllowance).lt(fromWei(pairBalance))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve2uuid, gammaPairContract, 'approve', [gammaPair.gauge.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 2 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve2uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [pairBalance]
      const gaugeContract = getGaugeContract(web3, gammaPair.gauge.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }
      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added And Staked',
        }),
      )
      setPending(false)
    },
    [account, web3, gammaUNIProxyContract, preset],
  )

  return { onGammaAdd: handleGammaAdd, onGammaAddAndStake: handleGammaAddAndStake, pending }
}

const useGammaRemove = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const gammaUNIProxyContract = useGammaUNIProxy()
  const web3 = useWeb3()
  const preset = useActivePreset()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleGammaRemove = useCallback(
    async (pairAddress, baseSymbol, quoteSymbol, amount) => {
      const key = uuidv4()
      const removeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Withdraw ${baseSymbol}/${quoteSymbol} liquidity`,
          transactions: {
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const contract = getGammaHyperVisorContract(web3, pairAddress)

      try {
        await sendContract(dispatch, key, removeuuid, contract, 'withdraw', [amount.times(1e18).toFixed(0), account, account, [0, 0, 0, 0]], account)
      } catch (err) {
        console.log('remove error :>> ', err)
        setPending(false)
        return
      }
      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3, gammaUNIProxyContract, preset],
  )

  return { onGammaRemove: handleGammaRemove, pending }
}

export { useGammaAdd, useGammaRemove }
