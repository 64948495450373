import React, { useState } from 'react'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import NumericalInput from '../../../common/Input/NumericalInput'
import { useIdoCommit } from '../../../../hooks/useIdoCommit'
import useTokenBalance from '../../../../hooks/useTokenBalance'
import { formatAmount } from '../../../../utils/formatNumber'
import './commit.scss'

const CommitInput = ({ ido, status, remaining, symbol }) => {
  const { account } = useWeb3Wagmi()
  const [inputValue, setInputValue] = useState(null)
  const { handleCommit } = useIdoCommit()
  const currency = ido.currency
  const balance = useTokenBalance(currency.address[process.env.REACT_APP_CHAIN_ID])
  const max = remaining.gt(balance) ? balance : remaining
  const disabled = !account || status !== 'live' || remaining.eq(0)

  return (
    <>
      <div className='input-wrapper'>
        <div className='input-text w-[100%] md:text-xl divide-x divide-[#959595]'>
          <NumericalInput
            value={inputValue}
            id='commit-input'
            disabled={disabled}
            onUserInput={(val) => {
              setInputValue(val)
            }}
            placeholder='0.00'
            align='start'
            cursor={disabled && 'not-allowed'}
          />
          <p className='text-xs flex items-center px-4 cursor-pointer' onClick={() => setInputValue(max)}>
            MAX
          </p>
          <p className='text-xs flex items-center px-4'>{symbol}</p>
        </div>
        <button
          disabled={status !== 'live' || inputValue === null || Number(inputValue) <= 0}
          className={`commit-button ${status !== 'live' ? 'bg-white bg-opacity-[0.12]' : 'enabled-commit'}`}
          onClick={() => handleCommit(ido, inputValue)}
        >
          COMMIT
        </button>
      </div>
      <p className='data mt-2'>
        BALANCE: {formatAmount(balance)} {`${currency.symbol.toUpperCase()}`}
      </p>
    </>
  )
}

export default CommitInput
