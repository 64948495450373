import React, { useState } from 'react'
import SwapV1 from '../../components/pages/swap/SwapV1'
import SwapFusion from '../../components/pages/swap/SwapFusion'
import { useSearchParams } from 'react-router-dom'
import { Limit, TWAP } from '../../components/pages/swap/TWAP'
import { defaultChainId } from '../../config/constants'
// import SwapBest from '../../components/pages/swap/SwapBest'

const swaps =
  defaultChainId === 59140
    ? [
        { type: 'V1', Component: SwapV1 },
        { type: 'FUSION', Component: SwapFusion },
      ]
    : [
        { type: 'V1', Component: SwapV1 },
        { type: 'FUSION', Component: SwapFusion },
        // { type: 'BEST', Component: SwapBest },
        { type: 'LIMIT', Component: Limit },
        { type: 'TWAP', Component: TWAP },
      ]

const types = {
  V1: SwapV1,
  FUSION: SwapFusion,
}

const Index = () => {
  const [searchParams] = useSearchParams()
  const type = types[searchParams.get('type')] ? searchParams.get('type') : 'FUSION'
  const [swapType, setSwapType] = useState(type)

  return (
    <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[32px] px-5 xl:px-0 '>
      <div className='max-w-[1104px] mx-auto w-full'>
        <div className='flex justify-center'>
          <div className='flex h-11 border-[#DF832F] border-t  border-l border-r border-b  rounded-[5px]'>
            {swaps.map((swap) => {
              return (
                <div
                  key={swap.type}
                  onClick={() => setSwapType(swap.type)}
                  className={`w-[70px] md:w-[100px] h-full text-sm md:text-base flex justify-center items-center cursor-pointer
              ${
                swap.type !== swapType
                  ? 'text-[#A2A0B7] font-normal -mr-[3px] -ml-px'
                  : 'text-white font-semibold border-[#DF832F] border rounded-[5px] -ml-px popup-gradientbg'
              } `}
                >
                  {swap.type}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {swaps.map((swap) => {
        if (swap.type === swapType) {
          return <swap.Component key={swap.type} />
        }
        return null
      })}
    </div>
  )
}

export default Index
