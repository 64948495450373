import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPH_V1_API,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const dibsClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPH_DIBS_API_URL,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const fusionClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPH_FUSION_API_URL,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPH_BLOCK_API_URL,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPH_HEALTH_API_URL,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export default client
