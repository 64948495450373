import '@rainbow-me/rainbowkit/styles.css'

import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig } from 'wagmi'
import { linea, lineaTestnet } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import {
  bitgetWallet,
  injectedWallet,
  walletConnectWallet,
  rainbowWallet,
  braveWallet,
  okxWallet,
  tokenPocketWallet,
  metaMaskWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { defaultChainId } from './config/constants'

const chain = defaultChainId === 59140 ? [lineaTestnet] : [linea]

const { chains, publicClient } = configureChains(chain, [publicProvider()])
const projectId = process.env.REACT_APP_PROJECT_ID

const connectors = connectorsForWallets([
  {
    appName: 'Lynex',
    groupName: 'Popular',
    wallets: [metaMaskWallet({ projectId, chains }), trustWallet({ projectId, chains }), walletConnectWallet({ projectId, chains })],
  },
  {
    appName: 'Lynex',
    groupName: 'More',
    wallets: [
      bitgetWallet({
        projectId,
        chains,
      }),
      braveWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
      tokenPocketWallet({ projectId, chains }),
      rainbowWallet({ projectId, chains }),
      injectedWallet({ projectId, chains }),
    ],
  },
])

export { chains }

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})
