import { ERC20Abi } from '../config/abi'
import { BIG_ZERO } from '../hooks/v3/useFarms'
import { fetchAssetsBalances } from './fetchUserAssets'
import { multicall } from './multicall'

export async function fetchImportedToken(address, account) {
  const calls = [
    {
      address: address,
      name: 'decimals',
    },
    {
      address: address,
      name: 'symbol',
    },
    {
      address: address,
      name: 'name',
    },
  ]
  try {
    const [decimals, symbol, name] = await multicall(ERC20Abi, calls)
    let asset = { name: name[0], balance: BIG_ZERO, symbol: symbol[0], decimals: decimals[0], address }
    const balance = account ? (await fetchAssetsBalances([asset], account))[0] : BIG_ZERO
    asset.balance = balance
    if (asset.name) return asset
    return null
  } catch (err) {
    return null
  }
}
