import React from 'react'

const Index = ({ className, range, content }) => {
  return (
    <div className={`border border-themeOrange rounded-xl text-center pt-2 md:pt-3 pb-2 md:pb-4 flex flex-col items-center justify-center ${className}`}>
      <p className='text-[#B8B6CB] leading-[15px]  md:leading-4 text-xs md:text-sm'>{range} Price</p>
      {content}
    </div>
  )
}

export default Index
