import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const percentArray = [25, 50, 75, 100]

const OtokenInput = ({ asset, amount, onInputChange = () => {}, disabled = false }) => {
  const { account } = useWeb3Wagmi()

  return (
    <div className='w-full'>
      {!disabled && (
        <div className='flex items-center space-x-2.5 my-2'>
          {percentArray.map((percent, index) => {
            return (
              <div
                className={`flex items-center justify-center bg-white bg-opacity-[0.08] round-[3px] text-white text-sm w-[40px] h-[22px] cursor-pointer`}
                onClick={() => {
                  asset && onInputChange(asset?.balance.times(percent).div(100).dp(asset?.decimals).toString(10) || '')
                }}
                key={`percent-${index}`}
              >
                {percent}%
              </div>
            )
          })}
        </div>
      )}
      <div className='p-px w-full rounded-[3px]'>
        <div className='flex items-center justify-start  bg-white/10 rounded-[3px] '>
          <div className={`flex flex-col w-full pb-2 lg:py-[8px] pl-2.5 lg:pl-4 text-themeBlack`}>
            <div className='flex items-center'>
              {asset && (
                <div>
                  <div className='flex items-center space-x-[3.5px] lg:space-x-3 mr-4'>
                    <img className='w-[20px] h-[20px]' alt='' src={asset.logoURI || '/images/tokens/UKNOWN.png'} />
                    <p className='font-medium text-sm lg:text-[1.2rem] leading-6 text-white'>{asset.symbol}</p>
                  </div>
                </div>
              )}
              <input
                value={amount}
                onChange={(e) => {
                  if (Number(e.target.value) < 0) {
                    onInputChange('')
                  } else {
                    onInputChange(e.target.value)
                  }
                }}
                className='bg-transparent w-full px-2 !border-0 text-xl lg:text-2xl leading-10 placeholder-[#8a8a8a] text-white'
                placeholder='0.00'
                type={'number'}
                disabled={disabled || !account}
                min={0}
              />
            </div>
            <div className='text-slate-200 text-xs lg:text-sm'>${formatAmount((asset?.price || 0) * (Number(amount) || 0))}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtokenInput
