import React, { useState, useMemo } from 'react'
import AlgebraPosition from './algebraPosition'
import { useV3Positions } from '../../../../../hooks/v3/useV3Positions'
import useWalletModal from '../../../../../hooks/useWalletModal'
import StyledButton from '../../../../common/Buttons/styledButton'
import Toggle from '../../../../common/Toggle'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3'

const MyAlgebraPools = () => {
  const [manualLP, setManualLP] = useState(-1)
  const [isClosed, setIsClosed] = useState(false)
  const { account } = useWeb3Wagmi()
  const positions = useV3Positions(account)
  const { openWalletModal } = useWalletModal()

  const filteredPositions = useMemo(() => {
    return positions.filter((pos) => (isClosed ? pos.liquidity?.eq(0) : !pos.liquidity?.eq(0)))
  }, [positions, isClosed])

  return (
    <div className={`max-h-[550px] overflow-auto w-full rounded-md bg-white/10 py-3 px-4 lg:px-6 relative mt-5 md:mt-6`}>
      <p className='text-lg lg:text-[22px] leading-[22px] lg:leading-[27px] f-f-fg font-semibold text-white'>Your Manual LP</p>
      <div className='flex items-center space-x-2 mt-4'>
        <Toggle checked={isClosed} onChange={() => setIsClosed(!isClosed)} small toggleId='isClosed' />
        <p className='text-[#DEDBF2] text-[16px] whitespace-nowrap'>Closed Only</p>
      </div>
      {!account ? (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-white text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      ) : filteredPositions.length > 0 ? (
        <div className='mt-2.5 md:mt-[9px]'>
          {filteredPositions.map((item, id) => {
            return <AlgebraPosition item={item} position={filteredPositions[id]} id={id} key={`position-${id}`} manualLP={manualLP} setManualLP={setManualLP} />
          })}
        </div>
      ) : (
        <p className='my-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>You do not have any liquidity positions.</p>
      )}
    </div>
  )
}

export default MyAlgebraPools
