import React, { useEffect, useState } from 'react'
import OtokenInput from '../../common/Input/OtokenInput'
import StyledButton from '../../common/Buttons/styledButton'
import AddToMetamask from '../../common/Buttons/addToMetamask'
import './otoken.scss'
import { formatAmount } from '../../../utils/formatNumber'
import { getOptionAddress } from '../../../utils/addressHelpers'
import { formatUnits } from 'ethers/lib/utils'
import { useExcercise } from '../../../hooks/useExercise'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const Lynx = ({ baseAssets, optionsData }) => {
  const { account } = useWeb3Wagmi()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [redeemAsset, setRedeemAsset] = useState(null)
  const [payAmount, setPayAmount] = useState('')
  const [payAsset, setPayAsset] = useState(null)
  const assets = baseAssets || []
  const option = optionsData.fetchedOptions
  const { onExcercise } = useExcercise()
  const notAllowed = !account || !redeemAsset || redeemAsset?.balance === 0 || !payAsset || payAsset?.balance === 0

  useEffect(() => {
    if (assets.length === 0) return
    if (!redeemAsset) {
      const oLynxtoken = assets.find((asset) => asset.address.toLowerCase() === getOptionAddress()?.toLowerCase())
      setRedeemAsset(oLynxtoken)
    } else {
      setRedeemAsset(redeemAsset)
    }
    if (!payAsset) {
      const paymentToken = assets.find((asset) => asset.address.toLowerCase() === option.paymentToken.toLowerCase())
      setPayAsset(paymentToken)
    } else {
      setPayAsset(payAsset)
    }
  }, [assets])

  useEffect(() => {
    if (!payAsset) return
    const principal = redeemAmount * option?.discountPrice?.toNumber()
    setPayAmount(formatUnits(parseInt(principal), payAsset.decimals))
  }, [redeemAmount])

  return (
    <div className='wrapper-otoken'>
      <div className='line'>
        <p className='title'>oLYNX balance</p>
        <p className='value'>{redeemAsset?.balance ? formatAmount(redeemAsset.balance.toString()) : '-'}</p>
      </div>
      <div className='line'>
        <p className='title'>USDC balance</p>
        <p className='value'>{payAsset?.balance ? formatAmount(payAsset.balance.toString()) : '-'}</p>
      </div>
      <div className='line'>
        <p className='title'>Discount</p>
        <p className='value'>{100 - option.discount}%</p>
      </div>
      <div className='line'>
        <p className='text-white title'>You redeem oLYNX for LYNX</p>
      </div>
      <OtokenInput
        asset={redeemAsset}
        setAsset={setRedeemAsset}
        amount={redeemAmount}
        onInputChange={(val) => {
          setRedeemAmount(val)
        }}
      />
      <div className='line'>
        <p className='text-white title'>You pay USDC</p>
      </div>
      <OtokenInput asset={payAsset} setAsset={setPayAsset} amount={payAmount} disabled />
      <div className='line'>
        <p className='title'>You get</p>
        <p className='value'>{formatAmount(redeemAmount, false, 2)} LYNX</p>
      </div>
      <StyledButton
        content='REDEEM INTO LYNX'
        disabled={notAllowed}
        onClickHandler={() => {
          onExcercise(payAsset, payAmount, redeemAmount)
        }}
        className='w-full py-[13px] text-white text-lg tracking-[1.36px] rounded-md'
      />
      {account && (
        <div className='flex'>
          <AddToMetamask
            text='Add oLYNX to Metamask'
            tokenAddress={redeemAsset?.address}
            tokenSymbol={redeemAsset?.symbol}
            tokenDecimals={redeemAsset?.decimals}
            imgUrl={redeemAsset?.logoURI}
          />
        </div>
      )}
    </div>
  )
}

export default Lynx
