export const Tokens = {
  BNB: {
    symbol: 'BNB',
    decimals: 18,
    address: {
      56: '',
      97: '',
    },
  },
}

export const tokensList = {
  weth: {
    symbol: 'WETH',
    address: {
      59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      59144: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  stweth: {
    symbol: 'wstETH',
    address: {
      59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      59144: '0xB5beDd42000b71FddE22D3eE8a79Bd49A568fC8F',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  lynx: {
    symbol: 'LYNX',
    address: {
      59140: '0x945d0f208E6755Ca8b17097B307d8aEC2363E101',
      59144: '0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  lynxClaim: {
    symbol: 'LYNXClaim',
    address: {
      59140: '',
      59144: '0xF86381C8c4BBF3D398571e3D904EC04f12ac645A',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  ldo: {
    symbol: 'ldo',
    address: {
      59140: '0x567F42De8014399Cd4FD342b886E3377b763767D',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      59140: '0xf56dc6695cf1f5c364edebc7dc7077ac9b586068',
      59144: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
      97: '',
    },
    decimals: 6,
    projectLink: '#',
  },
  usdcm: {
    symbol: 'USDC',
    address: {
      59140: '0xC0f8eE736cEC8c1a12e9c25272e6f417510165FF',
      59144: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
      97: '',
    },
    decimals: 8,
    projectLink: '#',
  },
  usdt: {
    symbol: 'usdt',
    address: {
      59140: '0x0DFc6bb7eEE496CD789B60255F7B2982F8069415',
      59144: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
      97: '',
    },
    decimals: 18, // 6 on mainnet
    projectLink: '#',
  },
  fusdt: {
    symbol: 'fUSDT',
    address: {
      59140: '0x0DFc6bb7eEE496CD789B60255F7B2982F8069415',
      59144: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
      97: '',
    },
    decimals: 18, // 6 on mainnet
    projectLink: '#',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      59140: '',
      59144: '0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4',
      97: '',
    },
    decimals: 8,
    projectLink: '#',
  },
  busd: {
    symbol: 'busd',
    address: {
      59140: '',
      59144: '0x7d43AABC515C356145049227CeE54B608342c0ad',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  matic: {
    symbol: 'matic',
    address: {
      59140: '',
      59144: '0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
}
